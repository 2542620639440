<div class="card-container" *ngIf="favCards">
	<div class="card" *ngFor="let card of favCards">
		<div class="card-header d-flex justify-content-between align-items-center">
			<h3 (click)="navigateTo(card.tblQuestion.navigationLink)">
				<b>{{ card.tblQuestion.questioName }}</b>
			</h3>
			<niq-icon
				(click)="removeFavorite(card)"
				[iconName]="isFavorite(card.tblQuestion.id) ? 'star-fill' : 'star'"
				size="small"
				class="card-header-icon favorite-icon"
			></niq-icon>
		</div>
		<div class="card-content" (click)="navigateTo(card.tblQuestion.navigationLink)">
			<img [src]="card.tblQuestion.image" [alt]="card.tblQuestion.alternateText" />
		</div>
	</div>
</div>
<!-- <div *ngIf="!favCards || favCards.length === 0">
	<p>No favorite cards available.</p>
</div> -->
