import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol.js';
import ClassBreaksRenderer from '@arcgis/core/renderers/ClassBreaksRenderer.js';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';

export const lessGreen3 = new SimpleFillSymbol({
	color: '#26B910',
	style: 'solid',
	outline: {
		width: 0.2,
		color: '#1E910B'
	}
});

export const lessGreen2 = new SimpleFillSymbol({
	color: '#5DB45D',
	style: 'solid',
	outline: {
		width: 0.2,
		color: '#4B9A4B'
	}
});

export const lessGreen1 = new SimpleFillSymbol({
	color: '#97DB97',
	style: 'solid',
	outline: {
		width: 0.2,
		color: '#80C180'
	}
});

export const lessRed1 = new SimpleFillSymbol({
	color: '#FFCCCC',
	style: 'solid',
	outline: {
		width: 0.2,
		color: '#E6B1B1'
	}
});

export const lessRed2 = new SimpleFillSymbol({
	color: '#D55858',
	style: 'solid',
	outline: {
		width: 0.2,
		color: '#A24D4D'
	}
});

export const lessRed3 = new SimpleFillSymbol({
	color: '#E00020',
	style: 'solid',
	outline: {
		width: 0.2,
		color: '#E00020'
	}
});

export const geoGisDefaults = new SimpleFillSymbol({
	color: '#000000',
	style: 'backward-diagonal',
	outline: {
		width: 0.2,
		color: '#000000'
	}
});

export const geoGisRenderer = new ClassBreaksRenderer({
	field: 'Index',
	// normalizationField: "CMA",
	// legendOptions: {
	//     title: "WM $ Share Pt Chg (BPS) vs YA"
	// },
	// defaultSymbol: geoGisDefaults,
	defaultLabel: 'no data',

	classBreakInfos: [
		{
			minValue: 120,
			maxValue: 200,
			symbol: lessGreen1,
			label: '0 to 80'
		},
		{
			minValue: 51,
			maxValue: 120,
			symbol: lessGreen2,
			label: '80 to 160'
		},
		{
			minValue: 0,
			maxValue: 50,
			symbol: lessGreen3,
			label: '160 to 240'
		},
		{
			minValue: -51,
			maxValue: 0,
			symbol: lessRed1,
			label: '-51 to 0'
		},
		{
			minValue: -102,
			maxValue: -51,
			symbol: lessRed2,
			label: '-102 to -51'
		},
		{
			minValue: -152,
			maxValue: -102,
			symbol: lessRed3,
			label: '-152 to -102'
		}
	]
});

export const layerListInfo = {
	kpi: new FeatureLayer({
		title: 'Walmart CMA Market Names',
		url: 'https://services7.arcgis.com/qHAgwIxldkwnmCm4/arcgis/rest/services/WalmartCMA/FeatureServer/0',
		opacity: 0.9,
		renderer: geoGisRenderer,
		outFields: ['*']
	}),
	geographicDeepDive: new FeatureLayer({
		title: 'Walmart CMA Market Names',
		url: 'https://services7.arcgis.com/qHAgwIxldkwnmCm4/arcgis/rest/services/WalmartCMA/FeatureServer/0',
		opacity: 0.9,
		renderer: geoGisRenderer,
		outFields: ['*']
	}),
	priceBeats: new FeatureLayer({
		title: 'Walmart CMA Market Names',
		url: 'https://services7.arcgis.com/qHAgwIxldkwnmCm4/arcgis/rest/services/WalmartCMA/FeatureServer/0',
		opacity: 0.9,
		renderer: geoGisRenderer,
		outFields: ['*']
	})
};
