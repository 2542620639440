import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BY_LEVEL_REQUEST_TYPE } from 'src/app/constants/report.constant';

@Injectable({
	providedIn: 'root'
})
export class ExportUtilityService {
	constructor() {}

	getExportScope(productSelectorValues): string {
		let scope: string = '';

		Object.keys(productSelectorValues).forEach(key => {
			if (scope === '' || productSelectorValues[key].length > 0) scope = key;
		});

		return scope;
	}

	getPerformanceQuadrantExportPayload(values: any, tab: string) {
		var payload: any = {
			applicationType: 'JBP',
			dataModelType: 'PERFORMANCEQUADRANT'
		};

		//Market
		payload.market = values.market.map(market => market.key);
		payload.wmMarket = values.market.map(market => market.api.value.walmartMarket);
		payload.rmMarket = values.market.map(market => market.api.value.remainingMarket);

		//Period
		payload.week = values.period.map(period => period.api.value.id).join(',');
		payload.period = values.period.map(period => period.api.value.id).join(',');
		payload.cyPeriod = values.period.map(period => period.api.value.currentPeriod).join(',');
		payload.yaPeriod = values.period.map(period => period.api.value.previousPeriod).join(',');

		//Product Selector
		Object.keys(values.product).forEach(
			productKey => (payload[productKey] = values.product[productKey])
		);

		//Level
		payload.level = values.bylevel.map(level => level.key).join(';');
		payload.bylevel = values.bylevel.map(level => level.value).join(';');

		//Fact
		payload.baseFact = values.fact.factType[0];

		values.fact.facts.forEach(fact => {
			payload['facts_' + fact.field] = true;
		});
		payload['facts_level_MANUFATURER'] = true;

		payload.periodEndDate = values.periodenddate.join(';');

		let scope = this.getExportScope(values.product);
		payload.scope = scope;
		payload.selectedValue = values.product[scope];
		payload.gridQuadrants = tab == 'product-quadrants';

		return payload;
	}

	getConsumerDecisionTreePayload(
		values: any,
		period: any,
		market: any,
		isShowUPCS,
		upcRequestParam: any
	) {
		let exportMetricsObj = [];
		let upcExportRequest: any = {};
		let reqObjList = [];
		let exportReq = {
			category: values.product.category.join(';')
		};
		reqObjList.push(exportReq); //Table
		reqObjList.push(exportReq); //Tree

		if (isShowUPCS) {
			values.fact.facts.forEach(fact => {
				exportMetricsObj.push(fact.field);
			});

			//Tree
			upcExportRequest = { ...upcRequestParam, ...{ selected_metrics: exportMetricsObj } };
		} else {
			var defaultColumns = [
				'dollarMarketShare',
				'dollarSharePtChange',
				'dollarValueOfSharePointChange',
				'dollarWalmartSales',
				'dollarWalmartSalesChange',
				'dollarRemainingMarketSales',
				'dollarRemainingMarketSalesChange'
			];
			_.forEach(defaultColumns, function (column, key) {
				exportMetricsObj.push(column);
			});

			upcExportRequest = {
				category: values.product.category.join(';'),
				selected_metrics: exportMetricsObj,
				user_sort_selection_metrics: 'dollarWalmartSales',
				user_sort_selection_order: 'desc',
				periodEndDate: values.periodenddate.join(';'),
				cyPeriod: period.api.value.currentPeriod,
				yaPeriod: period.api.value.previousPeriod,
				wmMarket: market.api.value.walmartMarket,
				rmMarket: market.api.value.remainingMarket
			};
		}

		reqObjList.push(upcExportRequest); //UPC

		let payload = {
			applicationType: 'JBP',
			dataModelType: 'CDTCONSUMERDECISIONTREE',
			market: 'Total US',
			periodEndDate: values.periodenddate.join(';'),
			category: values.product.category.join(';'),
			period: 'Latest 52 Weeks',
			requests: reqObjList
		};

		return payload;
	}

	getTrendedWeeklyExportReportPayload(
		values: any,
		period,
		minRangeSlider: any,
		chartBounds: any,
		filteredFactToShow: any[]
	) {
		let scope = this.getExportScope(values.product);

		let payload = {
			applicationType: 'JBP',
			dataModelType: 'TRENDEDWEEKLY',
			brandOwnerHigh: '',
			brandOwnerLow: '',
			category: values.product.category,
			categoryGroup: values.product.categoryGroup,
			cyPeriod: 'Latest 52 Wks',
			department: values.product.department,
			majorBusiness: values.product.majorBusiness,
			segment: values.product.segment,
			subCategory: values.product.subCategory,
			totalBusiness: values.product.totalBusiness,

			market: values.market.map(marketI => marketI.key),
			rmMarket: values.market.map(marketI => marketI.api.value.remainingMarket),
			wmMarket: values.market.map(marketI => marketI.api.value.walmartMarket),

			yaPeriod: 'Latest 52 Wks YA',
			twoYAPeriod: 'Latest 52 Wks 2YA',
			period: 'Latest ' + period.api.value.id + ' Weeks',
			periodEndDate: values.periodenddate.join(';'),

			scope: scope,
			scopeDisplayName: BY_LEVEL_REQUEST_TYPE[scope],

			fact: values.fact.factType[0] == 'DOLLAR' ? '$' : 'Units',
			factGroup: filteredFactToShow[0].factGroup,

			minPeriod: minRangeSlider.minValue,
			maxPeriod: minRangeSlider.maxValue,

			minYaxis: chartBounds.min,
			maxYaxis: chartBounds.max,

			filteredFactToShow: filteredFactToShow.map(filteredFact => {
				return {
					key: filteredFact.gkey,
					valuefromapi: filteredFact.valuefromapi,
					factGroup: filteredFact.factGroup,
					valuebase: filteredFact.valuebase,
					formatType: filteredFact.formatType,
					isSelected: filteredFact.isSelected,
					cellClass: filteredFact.cellClass,
					colorcode: filteredFact.colorcode,
					color: filteredFact.color,
					unitDesc: filteredFact.unitDesc
				};
			}),
			yAxisTickDiff: chartBounds.diff,
			selectedValue: values.product[this.getExportScope(values.product)]?.join(','),
			level: BY_LEVEL_REQUEST_TYPE[scope]
		};

		return payload;
	}
}
