<!-- @ Panel Dashboard -->
<div class="panelDashboard">
	<div class="main-menu consumer-drive-header">
		<div>
			<div class="mr-4">
				<div class="reportHeader">
					<h4 class="m-0 reportName">{{ pageTitle }}</h4>
				</div>
				<br />
				<div class="mb-0 reportMenu">
					<div>
						<span
							promptClickOutside
							(clickOutside)="closeDropdown('product')"
							#product
							class="report-dropdown dropdown dropdown-ovt select-dropdown"
						>
							<a
								(click)="toggleDropdown('product')"
								[niqTooltip]="productTooltipContent"
								niqTooltipPosition="bottom"
								[niqTooltipColorTheme]="'dark'"
							>
								<span class="product-input-text"> {{ selectedProductToShow.value }} </span>
							</a>
							<crf-ca-product-selector-single
								[hidden]="!showDropdown.product"
								(onClose)="toggleDropdown('product')"
								[selectorType]="3"
								[configId]="qid"
								(updateReport)="updateProductSelection($event)"
								(onDefaultChange)="onMoreOptionClicked($event)"
							></crf-ca-product-selector-single>
							<ng-template #productTooltipContent>
								<div style="padding: 9px 14px; color: #000000">
									{{ pageHoverTexts.productTooltip }}
								</div>
							</ng-template>
						</span>
						<!-- By Level Menu -->
						by
						<span
							promptClickOutside
							(clickOutside)="closeDropdown('shareTo')"
							#shareTo
							class="report-dropdown dropdown dropdown-ovt"
						>
							<a
								id="shareToSelectBtn"
								type="button"
								(click)="toggleDropdown('shareTo')"
								[niqTooltip]="shareToSelectTooltipContent"
								niqTooltipPosition="bottom"
								[niqTooltipColorTheme]="'dark'"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<span>{{ panelDashboardCtrl.selectedLevelToShow }}</span>
							</a>
							<ng-template #shareToSelectTooltipContent>
								<div style="padding: 9px 14px; color: #000000">
									{{ pageHoverTexts.shareToSelectorTooltip }}
								</div>
							</ng-template>
							<app-dropdown-menu
								[hidden]="!showDropdown.shareTo"
								[items]="shareToSelectorList"
								[selectorType]="1"
								[configId]="qid"
								(selectedItem)="byShareSelected($event)"
								(onDefaultChange)="onMoreOptionClicked($event)"
							></app-dropdown-menu>
						</span>
						<!-- End -->
						in
						<span
							promptClickOutside
							(clickOutside)="closeDropdown('market')"
							#market
							class="report-dropdown dropdown dropdown-ovt"
						>
							<a
								id="marketSelectorBtn"
								type="button"
								(click)="toggleDropdown('market')"
								[niqTooltip]="marketTooltipContent"
								niqTooltipPosition="bottom"
								[niqTooltipColorTheme]="'dark'"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<span>{{ panelDashboardCtrl.selectedMarket[0] }}</span>
								<span
									*ngIf="panelDashboardCtrl.selectedMarket.length > 1"
									title="{{ panelDashboardCtrl.selectedMarket.join(',') }}"
									class="badge item-count pl-2"
									>+{{ panelDashboardCtrl.selectedMarket.length - 1 }}</span
								>
							</a>
							<ng-template #marketTooltipContent>
								<div style="padding: 9px 14px; color: #000000">
									{{ pageHoverTexts.marketTooltip }}
								</div>
							</ng-template>
							<app-multiselect-dropdown
								*ngIf="showDropdown.market"
								[placeholder]="'Search markets'"
								[selectorType]="2"
								[configId]="qid"
								[marketData]="marketMenuItems"
								[multiSelect]="false"
								(onCancel)="toggleDropdown('market')"
								(onApply)="marketSelected($event)"
								(onDefaultChange)="onMoreOptionClicked($event)"
							></app-multiselect-dropdown>
						</span>
						during
						<span
							class="col-lg-2 col-md-2 col-sm-2 col-xs-12"
							promptClickOutside
							(clickOutside)="closeDropdown('period')"
							#period
							class="report-dropdown dropdown dropdown-ovt"
						>
							<a
								id="periodSelectorBtn"
								type="button"
								(click)="toggleDropdown('period')"
								[niqTooltip]="periodTooltipContent"
								niqTooltipPosition="bottom"
								[niqTooltipColorTheme]="'dark'"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<span>
									{{ panelDashboardCtrl.period }}
								</span>
							</a>
							<ng-template #periodTooltipContent>
								<div style="padding: 9px 14px; color: #000000">
									{{ pageHoverTexts.periodTooltip }}
								</div>
							</ng-template>
							<app-dropdown-menu
								[hidden]="!showDropdown.period"
								[items]="periodList"
								[selectorType]="5"
								[configId]="qid"
								(selectedItem)="periodSelected($event)"
								(onDefaultChange)="onMoreOptionClicked($event)"
							></app-dropdown-menu>
						</span>
						vs
						<span
							class="col-lg-2 col-md-2 col-sm-2 col-xs-12"
							promptClickOutside
							(clickOutside)="closeDropdown('compPeriod')"
							#compPeriod
							class="report-dropdown dropdown dropdown-ovt"
						>
							<a
								id="comparisonPeriodSelectBtn"
								type="button"
								(click)="toggleDropdown('compPeriod')"
								[niqTooltip]="compPeriodTooltipContent"
								niqTooltipPosition="bottom"
								[niqTooltipColorTheme]="'dark'"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								<span>
									{{ panelDashboardCtrl.comparisonPeriod }}
								</span>
							</a>
							<ng-template #compPeriodTooltipContent>
								<div style="padding: 9px 14px; color: #000000">
									{{ pageHoverTexts.compPeriodTooltip }}
								</div>
							</ng-template>
							<app-dropdown-menu
								[hidden]="!showDropdown.compPeriod"
								[items]="compPeriodList"
								[selectorType]="8"
								[configId]="qid"
								(selectedItem)="compPeriodSelected($event)"
								(onDefaultChange)="onMoreOptionClicked($event)"
							></app-dropdown-menu>
						</span>
					</div>
				</div>
			</div>
		</div>
		<crf-ca-export-report (onExport)="reportDownload($event)"></crf-ca-export-report>
		<crf-ca-save-default
			class="save-selection-all"
			[selectorType]="7"
			[configId]="qid"
			(menuSelector)="onMoreOptionClicked($event)"
		>
		</crf-ca-save-default>
	</div>

	<div>
		<div class="clearfix spacer-20"></div>
		<div id="loadData" *ngIf="showOverlay">
			<div id="overlay" class="ovt-general-overlay"></div>
			<div class="ovt-no-data-message">
				<span class="glyphicon glyphicon-exclamation-sign"></span>
				{{ showOverlayMsg }}
				<span id="closeId" class="ovt-no-data-message-close" ng-click="hideOverlay()">&times;</span>
			</div>
		</div>
		<!-- <div id="loadData" ng-show="showOverlayForExports">
	<div id="overlay" class="ovt-general-overlay"></div>
	<div>
		<span class="spinner-large"> <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i> </span>
	</div>
</div> -->
		<div class="panel-dashboard-container" [hidden]="shareOfWalletExpanded">
			<div>
				<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
					<div class="panel panel-default panel-dashboard-panel-shadow">
						<div class="purchase-dyna-block">
							<span> {{ purchaseDynamicsText }} </span>
						</div>
						<div class="stats-block-purchase-dynamics">
							<div *ngIf="!isPanelDashboardInfoReady">
								<span class="spinner-large">
									<!-- <em class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></em> -->
									<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
								</span>
							</div>
							<div class="one-quarter">
								<div class="middle-height">
									<div
										class="title text-center"
										[niqTooltip]="popover1"
										niqTooltipPosition="top"
										[niqTooltipColorTheme]="'dark'"
									>
										{{ penetrationText }}
									</div>
									<div class="panel-dashboard-value text-center">
										{{ penetrationCYValue }}
									</div>
								</div>
								<ng-template #popover1>
									<div class="popover top panel-dashboard-only-popover penetration-popover">
										<div class="popover-content">
											<p>{{ selectedProductToShow.value }}</p>
											<p>
												<span>{{ penetrationLowerCaseText }}</span
												><span class="panel-dashboard-tooltip-right">{{ penetrationCYValue }}</span>
											</p>
											<p>
												<span>{{ buyingHouseholdsText }}</span
												><span class="panel-dashboard-tooltip-right">{{
													buyingHouseholdsValue
												}}</span>
											</p>
										</div>
									</div>
								</ng-template>
								<div class="middle-height">
									<div class="title text-center">{{ changePtsText }}</div>
									<div class="panel-dashboard-value text-center {{ penetrationChangeStyle }}">
										{{ penetrationChangeValue }}
									</div>
								</div>
							</div>
							<div class="one-quarter">
								<div class="middle-height">
									<div class="title text-center">{{ buyingRateText }}</div>
									<div class="panel-dashboard-value text-center">
										{{ buyingRateCYValue }}
									</div>
								</div>
								<div class="middle-height">
									<div class="title text-center">{{ changeYAText }}</div>
									<div class="panel-dashboard-value text-center {{ buyingRateChangeStyle }}">
										{{ buyingRateChangeValue }}
									</div>
								</div>
							</div>
							<div class="one-quarter">
								<div class="middle-height">
									<div class="title text-center">{{ frequencyText }}</div>
									<div class="panel-dashboard-value text-center">
										{{ frequencyCYValue }}
									</div>
								</div>
								<div class="middle-height">
									<div class="title text-center">{{ changeYAText }}</div>
									<div class="panel-dashboard-value text-center {{ frequencyChangeStyle }}">
										{{ frequencyChangeValue }}
									</div>
								</div>
							</div>
							<div class="one-quarter">
								<div class="last-middle-height">
									<div class="title text-center">{{ dollarPerTripText }}</div>
									<div class="panel-dashboard-value text-center">
										{{ tripCYValue }}
									</div>
								</div>
								<div class="last-middle-height">
									<div class="title text-center">{{ changeYAText }}</div>
									<div class="panel-dashboard-value text-center {{ tripChangeStyle }}">
										{{ tripChangeValue }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
					<div class="panel panel-default panel-dashboard-panel-shadow">
						<div class="share-of-wallet-block">
							<span> {{ shareOfWalletBlockTitleText }} </span>
							<div
								class="expand-icon"
								*ngIf="isPanelDashboardInfoReady && isDataAvailable"
								[hidden]="!isPanelDashboardInfoReady"
								(click)="expandShareWallet()"
								title="{{ expandShareWalletHoverText }}"
							>
								<span class="expand-icon-column">
									<!-- <niq-icon class="expand-img" [size]="'small'" [iconName]="'full-screen-open'"></niq-icon> -->
									<img src="assets/img/niq-icons/expand-icon_black.svg" class="expand-img" alt="" />
								</span>
							</div>
						</div>
						<div *ngIf="!isPanelDashboardInfoReady">
							<span class="spinner-large">
								<!-- <em class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></em> -->
								<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
							</span>
						</div>
						<div class="stats-block-share-wallet">
							<div class="raw-buyers-message-div">
								<span *ngIf="showRawOccasionsMessage" class="raw-buyers-message">
									<img
										src="assets/img/niq-icons/warning-small_black.svg"
										class="raw-buyers-img"
										alt=""
									/>{{ rawOccationsThreshold }}
								</span>
							</div>
							<ng-template #popover2>
								<div
									class="
										popover
										top
										ng-hide
										panel-dashboard-only-popover
										report-popover
										panel-dashboard-share-wallet-popover
									"
								>
									<div class="popover-content">
										<p>
											<span>{{ shareOfWalletText }}</span>
											<span
												class="panel-dashboard-tooltip-right"
												*ngIf="currentShareOfWalmartToTotalOutlets != 'RO'"
											>
												{{ currentShareOfWalmartToTotalOutlets + percentExtension }}
											</span>
											<span
												class="panel-dashboard-tooltip-right"
												*ngIf="currentShareOfWalmartToTotalOutlets == 'RO'"
											>
												<img
													src="assets/img/niq-icons/warning-small_black.svg"
													class="raw-buyers-img"
													alt=""
												/>
											</span>
										</p>
										<p>
											<span>{{ shareOfWalletChangeText }}</span
											><span
												class="panel-dashboard-tooltip-right {{ shareOfWalmartStyle }}"
												*ngIf="shareOfWalmart != 'RO'"
											>
												{{ shareOfWalmart }}
											</span>
											<span
												class=" panel-dashboard-tooltip-right {{ shareOfWalmartStyle }}"
												*ngIf="shareOfWalmart == 'RO'"
											>
												<img
													src="assets/img/niq-icons/warning-small_black.svg"
													class="raw-buyers-img"
											/></span>
										</p>
									</div>
								</div>
							</ng-template>

							<div class="first-section-share-wallet">
								<!-- Show if it is selected Retailer -->
								<div *ngIf="panelDashboardCtrl.selectedLevelToShow === 'Retailer'">
									<div
										id="shareOfWalmartPieChart"
										class="share-walmart-pie-chart"
										[niqTooltip]="popover2"
										niqTooltipPosition="top"
										[niqTooltipColorTheme]="'dark'"
									></div>
									<div class="first-section-table-share-wallet">
										<table
											class="table table-bordered"
											*ngIf="isPanelDashboardInfoReady && isDataAvailable"
										>
											<tbody>
												<tr>
													<td>WALMART</td>
													<td width="70px" class="text-center" *ngIf="shareOfWalmart !== 'RO'">
														<span class="{{ shareOfWalmartStyle }}">{{ shareOfWalmart }}</span>
													</td>
													<td width="70px" class="text-center" *ngIf="shareOfWalmart === 'RO'">
														<img
															src="assets/img/niq-icons/warning-small_black.svg"
															class="raw-buyers-img"
														/>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
								<!-- Show if it is selected channel -->
								<div *ngIf="panelDashboardCtrl.selectedLevelToShow === 'Channel'">
									<div id="channelsPieChart" class="channels-pie-chart"></div>
								</div>
							</div>
							<div class="top-gained">
								<table class="table table-bordered panel-dashboard-share-wallet-table">
									<thead
										[niqTooltip]="popoverGaining"
										niqTooltipPosition="top"
										[niqTooltipColorTheme]="'dark'"
									>
										<tr>
											<th colspan="2">Top {{ panelDashboardCtrl.selectedLevelToShow }}s Gaining</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let retailer of top3Gained">
											<td title="{{ retailer.hoverText }}">{{ retailer.driver }}</td>
											<td width="70px" class="text-center" *ngIf="retailer.value !== 'RO'">
												<font color="green">{{ retailer.value }}</font>
											</td>
											<td width="70px" class="text-center" *ngIf="retailer.value === 'RO'">
												<img
													src="assets/img/niq-icons/warning-small_black.svg"
													class="raw-buyers-img"
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<ng-template #popoverGaining>
								<div
									class="popover top panel-dashboard-popover pop-over-gaining-table"
									*ngIf="isPanelDashboardInfoReady && isDataAvailable"
									data-placement="left"
								>
									<div class="popover-content">
										<p>
											Top {{ panelDashboardCtrl.selectedLevelToShow }}s Gaining Share of Walmart
											from Walmart Shoppers
										</p>
									</div>
								</div>
							</ng-template>
							<div class="bottom-lost">
								<table class="table table-bordered panel-dashboard-share-wallet-table">
									<thead
										[niqTooltip]="popoverLosing"
										niqTooltipPosition="top"
										[niqTooltipColorTheme]="'dark'"
									>
										<tr>
											<th colspan="2">
												Bottom {{ panelDashboardCtrl.selectedLevelToShow }}s Declining
											</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let retailer of top3Lost">
											<td title="{{ retailer.hoverText }}">{{ retailer.driver }}</td>
											<td width="70px" class="text-center" *ngIf="retailer.value !== 'RO'">
												<font color="red">{{ retailer.value }}</font>
											</td>
											<td width="70px" class="text-center" *ngIf="retailer.value == 'RO'">
												<img
													src="assets/img/niq-icons/warning-small_black.svg"
													class="raw-buyers-img"
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<ng-template #popoverLosing>
								<div
									class="popover top panel-dashboard-popover pop-over-losing-table"
									*ngIf="isPanelDashboardInfoReady && isDataAvailable"
								>
									<div class="popover-content">
										<p>
											Bottom {{ panelDashboardCtrl.selectedLevelToShow }}s Losing Share of Walmart
											from Walmart Shoppers
										</p>
									</div>
								</div>
							</ng-template>
						</div>
					</div>
				</div>

				<div class="clearfix"></div>
				<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<div class="panel panel-default panel-dashboard-panel-shadow">
						<div class="share-of-wallet-block">
							<span> $ Contribution Drivers </span>
						</div>
						<div class="stats-block-contribution-drivers">
							<div *ngIf="!isPanelDashboardInfoReady">
								<span class="spinner-large">
									<!-- <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i> -->
									<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
								</span>
							</div>
							<div class="waterfall-chart" id="conWaterFallGraph"></div>
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<span class="float-right">{{ chartBottomLegend }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="panel-dashboard-container" [hidden]="!shareOfWalletExpanded">
			<div class="clearfix"></div>
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<div class="panel panel-default panel-dashboard-panel-shadow">
					<div class="share-of-wallet-block">
						<span>{{ shareWalletTableTitle }}</span>

						<div
							class="expand-icon"
							(click)="expandShareWallet()"
							title="{{ contractShareWalletHoverText }}"
							[hidden]="!isPanelDashboardInfoReady"
							*ngIf="isPanelDashboardInfoReady && isDataAvailable"
						>
							<span class="expand-icon-column">
								<img src="assets/img/niq-icons/contract-icon_black.svg" class="expand-img" />
							</span>
						</div>
						<span
							*ngIf="showRawOccasionsMessageExpandedView && isPanelDashboardInfoReady"
							class="raw-buyers-message"
						>
							<img src="assets/img/niq-icons/warning-small_black.svg" class="raw-buyers-img" />
							{{ rawOccationsThreshold }}
						</span>
						<div style="float: right; margin-top: 5px; margin-right: 10px; font-size: 13px">
							<span *ngIf="product_latest_sort.direction == 'desc'">
								<i class="fa fa-arrow-down" aria-hidden="true"></i>
							</span>
							<span *ngIf="product_latest_sort.direction == 'asc'">
								<i class="fa fa-arrow-up" aria-hidden="true"></i>
							</span>
							<span>{{ product_latest_sort.name }}</span>
						</div>
					</div>
					<div class="body-share-wallet-dashboard">
						<div *ngIf="!isPanelDashboardInfoReady">
							<span class="spinner-large">
								<!-- <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i> -->
								<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
							</span>
						</div>
						<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div>
								<table class="table table-bordered share-wallet-table">
									<thead>
										<tr>
											<th class="table-header-left">
												{{ panelDashboardCtrl.selectedLevelToShow }}
											</th>
											<th class="table-header-right">
												<span
													class="sortorder"
													[ngClass]="'{ reverse: reverse }'"
													[hidden]="propertyNameOrdered !== 'buyingHouseholds'"
												>
													<i
														[class]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"
														aria-hidden="true"
													></i>
												</span>
												<span class="cursor-pointer" (click)="sortBy('buyingHouseholds')">
													{{ buyingHouseholdsColumn }}
												</span>
											</th>
											<th class="table-header-right">
												<span
													class="sortorder"
													[hidden]="propertyNameOrdered !== 'dollarVolume'"
													[ngClass]="'{ reverse: reverse }'"
												>
													<i
														[class]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"
														aria-hidden="true"
													></i
												></span>
												<span class="cursor-pointer" (click)="sortBy('dollarVolume')">
													{{ purchaseColumn }}
												</span>
											</th>
											<th class="table-header-right">
												<span
													class="sortorder"
													[hidden]="propertyNameOrdered !== 'wmShareWallet'"
													[ngClass]="'{ reverse: reverse }'"
												>
													<i
														[class]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"
														aria-hidden="true"
													></i
												></span>
												<span class="cursor-pointer" (click)="sortBy('wmShareWallet')">
													{{ vmShareColumn }}
												</span>
											</th>
											<th class="table-header-right">
												<span
													class="sortorder"
													[hidden]="propertyNameOrdered !== 'value'"
													[ngClass]="'{ reverse: reverse }'"
													><i
														[class]="reverse ? 'fa fa-arrow-down' : 'fa fa-arrow-up'"
														aria-hidden="true"
													></i
												></span>
												<span class="cursor-pointer" (click)="sortBy('value')">{{
													bpsColumn
												}}</span>
											</th>
										</tr>
									</thead>
									<tbody class="scroll custom">
										<tr *ngFor="let retailer of completeListSow">
											<td>
												<span class="share-wallet-table-padding-retailers">{{
													retailer.driver
												}}</span>
											</td>
											<td class="text-right" *ngIf="retailer.buyingHouseholds !== 'RO'">
												{{ retailer.buyingHouseholdsFormatted }}
											</td>
											<td class="text-right" *ngIf="retailer.buyingHouseholds == 'RO'">
												<img
													src="assets/img/niq-icons/warning-small_black.svg"
													class="raw-buyers-img"
												/>
											</td>
											<td class="text-right" *ngIf="retailer.dollarVolume !== 'RO'">
												{{ retailer.dollarVolumeFormatted }}
											</td>
											<td class="text-right" *ngIf="retailer.dollarVolume == 'RO'">
												<img
													src="assets/img/niq-icons/warning-small_black.svg"
													class="raw-buyers-img"
												/>
											</td>
											<td class="text-right" *ngIf="retailer.wmShareWallet !== 'RO'">
												{{ retailer.wmShareWalletFormatted }}%
											</td>
											<td class="text-right" *ngIf="retailer.wmShareWallet == 'RO'">
												<img
													src="assets/img/niq-icons/warning-small_black.svg"
													class="raw-buyers-img"
												/>
											</td>
											<td class="text-right" *ngIf="retailer.value !== 'RO'">
												<span [ngClass]="retailer.valueStyle">{{ retailer.value }}</span>
											</td>
											<td class="text-right" *ngIf="retailer.value == 'RO'">
												<img
													src="assets/img/niq-icons/warning-small_black.svg"
													class="raw-buyers-img"
												/>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<span class="float-right">{{ chartBottomLegend }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="col-xs-12 footnote-block">
		<crf-ca-dashboard-footnote [reportDimension]="panelDashboardCtrl"> </crf-ca-dashboard-footnote>
	</div>
</div>
