export const FACT_SPECIFICATION = [
	{
		gkey: 'WM $ Sales',
		valuefromapi: 'walmartDollars',
		factGroup: 'Sales',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'WM Units',
		valuefromapi: 'walmartUnits',
		factGroup: 'Sales',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM $ Sales YA',
		valuefromapi: 'walmartDollarsYA',
		factGroup: 'Sales',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'WM $ Sales 2YA',
		valuefromapi: 'walmartDollarsTwoYA',
		factGroup: 'Sales',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#8CD1FA',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'WM Units YA',
		valuefromapi: 'walmartUnitsYA',
		factGroup: 'Sales',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM Units 2YA',
		valuefromapi: 'walmartUnitsTwoYA',
		factGroup: 'Sales',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#8CD1FA',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'RM $ Sales',
		valuefromapi: 'remainingDollars',
		factGroup: 'Sales',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#D26514',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'RM Units',
		valuefromapi: 'remainingUnits',
		factGroup: 'Sales',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#D26514',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'RM $ Sales YA',
		valuefromapi: 'remainingDollarsYA',
		factGroup: 'Sales',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#FFAD59',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'RM $ Sales 2YA',
		valuefromapi: 'remainingDollarsTwoYA',
		factGroup: 'Sales',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#F3C098',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'RM Units YA',
		valuefromapi: 'remainingUnitsYA',
		factGroup: 'Sales',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#FFAD59',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'RM Units 2YA',
		valuefromapi: 'remainingUnitsTwoYA',
		factGroup: 'Sales',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#F3C098',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM $ Sales % Chg vs YA',
		valuefromapi: 'walmartDollarsPercentageChange',
		factGroup: 'Sales % Chg',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'hasColor',
		unitDesc: 'value%'
	},
	{
		gkey: 'WM $ Sales % Chg vs 2YA',
		valuefromapi: 'walmartDollarsPercentageChangeTwoYA',
		factGroup: 'Sales % Chg',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'hasColor',
		unitDesc: 'value%'
	},
	{
		gkey: 'WM Units % Chg vs YA',
		valuefromapi: 'walmartUnitsPercentageChange',
		factGroup: 'Sales % Chg',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'hasColor',
		unitDesc: 'value%'
	},
	{
		gkey: 'WM Units % Chg vs 2YA',
		valuefromapi: 'walmartUnitsPercentageChangeTwoYA',
		factGroup: 'Sales % Chg',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'hasColor',
		unitDesc: 'value%'
	},
	{
		gkey: 'RM $ Sales % Chg vs YA',
		valuefromapi: 'remainingDollarsPercentageChange',
		factGroup: 'Sales % Chg',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#D26514',
		color: 'hasColor',
		unitDesc: 'value%'
	},
	{
		gkey: 'RM $ Sales % Chg vs 2YA',
		valuefromapi: 'remainingDollarsPercentageChangeTwoYA',
		factGroup: 'Sales % Chg',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#EEA165',
		color: 'hasColor',
		unitDesc: 'value%'
	},
	{
		gkey: 'RM Units % Chg vs YA',
		valuefromapi: 'remainingUnitsPercentageChange',
		factGroup: 'Sales % Chg',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#D26514',
		color: 'hasColor',
		unitDesc: 'value%'
	},
	{
		gkey: 'RM Units % Chg vs 2YA',
		valuefromapi: 'remainingUnitsPercentageChangeTwoYA',
		factGroup: 'Sales % Chg',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#EEA165',
		color: 'hasColor',
		unitDesc: 'value%'
	},
	{
		gkey: 'WM $ Share',
		valuefromapi: 'walmartDollarSharePercentage',
		factGroup: 'Share',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'noColor',
		unitDesc: 'value%'
	},
	{
		gkey: 'WM Units Share',
		valuefromapi: 'walmartUnitsSharePercentage',
		factGroup: 'Share',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'noColor',
		unitDesc: 'value%'
	},
	{
		gkey: 'WM $ Share YA',
		valuefromapi: 'walmartDollarSharePercentageYA',
		factGroup: 'Share',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'noColor',
		unitDesc: 'value%'
	},
	{
		gkey: 'WM $ Share 2YA',
		valuefromapi: 'walmartDollarSharePercentageTwoYA',
		factGroup: 'Share',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#8CD1FA',
		color: 'noColor',
		unitDesc: 'value%'
	},
	{
		gkey: 'WM Units Share YA',
		valuefromapi: 'walmartUnitsSharePercentageYA',
		factGroup: 'Share',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'noColor',
		unitDesc: 'value%'
	},
	{
		gkey: 'WM Units Share 2YA',
		valuefromapi: 'walmartUnitsSharePercentageTwoYA',
		factGroup: 'Share',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#8CD1FA',
		color: 'noColor',
		unitDesc: 'value%'
	},
	{
		gkey: 'WM $ Share Pt Chg vs YA',
		valuefromapi: 'walmartDollarSharePointChange',
		factGroup: 'Share Pt Chg',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'hasColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM $ Share Pt Chg vs 2YA',
		valuefromapi: 'walmartDollarSharePointChangeTwoYA',
		factGroup: 'Share Pt Chg',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'hasColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM Units Share Pt Chg vs YA',
		valuefromapi: 'walmartUnitsSharePointChange',
		factGroup: 'Share Pt Chg',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'hasColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM Units Share Pt Chg vs 2YA',
		valuefromapi: 'walmartUnitsSharePointChangeTwoYA',
		factGroup: 'Share Pt Chg',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'hasColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM $ Value of Share Pt Chg vs YA',
		valuefromapi: 'walmartDollarValueOfShareChange',
		factGroup: 'Value of Share Pt Chg',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'hasColor',
		unitDesc: '$value'
	},
	{
		gkey: 'WM $ Value of Share Pt Chg vs 2YA',
		valuefromapi: 'walmartDollarValueOfShareChangeTwoYA',
		factGroup: 'Value of Share Pt Chg',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'hasColor',
		unitDesc: '$value'
	},
	{
		gkey: 'WM Units Value of Share Pt Chg vs YA',
		valuefromapi: 'walmartUnitsValueOfShareChange',
		factGroup: 'Value of Share Pt Chg',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'hasColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM Units Value of Share Pt Chg vs 2YA',
		valuefromapi: 'walmartUnitsValueOfShareChangeTwoYA',
		factGroup: 'Value of Share Pt Chg',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'hasColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM $ Any Promo',
		valuefromapi: 'walmartDollarAnyPromo',
		factGroup: 'Any Promo',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'WM Units Any Promo',
		valuefromapi: 'walmartUnitsAnyPromo',
		factGroup: 'Any Promo',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM $ Any Promo YA',
		valuefromapi: 'walmartDollarAnyPromoYA',
		factGroup: 'Any Promo',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'WM Units Any Promo YA',
		valuefromapi: 'walmartUnitsAnyPromoYA',
		factGroup: 'Any Promo',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'RM $ Any Promo',
		valuefromapi: 'remainingDollarAnyPromo',
		factGroup: 'Any Promo',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#D26514',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'RM Units Any Promo',
		valuefromapi: 'remainingUnitsAnyPromo',
		factGroup: 'Any Promo',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#D26514',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'RM $ Any Promo YA',
		valuefromapi: 'remainingDollarAnyPromoYA',
		factGroup: 'Any Promo',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#FFAD59',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'RM Units Any Promo YA',
		valuefromapi: 'remainingUnitsAnyPromoYA',
		factGroup: 'Any Promo',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#FFAD59',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM $ No Promo',
		valuefromapi: 'walmartDollarNoPromo',
		factGroup: 'No Promo',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'WM Units No Promo',
		valuefromapi: 'walmartUnitsNoPromo',
		factGroup: 'No Promo',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM $ No Promo YA',
		valuefromapi: 'walmartDollarNoPromoYA',
		factGroup: 'No Promo',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'WM Units No Promo YA',
		valuefromapi: 'walmartUnitsNoPromoYA',
		factGroup: 'No Promo',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'RM $ No Promo',
		valuefromapi: 'remainingDollarNoPromo',
		factGroup: 'No Promo',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#D26514',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'RM Units No Promo',
		valuefromapi: 'remainingUnitsNoPromo',
		factGroup: 'No Promo',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#D26514',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'RM $ No Promo YA',
		valuefromapi: 'remainingDollarNoPromoYA',
		factGroup: 'No Promo',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#FFAD59',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'RM Units No Promo YA',
		valuefromapi: 'remainingUnitsNoPromoYA',
		factGroup: 'No Promo',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#FFAD59',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM $ Feature and Display',
		valuefromapi: 'walmartDollarFeatureAndDisplay',
		factGroup: 'Feature and Display',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'WM Units Feature and Display',
		valuefromapi: 'walmartUnitsFeatureAndDisplay',
		factGroup: 'Feature and Display',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM $ Feature and Display YA',
		valuefromapi: 'walmartDollarFeatureAndDisplayYA',
		factGroup: 'Feature and Display',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'WM Units Feature and Display YA',
		valuefromapi: 'walmartUnitsFeatureAndDisplayYA',
		factGroup: 'Feature and Display',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'RM $ Feature and Display',
		valuefromapi: 'remainingDollarFeatureAndDisplay',
		factGroup: 'Feature and Display',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#D26514',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'RM Units Feature and Display',
		valuefromapi: 'remainingUnitsFeatureAndDisplay',
		factGroup: 'Feature and Display',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#D26514',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'RM $ Feature and Display YA',
		valuefromapi: 'remainingDollarFeatureAndDisplayYA',
		factGroup: 'Feature and Display',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#FFAD59',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'RM Units Feature and Display YA',
		valuefromapi: 'remainingUnitsFeatureAndDisplayYA',
		factGroup: 'Feature and Display',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#FFAD59',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM $ Display w/o Feature',
		valuefromapi: 'walmartDollarDisplayWithOutFeature',
		factGroup: 'Display w/o Feature',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'WM Units Display w/o Feature',
		valuefromapi: 'walmartUnitsDisplayWithOutFeature',
		factGroup: 'Display w/o Feature',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM $ Display w/o Feature YA',
		valuefromapi: 'walmartDollarDisplayWithOutFeatureYA',
		factGroup: 'Display w/o Feature',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'WM Units Display w/o Feature YA',
		valuefromapi: 'walmartUnitsDisplayWithOutFeatureYA',
		factGroup: 'Display w/o Feature',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'RM $ Display w/o Feature',
		valuefromapi: 'remainingDollarDisplayWithOutFeature',
		factGroup: 'Display w/o Feature',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#D26514',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'RM Units Display w/o Feature',
		valuefromapi: 'remainingUnitsDisplayWithOutFeature',
		factGroup: 'Display w/o Feature',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#D26514',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'RM $ Display w/o Feature YA',
		valuefromapi: 'remainingDollarDisplayWithOutFeatureYA',
		factGroup: 'Display w/o Feature',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#FFAD59',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'RM Units Display w/o Feature YA',
		valuefromapi: 'remainingUnitsDisplayWithOutFeatureYA',
		factGroup: 'Display w/o Feature',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#FFAD59',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM $ Feature w/o Display',
		valuefromapi: 'walmartDollarFeatureWithoutDisplay',
		factGroup: 'Feature w/o Display',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'WM Units Feature w/o Display',
		valuefromapi: 'walmartUnitsFeatureWithoutDisplay',
		factGroup: 'Feature w/o Display',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM $ Feature w/o Display YA',
		valuefromapi: 'walmartDollarFeatureWithoutDisplayYA',
		factGroup: 'Feature w/o Display',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'WM Units Feature w/o Display YA',
		valuefromapi: 'walmartUnitsFeatureWithoutDisplayYA',
		factGroup: 'Feature w/o Display',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'RM $ Feature w/o Display',
		valuefromapi: 'remainingDollarFeatureWithoutDisplay',
		factGroup: 'Feature w/o Display',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#D26514',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'RM Units Feature w/o Display',
		valuefromapi: 'remainingUnitsFeatureWithoutDisplay',
		factGroup: 'Feature w/o Display',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#D26514',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'RM $ Feature w/o Display YA',
		valuefromapi: 'remainingDollarFeatureWithoutDisplayYA',
		factGroup: 'Feature w/o Display',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#FFAD59',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'RM Units Feature w/o Display YA',
		valuefromapi: 'remainingUnitsFeatureWithoutDisplayYA',
		factGroup: 'Feature w/o Display',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#FFAD59',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM $ Price Decrease',
		valuefromapi: 'walmartDollarPriceDecrease',
		factGroup: 'Price Decrease',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'WM Units Price Decrease',
		valuefromapi: 'walmartUnitsPriceDecrease',
		factGroup: 'Price Decrease',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00568B',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'WM $ Price Decrease YA',
		valuefromapi: 'walmartDollarPriceDecreaseYA',
		factGroup: 'Price Decrease',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'WM Units Price Decrease YA',
		valuefromapi: 'walmartUnitsPriceDecreaseYA',
		factGroup: 'Price Decrease',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#00AEEF',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'RM $ Price Decrease',
		valuefromapi: 'remainingDollarPriceDecrease',
		factGroup: 'Price Decrease',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#D26514',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'RM Units Price Decrease',
		valuefromapi: 'remainingUnitsPriceDecrease',
		factGroup: 'Price Decrease',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#D26514',
		color: 'noColor',
		unitDesc: 'value'
	},
	{
		gkey: 'RM $ Price Decrease YA',
		valuefromapi: 'remainingDollarPriceDecreaseYA',
		factGroup: 'Price Decrease',
		valuebase: 'dollar',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#FFAD59',
		color: 'noColor',
		unitDesc: '$value'
	},
	{
		gkey: 'RM Units Price Decrease YA',
		valuefromapi: 'remainingUnitsPriceDecreaseYA',
		factGroup: 'Price Decrease',
		valuebase: 'units',
		formatType: '',
		isSelected: false,
		cellClass: 'normal',
		colorcode: '#FFAD59',
		color: 'noColor',
		unitDesc: 'value'
	}
];
