<div class="container m-0 p-0">
	<div class="row p-3">
		<div class="col-12">
			<div class="table-container p-4">
				<table>
					<!--Dashboard details-->
					<tr *ngIf="footerContent.dashboard">
						<th class="pr-5 py-1">Dashboard</th>
						<td class="pr-2 py-1">:</td>
						<td class="py-1">{{ footerContent?.dashboard }}</td>
					</tr>
					<!--Filter values-->
					<tr *ngFor="let filter of footerContent.filters">
						<th class="pr-5 py-1">{{ filter.filter }}</th>
						<td class="pr-2 py-1">:</td>
						<td class="py-1" *ngIf="isString(filter.value); else footerObjectLoop">
							{{ filter.value }}
						</td>
						<ng-template #footerObjectLoop>
							<td>
								<table>
									<tr *ngFor="let subFilter of filter.value; let i = index">
										<td class="pr-5 pt-1">
											<u
												><strong>{{ subFilter.filter }}</strong></u
											>
										</td>
										<td>
											<span class="mr-2 pt-1">:</span>{{ subFilter.value }}
											<span *ngIf="filter.value.length - 1 !== i"
												><i
													class="fa fa-level-down level-sep"
													aria-hidden="true"
													*ngIf="subFilter.value"
												></i
											></span>
										</td>
									</tr>
								</table>
							</td>
						</ng-template>
					</tr>
				</table>
				<!-- Note -->
				<b>{{ footerContent?.note }}</b>
			</div>
		</div>
	</div>
</div>
