import { DOCUMENT } from '@angular/common';
import {
	AfterViewInit,
	Directive,
	ElementRef,
	EventEmitter,
	Inject,
	OnDestroy,
	Output
} from '@angular/core';
import { Subscription, filter, fromEvent } from 'rxjs';

@Directive({
	selector: '[clickOutside]'
})
export class TopbarPanelClickOutside implements AfterViewInit, OnDestroy {
	documentClickSubscription: Subscription | undefined;
	@Output() clickOutsideEvent = new EventEmitter<void>();

	constructor(private elementRef: ElementRef, @Inject(DOCUMENT) private document: Document) {}

	ngAfterViewInit(): void {
		this.documentClickSubscription = fromEvent(this.document, 'click')
			.pipe(
				filter(event => {
					var elementToCheck = event.target as HTMLElement;
					return !(
						elementToCheck === this.elementRef.nativeElement ||
						this.elementRef.nativeElement.contains(elementToCheck) ||
						elementToCheck.classList.contains('no-outside-click-check')
					);
				})
			)
			.subscribe(() => {
				this.clickOutsideEvent.emit();
			});
	}

	ngOnDestroy(): void {
		this.documentClickSubscription?.unsubscribe();
	}
}
