import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'preserveOrder'
})
export class PreserveOrderPipe implements PipeTransform {
	transform(value: any, ...args: any[]): any {
		if (!value || typeof value !== 'object') {
			return value;
		}

		return Object.keys(value).map(key => ({ key, value: value[key] }));
	}
}
