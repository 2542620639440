<div class="card dropdown-menu px-3">
	<ul class="dropdown-list">
		<crf-ca-save-default
			class="mb-2"
			[menu]="showMenu"
			[selectorType]="selectorType"
			[configId]="configId"
			(menuSelector)="getMenuResponse($event)"
			[disable]="disabledDefaultOptions"
			[disableMenuOptions]="disableMenuOptions"
			[menu]="showMenuIcon"
		></crf-ca-save-default>
		<niq-radio-group
			(selectedChange)="onSelectedChange($event)"
			class="px-3 py-2 mt-2"
			size="small"
		>
			<niq-radio-button
				*ngFor="let category of items"
				[value]="category"
				[checked]="category?.isSelected"
				size="small"
			>
				<p>{{ category.value }}</p>
			</niq-radio-button>
		</niq-radio-group>
	</ul>
	<!--Footer-->
	<div class="button-container py-2 d-flex justify-content-end">
		<niq-button variant="ghost" class="mr-2" size="medium" (click)="cancel.emit()">
			Cancel
		</niq-button>
		<niq-button
			[state]="this.selectedValue ? 'default' : 'disabled'"
			size="medium"
			(click)="apply()"
		>
			Apply
		</niq-button>
	</div>
</div>
