<div class="page-container">
	<div class="main">
		<div class="row">
			<div class="col-lg-12 pr-0 contentsection">
				<h3 class="ml-3 py-3">Content</h3>
				<crf-ca-concierge-content></crf-ca-concierge-content>
			</div>
			<!-- <div class="col-lg-3">
				<div class="row">
					<div class="col-12 appssection">
						<h3 class="ml-3 py-3">Apps</h3>
						<crf-ca-app-section [hover]="false" [isConcierge]="true"></crf-ca-app-section>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</div>
