import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import {
	CONTEXT_ROOT,
	OIDC_HEADER_URL,
	OVT_UI_URL,
	OVT_UI_URL_HOME_PAGE_URLS,
	REDIRECTING_ANSWERS_PORTAL_URL_WHEN_LOGOUT
} from '../../../../environments/env.config.constants';
import { catchError, map } from 'rxjs/operators';
import { KEY } from '../../../constants/data-constants';
import { NlsnGlobalsContextExtract } from 'src/app/core/service/auth/nlsn-globals.service';
import { NlsnDataStoreService } from 'src/app/core/service/store/nlsn-data-store.service';

@Injectable({
	providedIn: 'root'
})
export class NlsnWMovtLandingService {
	private _productId = new BehaviorSubject<any>(null);
	productId = this._productId.asObservable();

	constructor(
		private http: HttpClient,
		public nlsnGlobalsContextExtract: NlsnGlobalsContextExtract,
		private nlsnDataStoreService: NlsnDataStoreService
	) {}

	public canActivate(): Observable<any> {
		return this.redirectToLandingPage();
	}

	private _isUserAuthorized(): Observable<boolean> {
		const headers = {
			withCredentials: true
		};
		const pingURL = OIDC_HEADER_URL[this.nlsnGlobalsContextExtract.appEnvironment];
		return this.http.get<any>(pingURL, headers).pipe(
			map(result => {
				this.nlsnDataStoreService.putItem(KEY.COOKIE, result);
				this.nlsnDataStoreService.putItem(USER_SESSION_DETAILS.ACCESS_TOKEN, result?.access_token);
				this.setExpiryTime(result?.access_token_expires);
				return result != null;
			}),
			catchError((err, caught) => {
				localStorage.removeItem('isLoggedIn');
				return this.redirectToLandingPage();
			})
		);
	}

	private redirectToLandingPage(): Observable<any> {
		//Initializing current context
		this.nlsnGlobalsContextExtract.extractEnvironment();
		console.log('Context', this.nlsnGlobalsContextExtract.appEnvironment);

		if (
			window.location.host.indexOf('uat.dc1.answers.nielseniq.io') > -1 ||
			window.location.host.indexOf('uat.dc2.answers.nielseniq.io') > -1 ||
			window.location.host.indexOf('dc2.answers.nielseniq.com') > -1 ||
			window.location.host.indexOf('dc2.answers.nielseniq.io') > -1
		) {
			//sessionStorage.setItem('isLoggedIn', 'true');
			localStorage.setItem('isLoggedIn', 'true');
			window.location.href = OVT_UI_URL[this.nlsnGlobalsContextExtract.appEnvironment];
		} else if (
			window.location.host.indexOf('jbpdev.nielseniq.io') > -1 ||
			window.location.host.indexOf('oneversiontruthuat.nielseniq.io') > -1 ||
			window.location.host.indexOf('localhost') > -1 ||
			window.location.host.indexOf('oneversiontruth.nielseniq.io') > -1 ||
			window.location.host.indexOf('oneversiontruth.nielseniq.com') > -1
		) {
			console.log('Is User Logged In', localStorage.getItem('isLoggedIn'), localStorage);
			//if (sessionStorage.getItem('isLoggedIn')) {
			if (localStorage.getItem('isLoggedIn')) {
				return this._isUserAuthorized();
			} else {
				//sessionStorage.setItem('isLoggedIn', 'true');
				localStorage.setItem('isLoggedIn', 'true');
				window.location.href =
					REDIRECTING_ANSWERS_PORTAL_URL_WHEN_LOGOUT[this.nlsnGlobalsContextExtract.appEnvironment];
			}
		}
	}

	getUserDetails(): Observable<boolean> {
		const pingURL =
			OVT_UI_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			CONTEXT_ROOT +
			OVT_UI_URL_HOME_PAGE_URLS.OAUTH_DETAILS;
		const headers = {
			headers: new HttpHeaders().append(
				'X-NIQ-ACCESS-TOKEN',
				this.nlsnDataStoreService.getItem(USER_SESSION_DETAILS.ACCESS_TOKEN)
			)
		};
		return this.http.get<any>(pingURL, headers).pipe(
			map(result => {
				if (result.accessToken !== null) {
					this.nlsnDataStoreService.putItem(USER_SESSION_DETAILS.IS_LOGGEDIN, true);
					this.nlsnDataStoreService.putItem(USER_SESSION_DETAILS.ACCESS_TOKEN, result.accessToken);
					if (result.user !== null) {
						this.nlsnDataStoreService.putItem(
							USER_SESSION_DETAILS.USER_NAME,
							result.user?.displayName
						);
						this.nlsnDataStoreService.putItem(USER_SESSION_DETAILS.EMAIL_ID, result.emailId);
						this.nlsnDataStoreService.putItem(USER_SESSION_DETAILS.USER_ROLE, result.role);
					}
				}
				return result != null;
			})
		);
	}
	// Generic POST request
	post(url, body, queryParams) {
		return this.http.post(encodeURI(url + queryParams), body);
	}

	setProductId(productId) {
		this._productId.next(productId);
	}

	setExpiryTime(expiryTimeinMillsec) {
		let logedInTime = new Date(expiryTimeinMillsec * 1000);
		let tokenExpDate = new Date(
			logedInTime.getUTCFullYear(),
			logedInTime.getUTCMonth(),
			logedInTime.getUTCDate(),
			logedInTime.getUTCHours(),
			logedInTime.getUTCMinutes(),
			logedInTime.getUTCSeconds()
		);
		this.nlsnDataStoreService.putItem(USER_SESSION_DETAILS.EXPIRY_TIME, tokenExpDate);
	}
	clearCookies(cookies) {}

	getIsSkipAuthentication() {
		return this.nlsnDataStoreService.getItem('isSkipAuthentication');
	}

	logoutApp(isUnitTest) {
		const timestamp = new Date().getTime();
		window.location.href =
			REDIRECTING_ANSWERS_PORTAL_URL_WHEN_LOGOUT[
				this.nlsnGlobalsContextExtract.extractEnvironment()
			];
		// $window.location.href = CONFIG.REDIRECTING_ANSWERS_PORTAL_URL_WHEN_LOGOUT[CONFIG.BASE_URL_MODE] + '?timestampForNoCache=' + timestamp;
	}

	isUserLoggedIn() {
		return this.nlsnDataStoreService.getItem(USER_SESSION_DETAILS.IS_LOGGEDIN) == null
			? false
			: this.nlsnDataStoreService.getItem(USER_SESSION_DETAILS.IS_LOGGEDIN);
	}

	isUserHasAccessToken() {
		return this.nlsnDataStoreService.getItem(USER_SESSION_DETAILS.ACCESS_TOKEN) == null
			? false
			: this.nlsnDataStoreService.getItem(USER_SESSION_DETAILS.ACCESS_TOKEN);
	}
}

export const USER_SESSION_DETAILS = {
	IS_LOGGEDIN: 'isSignIn',
	USER_NAME: 'userName',
	USER_ROLE: 'userRole',
	ACCESS_TOKEN: 'accessToken',
	EXPIRY_TIME: 'expiryTime',
	EMAIL_ID: 'emailId',
	NIQSESSION_COOKIE: 'niqSessionCookie'
};
