const defaultSettings = {
	enableColumnMoving: false,
	lockPosition: true,
	lockPinned: true,
	pinnedLeft: true,
	sortable: false
};

const productColumn = {
	headerName: 'Product',
	field: 'productName',
	tooltipField: 'productName',
	pinned: true,
	width: 220,
	cellClass: 'gridProductName',
	...defaultSettings
};

export const PDT_RM_DOLLAR_COLUMNS = [
	{ ...productColumn },
	{ headerName: 'UPC', field: 'upc', cellClass: 'normal', ...defaultSettings },
	{ headerName: 'RM $ Sales', field: 'salesThisYear', ...defaultSettings },
	{
		headerName: 'RM $ Actual Chg',
		field: 'actualChange',
		cellFilter: "zip:'dollar'",
		...defaultSettings
	}
];

export const PDT_RM_UNITS_COLUMNS = [
	{ ...productColumn },
	{ headerName: 'UPC', field: 'upc', cellClass: 'normal', ...defaultSettings },
	{ headerName: 'RM Units', field: 'salesThisYear', ...defaultSettings },
	{
		headerName: 'RM Units Actual Chg',
		field: 'actualChange',
		cellFilter: "zip:'roundednumber'",
		...defaultSettings
	}
];

// export const PDT_RM_EQ_COLUMNS = [
// 	{ ...productColumn },
// 	{ headerName: 'UPC', field: 'upc', cellClass: 'normal', ...defaultSettings },
// 	{ headerName: 'RM EQ', field: 'salesThisYear', ...defaultSettings },
// 	{
// 		headerName: 'RM EQ Actual Chg',
// 		field: 'actualChange',
// 		cellFilter: "zip:'roundednumber'",
// 		...defaultSettings
// 	}
// ];

export const PDT_WM_DOLLAR_COLUMNS = [
	{ ...productColumn },
	{ headerName: 'UPC', field: 'upc', minWidth: 130, cellClass: 'normal', ...defaultSettings },
	{ headerName: 'WM $ Sales', minWidth: 100, field: 'salesThisYear', ...defaultSettings },
	{
		headerName: 'WM $ Actual Chg',
		field: 'actualChange',
		minWidth: 100,
		cellFilter: "zip:'dollar'",
		...defaultSettings
	}
];

export const PDT_WM_UNITS_COLUMNS = [
	{ ...productColumn },
	{ headerName: 'UPC', field: 'upc', cellClass: 'normal', ...defaultSettings },
	{ headerName: 'WM Units', field: 'salesThisYear', ...defaultSettings },
	{
		headerName: 'WM Units Actual Chg',
		field: 'actualChange',
		cellFilter: "zip:'roundednumber'",
		...defaultSettings
	}
];

// export const PDT_WM_EQ_COLUMNS = [
// 	{ ...productColumn },
// 	{ headerName: 'UPC', field: 'upc', cellClass: 'normal', ...defaultSettings },
// 	{ headerName: 'WM EQ', field: 'salesThisYear', ...defaultSettings },
// 	{
// 		headerName: 'WM EQ Actual Chg',
// 		field: 'actualChange',
// 		cellFilter: "zip:'roundednumber'",
// 		...defaultSettings
// 	}
// ];
