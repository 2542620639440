import { Component, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { CREATE_USERS } from 'src/app/constants/ui-constants';
import { BASE_URL, CONCIERGE_URL } from 'src/environments/env.config.constants';
import { NlsnDataStoreService } from 'src/app/core/service/store/nlsn-data-store.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { last, map, takeUntil } from 'rxjs/operators';
import { KEY } from '../../../constants/data-constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ConciergeUserCreateService } from '../../services/concierge-user-create.service';
import { ElementRef } from '@angular/core';
import { ToastService } from '@nielseniq/athena-core';
import { Router } from '@angular/router';

@Component({
	selector: 'crf-ca-concierge-user-create',
	templateUrl: './concierge-user-create.component.html',
	styleUrls: ['./concierge-user-create.component.scss']
})
export class ConciergeUserCreateComponent implements OnInit, OnDestroy {
	constructor(
		public router: Router,
		public fb: FormBuilder,
		public http: HttpClient,
		public nlsnDataStoreService: NlsnDataStoreService,
		public conciergeUserCreateService: ConciergeUserCreateService,
		public toastService: ToastService
	) {
		this.userForm = this.fb.group({
			firstName: ['', Validators.required],
			lastName: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			bulkImportInput: ['']
		});
	}

	CREATE_USERS = CREATE_USERS;
	userOption: string = 'createUser';
	firstName: string = '';
	lastName: string = '';
	email: string = '';
	bulkImportInput: string = '';
	res: any;
	clientSearchResults: any;
	organizationType: string = 'manufacturer';
	userForm: FormGroup;
	manufacturerSearchTerm: string = '';
	manufacturerSearchResults: any;
	showClientSearch = false;
	showManufacturerSearch = false;
	manufacturerSearchInput: any = '';
	clientSearchInput: any = '';
	organizationName: any;
	showManufacturerError = false;
	showClientError = false;

	groups: any = [];
	groupApps: any = [];
	filteredGroups: any = [];
	selectedDimCategories: any = [];
	selectedGroups: any = [];
	groupSearchTerm: string = '';
	selectedGroup: any;

	selectedDimCategory: any;
	selectedDimCategoryId: any;

	selectedLeftGroup: any;
	selectedRightGroup: any;

	dimCategory: any = [];
	originalDimCategory: any;

	suggestedCategories: any = [];
	isCategoriesAdded: boolean = false;

	dashboards: any = [];
	saveGroupRes: any = [];
	saveUserRes: any = [];

	groupForm: FormGroup;

	selectedGroupForm: FormGroup;
	typedGroupName: any;

	showGroupNameError: boolean = false;
	showCategoryError: boolean = false;
	showAppAccessError: boolean = false;
	showDashboardAccessError: boolean = false;

	selectedApps: any = [];
	selectedDashboards: any = [];
	selectedCategoryIds: any = [];

	selectedGroupIds: any = [];
	selectedGroupId: any;
	hasSelectedGroupIds: boolean;
	appIdsOfSelectedGroups: number[] = [];

	tempGroupName: any;

	blank: any = '';

	protected readonly unSubscribeAll: Subject<void> = new Subject<void>();

	@ViewChild('niqSearchBar', { static: true, read: ElementRef })
	searchBarEl!: ElementRef;
	clientVOffset!: number;
	manufacturerVOffset!: number;

	isFormSubmitted: boolean = false; // Flag to track form submission

	ngOnInit(): void {
		this.groupForm = this.fb.group({
			groupName: ['', Validators.required]
		});
		this.conciergeUserCreateService
			.getGroups(this.blank)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(value => {
				this.groups = value.result;
				this.filterGroups(); // Initialize the filtered groups
			});

		this.conciergeUserCreateService
			.getDashboards(this.blank)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(value => (this.dashboards = value.result));

		this.conciergeUserCreateService
			.getApps(this.blank)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(value => (this.groupApps = value.result));
	}

	ngOnDestroy(): void {
		this.unSubscribeAll.next();
		this.unSubscribeAll.complete();
	}

	// handleFileInput(event: any): void {
	// 	let fileList: FileList = event.target.files;
	// 	if (fileList.length > 0) {
	// 		let file: File = fileList[0];
	// 		this.userForm.patchValue({ bulkImportInput: file.name });
	// 	}
	// }

	manufacturerSearch(queryParams) {
		this.manufacturerSearchResults = [];
		this.conciergeUserCreateService
			.manufacturerServiceSearch(queryParams)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(value => (this.manufacturerSearchResults = value.result));
	}

	clientSearchTrigger(data: string) {
		if (!data.trim()) {
			// Check if data is blank or contains only whitespace
			this.clientSearchResults = [];
			this.showClientSearch = false;
			return;
		}

		let payload = {
			searchString: data,
			exactMatch: 'false'
		};

		this.conciergeUserCreateService
			.clientServiceSearch(payload)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(value => {
				this.clientSearchResults = value.responseData.BRDOWNHGH;
				this.showClientSearch = this.clientSearchResults.length > 0;
			});
	}

	groupSearchTrigger(data: string): void {
		if (data.trim() === '') {
			this.filteredGroups = this.groups
				.filter(group => group.organizationName === this.organizationName)
				.filter(group => !this.selectedGroups.includes(group));
		} else {
			this.filteredGroups = this.groups
				.filter(group => group.organizationName === this.organizationName)
				.filter(
					group =>
						group.groupName.toLowerCase().includes(data.toLowerCase()) &&
						!this.selectedGroups.includes(group)
				);
		}
	}

	dimCategorySearchTrigger(data: string): void {
		if (!this.originalDimCategory) {
			this.originalDimCategory = [...this.dimCategory];
		}

		if (data.trim() === '') {
			this.dimCategory = this.originalDimCategory.filter(
				cat => !this.selectedDimCategories.includes(cat)
			);
		} else {
			this.dimCategory = this.originalDimCategory.filter(
				cat =>
					cat.category.toLowerCase().includes(data.toLowerCase()) &&
					!this.selectedDimCategories.includes(cat)
			);
		}
	}

	selectedClientItemsChange(data) {
		console.log('select', data);
	}

	selectedClientChange(data) {
		this.clientSearchInput = data;
		this.userForm.patchValue({ clientSearchInput: data });
		this.showClientError = false;
		this.organizationName = data;
		this.showClientSearch = false;
	}

	selectedManufacturerChange(data) {
		this.manufacturerSearchInput = data;

		this.organizationType = 'manufacturer';
		this.userForm.patchValue({ manufacturerSearchInput: data });
		this.showManufacturerSearch = false;
		this.showManufacturerError = false;
	}

	nextStep(): void {
		if (this.userForm.valid && this.organizationType) {
			if (this.organizationType === 'manufacturer' && !this.manufacturerSearchInput) {
				this.showManufacturerError = true;
			} else if (this.organizationType === 'Demo User' && !this.clientSearchInput) {
				this.showClientError = true;
			} else {
				// this part

				if (this.clientSearchInput) {
					this.organizationType = this.clientSearchInput;
					this.clientSearchInput = null;
				}
				this.isFormSubmitted = true; // Set form as submitted
				this.switchTab(2);
				if (this.organizationType === 'NielsenIQ' || this.organizationType === 'Walmart') {
					this.organizationName = this.organizationType;
					this.blank = '';
				} else {
					this.organizationName = this.organizationName
						? this.organizationName
						: this.organizationType;
					this.blank = this.organizationName;
				}
				this.filterGroupsByOrganization();
			}

			this.blank = '';

			//

			this.conciergeUserCreateService
				.getCategories(this.blank)
				.pipe(takeUntil(this.unSubscribeAll))
				.subscribe(value => (this.dimCategory = value.result));
		} else {
			this.userForm.markAllAsTouched();
		}
	}

	addBulkUsers() {
		// Implement your logic here to handle bulk user import
	}

	selectedTab: number = 1;
	selectedDialogueTab: number = 1;
	formFilled: boolean = false;

	isTabSelected(tabNumber: number): boolean {
		return this.selectedTab === tabNumber;
	}

	switchTab(tabNumber: number): void {
		if (tabNumber === 2 && !this.isFormSubmitted) {
			// Prevent switching to Group tab if form is not submitted
			return;
		}
		this.selectedTab = tabNumber;
		if (tabNumber === 2) {
			// Perform necessary actions for the new tab (e.g., Group tab)
			// You can clear or initialize new content here
		}
	}

	filterGroupsByOrganization(): void {
		this.filteredGroups = this.groups.filter(
			group => group.organizationName === this.organizationName
		);
	}

	filterGroups(): void {
		if (!this.groupSearchTerm) {
			this.filteredGroups = this.groups.filter(
				group => group.organizationName === this.organizationName
			);
		} else {
			this.filteredGroups = this.groups.filter(
				group =>
					group.organizationName === this.organizationName &&
					group.name.toLowerCase().includes(this.groupSearchTerm.toLowerCase())
			);
		}
	}

	selectGroup(group: any) {
		this.selectedGroup = group;
		this.selectedGroupId = group.id;
	}

	selectDimCategory(category: any) {
		this.selectedDimCategory = category;
		this.selectedDimCategoryId = category.categoryId;
	}

	moveCatToSecondBox(): void {
		if (this.selectedDimCategory) {
			this.selectedDimCategories.push(this.selectedDimCategory);
			this.dimCategory = this.dimCategory.filter(g => g !== this.selectedDimCategory);

			// Add the categoryId to selectedCategoryIds
			this.selectedCategoryIds.push(this.selectedDimCategoryId);
			this.updateGroupName();
		}
	}

	moveCatToFirstBox(): void {
		if (this.selectedDimCategory) {
			this.dimCategory.push(this.selectedDimCategory);
			this.selectedDimCategories = this.selectedDimCategories.filter(
				g => g !== this.selectedDimCategory
			);

			// Remove the categoryId from selectedCategoryIds
			this.selectedCategoryIds = this.selectedCategoryIds.filter(
				id => id !== this.selectedDimCategoryId
			);
			this.updateGroupName();
		}
	}

	updateGroupName(): void {
		if (this.organizationType && this.selectedDimCategories.length > 0) {
			const firstValue = this.selectedDimCategories[0].category.substring(0, 7);
			this.tempGroupName = `${this.organizationType}_${firstValue}`;

			if (this.selectedDimCategories.length > 1) {
				const secondValue = this.selectedDimCategories[1].category.substring(0, 7);
				this.tempGroupName += `&${secondValue}`;
			}

			this.groupForm.controls['groupName'].setValue(this.tempGroupName);
		} else if (this.organizationType) {
			this.groupForm.controls['groupName'].setValue(this.organizationType);
		}
	}
	dialogBoxSave(): void {
		let payload = {
			groupName: this.typedGroupName ? this.typedGroupName : this.tempGroupName,
			organizationName: this.organizationName ? this.organizationName : this.organizationType,
			apps: this.selectedApps,
			dashboards: this.selectedDashboards,
			categories: this.selectedCategoryIds
		};

		if (
			!this.showGroupNameError &&
			!this.showCategoryError &&
			this.selectedApps.length > 0 &&
			this.selectedDashboards.length > 0
		) {
			this.conciergeUserCreateService
				.saveGroup(payload)
				.pipe(takeUntil(this.unSubscribeAll))
				.subscribe(value => {
					this.saveGroupRes = value.responseData;
					this.refreshGroupsAndMove();
				});
			this.filterGroups();
		} else {
			this.toastService.InjectToast(
				'error',
				'Please complete the information required',
				'',
				0,
				'',
				'Message',
				'large'
			);
		}
	}

	refreshGroupsAndMove() {
		this.conciergeUserCreateService
			.getGroups(this.blank)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(value => {
				this.groups = value.result;
				this.filterGroups();
				this.moveToSecondBox();
			});
	}

	moveToSecondBox(): void {
		const newlyCreatedGroup = this.groups.find(group => group.groupName === this.typedGroupName);

		if (newlyCreatedGroup && !this.selectedGroups.includes(newlyCreatedGroup)) {
			// Handle newly created group
			this.selectedGroups.push(newlyCreatedGroup);
			this.filteredGroups = this.filteredGroups.filter(g => g !== newlyCreatedGroup);
			if (!this.selectedGroupIds.includes(newlyCreatedGroup.id)) {
				this.selectedGroupIds.push(newlyCreatedGroup.id);
			}
			this.selectedGroup = newlyCreatedGroup; // Mark as selected
		} else if (this.selectedGroup && !this.selectedGroups.includes(this.selectedGroup)) {
			// Handle normal functionality
			this.selectedGroups.push(this.selectedGroup);
			this.filteredGroups = this.filteredGroups.filter(g => g !== this.selectedGroup);
			this.selectedGroupIds.push(this.selectedGroupId);
		}
	}

	moveToFirstBox(): void {
		if (this.selectedGroup) {
			this.filteredGroups.push(this.selectedGroup);
			this.selectedGroups = this.selectedGroups.filter(g => g !== this.selectedGroup);

			this.selectedGroupIds = this.selectedGroupIds.filter(id => id !== this.selectedGroupId);
		}
	}

	isDialogueTabSelected(tabNumber: number): boolean {
		return this.selectedDialogueTab === tabNumber;
	}
	switchDialogueTab(tabNumber: number): void {
		this.selectedDialogueTab = tabNumber;
		if (tabNumber === 2) {
		}
	}

	toggleAppSelection(appId: number): void {
		let index = this.selectedApps.indexOf(appId);
		if (index > -1) {
			this.selectedApps.splice(index, 1); // Remove the appId if it's already in the array
		} else {
			this.selectedApps.push(appId); // Add the appId if it's not in the array
		}
	}

	isAppSelected(appId: number): boolean {
		return this.selectedApps.includes(appId);
	}

	toggleDashboardSelection(dashboardId: number): void {
		let index = this.selectedDashboards.indexOf(dashboardId);
		if (index > -1) {
			this.selectedDashboards.splice(index, 1); // Remove the dashboardId if it's already in the array
		} else {
			this.selectedDashboards.push(dashboardId); // Add the dashboardId if it's not in the array
		}
		console.log(this.selectedDashboards);
	}

	isDashboardSelected(dashboardId: number): boolean {
		return this.selectedDashboards.includes(dashboardId);
	}

	toggleSelectAllDashboards(): void {
		if (this.areAllDashboardsSelected()) {
			// If all dashboards are selected, deselect all
			this.selectedDashboards = [];
		} else {
			// Otherwise, select all dashboards
			this.selectedDashboards = [];
			this.dashboards.forEach(dashboard => this.selectedDashboards.push(dashboard.id));
		}
		console.log(this.selectedDashboards);
	}

	areAllDashboardsSelected(): boolean {
		return (
			this.dashboards.length > 0 &&
			this.dashboards.every(dashboard => this.selectedDashboards.includes(dashboard.id))
		);
	}

	dialogBoxNext() {
		this.groupForm.get('groupName').markAsTouched();

		this.showGroupNameError = this.groupForm.get('groupName').invalid;
		this.showCategoryError = this.selectedDimCategories.length === 0;

		if (!this.showGroupNameError && !this.showCategoryError) {
			this.typedGroupName = this.groupForm.get('groupName').value;
			this.selectedDialogueTab = 2;
		}
	}

	submitForm(): void {
		this.firstName = this.userForm.get('firstName').value;
		this.lastName = this.userForm.get('lastName').value;
		this.email = this.userForm.get('email').value;
		if (this.selectedGroupIds.length > 0) {
			this.hasSelectedGroupIds = true;
		} else {
			this.hasSelectedGroupIds = false;
		}

		if (this.hasSelectedGroupIds) {
			// Handle form submission
			for (let group of this.selectedGroups) {
				// Iterate over each app in the group
				for (let app of group.apps) {
					// Push the app ID to the appIdsOfSelectedGroups array
					this.appIdsOfSelectedGroups.push(app.id);
				}
			}
			let payload = {
				users: [
					{
						firstName: this.firstName,
						lastName: this.lastName,
						emailId: this.email,
						organizationName: this.organizationName ? this.organizationName : this.organizationType,
						organizationType: this.organizationType,
						groups: this.selectedGroupIds,
						apps: this.appIdsOfSelectedGroups
					}
				]
			};
			this.conciergeUserCreateService
				.saveUser(payload)
				.pipe(takeUntil(this.unSubscribeAll))
				.subscribe(value => (this.saveUserRes = value.responseData));
			if (this.saveUserRes.responseCode === 'INACTIVE') {
				this.toastService.InjectToast('error', 'User Inactive.', '', 0, '', 'Message', 'large');
			} else {
				this.toastService.InjectToast(
					'success',
					'Your user creation request has been successfully submitted. A confirmation message will be sent to your email.',
					'',
					0,
					'',
					'Message',
					'large'
				);
			}
		}
	}

	addSuggestedCategories() {
		this.conciergeUserCreateService
			.getSuggestedCategoriesByManufacturer(this.organizationName)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(value => {
				this.suggestedCategories = value.result;
				if (this.organizationType === 'NielsenIQ' || this.organizationType === 'Walmart') {
					this.suggestedCategories = this.dimCategory;
				}
				this.suggestedCategories.forEach(category => {
					const match = this.dimCategory.find(dimCat => dimCat.category === category.category);
					if (match) {
						this.selectedDimCategory = match;
						this.moveCatToSecondBox();
					}
				});
				this.isCategoriesAdded = true; // Mark as added
			});
	}

	removeSuggestedCategories() {
		this.selectedDimCategories.forEach(category => {
			this.selectedDimCategory = category;
			this.moveCatToFirstBox();
		});
		this.isCategoriesAdded = false; // Mark as removed
	}
}
