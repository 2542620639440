<div class="container">
	<div class="row">
		<div class="col-12">
			<div class="table-container">
				<div class="table-header d-flex justify-content-between align-items-center p-3">
					<h4>UPC detail: {{ table.title }}</h4>
					<div class="d-flex align-items-center">
						<h6 class="mr-5 d-flex align-items-center">
							{{ table.sort.column }}
							<niq-icon
								class="ml-2"
								[iconName]="
									table.sort.order === 'desc' ? 'arrow-standard-down' : 'arrow-standard-up'
								"
								size="small"
							></niq-icon>
						</h6>
						<niq-icon
							iconName="full-screen-closed"
							size="medium"
							(click)="displayTreeView()"
						></niq-icon>
					</div>
				</div>
				<div class="table-body">
					<crf-ag-grid
						[customStyle]="'height:350px'"
						[id]="'PRODUCT-G1'"
						[columns]="table.columnDefinition"
						[rowData]="table.data"
						*ngIf="ui.loaded"
					>
					</crf-ag-grid>
					<niq-spinner size="medium" [hidden]="table.loaded"></niq-spinner>
				</div>
			</div>
		</div>
	</div>
</div>
