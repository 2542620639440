import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastService, ToastState } from '@nielseniq/athena-core';
import { Subscription } from 'rxjs';
import { PeriodGroup } from 'src/app/models/report-dimension.model';
import { ReportService } from 'src/app/service/report.service';
import { TOAST } from 'src/app/constants/toast.constant';

@Component({
	selector: 'app-dropdown-selection',
	templateUrl: './dropdown-selection.component.html',
	styleUrl: './dropdown-selection.component.scss'
})
export class DropdownSelectionComponent {
	@Input() items: PeriodGroup[];
	@Input() selectorType: number;
	@Input() configId: number;
	@Input() questionId = -1;
	@Input() disableMenuOptions: boolean;
	@Output() periodCheckChanged: EventEmitter<PeriodGroup[]> = new EventEmitter<PeriodGroup[]>();
	@Output() onDefaultChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() cancel: EventEmitter<any> = new EventEmitter<any>();

	selectedPeriod: any;

	constructor(private service: ReportService, private toastService: ToastService) {}

	ngOnInit() {
		if (this.items) {
			this.selectedPeriod = this.items.filter(period => period.isSelected);
		}
	}

	checkedChange(category) {
		category.isSelected = !category.isSelected;
		this.selectedPeriod = this.items.filter(period => period.isSelected);
	}

	applyHandler() {
		this.periodCheckChanged.emit(this.items);
	}

	trackByFn(index: number, category) {
		return category.key;
	}

	getMenuResponse(data) {
		if (this.questionId !== -1) {
			if (data.menuId === 1) {
				let period =
					this.selectedPeriod.length > 0
						? this.selectedPeriod.map(period => period.key).join(';')
						: '';
				let payload = { periodIds: period };
				let subscription: Subscription = this.service
					.saveSelectors(this.questionId, 'periodIds', payload)
					.subscribe({
						next: res => {
							this.toast(
								'success',
								'Period Dropdown',
								'Get Menu Response: Successfully saved selections'
							);
						},
						error: err => {
							this.toast('error', 'Period Dropdown', 'Get Menu Response: Invalid Response');
							subscription.unsubscribe();
						},
						complete: () => subscription.unsubscribe()
					});
			} else if (data.menuId === 2) {
				let subscription: Subscription = this.service.getSelectors(this.questionId).subscribe({
					next: res => {
						if (res.responseData) {
							if (res.responseData.periodIds) {
								let responsePeriods = res.responseData.periodIds.split(';');
								this.items.forEach(period => {
									if (responsePeriods.indexOf(period.key) > -1) period.isSelected = true;
									else period.isSelected = false;
								});
							} else {
								this.items.forEach(period => {
									period.isSelected = true;
								});
							}
						}
					},
					error: err => {
						this.toast('error', 'Periods Dropdown', 'Get Menu Response: Invalid Response');
						subscription.unsubscribe();
					},
					complete: () => subscription.unsubscribe()
				});
			}
		}
		this.onDefaultChange.emit(data);
	}

	toast(type: ToastState, title: string, message: string): void {
		this.toastService.InjectToast(type, message, '', TOAST.TIMEOUT, '', title, TOAST.SIZE);
	}
}
