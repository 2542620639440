import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class MergeImageService {
	constructor() {}

	async merge(base64Image: any, base64Image2: any) {
		return new Promise<any>(resolve => {
			const canvas = document.createElement('canvas');
			const context = canvas.getContext('2d');
			if (context) {
				const image1 = new Image();
				const image2 = new Image();
				image1.src = base64Image;
				image2.src = base64Image2;
				let imagesLoaded = 0;
				const checkImagesLoaded = () => {
					imagesLoaded++;
					if (imagesLoaded === 2 && context) {
						canvas.width = 600;
						canvas.height = 350;
						const secondImageWidth = 110;
						const secondImageHeight = 175;
						context.drawImage(image1, 0, 0, canvas.width, canvas.height);
						context.drawImage(
							image2,
							canvas.width - secondImageWidth,
							canvas.height - secondImageHeight,
							secondImageWidth,
							secondImageHeight
						);
						const url = canvas.toDataURL('image/jpg');
						canvas.remove();
						resolve(url);
					}
				};
				image1.onload = checkImagesLoaded;
				image2.onload = checkImagesLoaded;
			}
		});
	}
}
