import { ZIP_DOLLER, ZIP_PERCENTAGE, ZIP_ROUNDED_NUMBER } from 'src/app/constants/report.constant';

export const FACT_SPECIFICATION = [
	{
		headerName: 'Major Business',
		field: 'prodLevel',
		width: 250,
		cellClass: 'gridProductName'
	},
	{
		headerName: 'WM $ Share',
		field: 'marketShare',
		width: 200,
		cellFilter: ZIP_PERCENTAGE,
		cellClass: 'special'
	},
	{
		field: 'sharePTChg',
		headerName: 'WM $ Share Pt Chg (BPS) vs YA',
		type: 'number',
		displayName: 'WM $ Share Pt Chg (BPS) vs YA',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMBER,
		cellClass: 'gridFromNumber',
		sort: 'asc'
	},
	{
		field: 'sharePTChgTwoYA',
		headerName: 'WM $ Share Pt Chg (BPS) vs 2YA',
		type: 'number',
		displayName: 'WM $ Share Pt Chg (BPS) vs 2YA',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMBER,
		cellClass: 'gridFromNumber'
	},
	{
		field: 'dollarValueOfShrPTChg',
		headerName: 'WM $ Value Of Share Pt Chg vs YA',
		type: 'number',
		displayName: 'WM $ Value of Share Pt Chg vs YA',
		width: 200,
		cellFilter: ZIP_DOLLER,
		cellClass: 'gridFromNumber'
	},
	{
		field: 'dollarValueOfShrPTChgTwoYA',
		headerName: 'WM $ Value Of Share Pt Chg vs 2YA',
		type: 'number',
		displayName: 'WM $ Value of Share Pt Chg vs 2YA',
		width: 200,
		cellFilter: ZIP_DOLLER,
		cellClass: 'gridFromNumber'
	},
	{
		field: 'salesPerChg',
		headerName: 'WM $ Sales % Chg vs YA',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENTAGE,
		cellClass: 'gridFromNumber'
	},
	{
		field: 'salesPerChgTwoYA',
		headerName: 'WM $ Sales % Chg vs 2YA',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENTAGE,
		cellClass: 'gridFromNumber'
	},
	{
		field: 'salesActualChg',
		headerName: 'WM $ Sales Actual Chg vs YA',
		type: 'number',
		width: 170,
		cellClass: 'gridFromNumber',
		cellFilter: ZIP_DOLLER
	},
	{
		field: 'salesActualChgTwoYA',
		headerName: 'WM $ Sales Actual Chg vs 2YA',
		type: 'number',
		width: 170,
		cellClass: 'gridFromNumber',
		cellFilter: ZIP_DOLLER
	},
	{
		field: 'unitsMarketShare',
		headerName: 'WM Units Share',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENTAGE,
		cellClass: 'special'
	},
	{
		field: 'unitsPTChg',
		headerName: 'WM Units Share Pt Chg (BPS) vs YA',
		type: 'number',
		displayName: 'WM $ Share Pt Chg (BPS)  vs YA',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMBER,
		cellClass: 'gridFromNumber'
	},
	{
		field: 'unitsPTChgTwoYA',
		headerName: 'WM Units Share Pt Chg (BPS) vs 2YA',
		type: 'number',
		displayName: 'WM $ Share Pt Chg (BPS) vs 2YA',
		width: 170,
		cellFilter: ZIP_ROUNDED_NUMBER,
		cellClass: 'gridFromNumber'
	},
	{
		field: 'unitValueOfShrPTChg',
		headerName: 'WM Units Value Of Share Pt Chg vs YA',
		type: 'number',
		displayName: 'WM $ Value of Share Pt Chg vs YA',
		width: 200,
		cellFilter: ZIP_DOLLER,
		cellClass: 'gridFromNumber'
	},
	{
		field: 'unitValueOfShrPTChgTwoYA',
		headerName: 'WM Units Value Of Share Pt Chg vs 2YA',
		type: 'number',
		displayName: 'WM $ Value of Share Pt Chg vs 2YA',
		width: 200,
		cellFilter: ZIP_DOLLER,
		cellClass: 'gridFromNumber'
	},
	{
		field: 'unitsSalesChg',
		headerName: 'WM Units % Chg vs YA',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENTAGE,
		cellClass: 'gridFromNumber'
	},
	{
		field: 'unitsSalesChgTwoYA',
		headerName: 'WM Units % Chg vs 2YA',
		type: 'number',
		width: 170,
		cellFilter: ZIP_PERCENTAGE,
		cellClass: 'gridFromNumber'
	},
	{
		field: 'unitsActualChg',
		headerName: 'WM Units Actual Chg vs YA',
		type: 'number',
		width: 170,
		cellClass: 'gridFromNumber',
		cellFilter: ZIP_DOLLER
	},
	{
		field: 'unitsActualChgTwoYA',
		headerName: 'WM Units Actual Chg vs 2YA',
		type: 'number',
		width: 170,
		cellClass: 'gridFromNumber',
		cellFilter: ZIP_DOLLER
	}
];

export const BASE_FACT_DROPDOWN = [
	{
		key: 'marketShare',
		isSelected: false,
		value: 'WM $ Share'
	},
	{
		key: 'sharePTChg',
		isSelected: false,
		value: 'WM $ Share Pt Chg (BPS) vs YA'
	},
	{
		key: 'sharePTChgTwoYA',
		isSelected: false,
		value: 'WM $ Share Pt Chg (BPS) vs 2YA'
	},
	{
		key: 'dollarValueOfShrPTChg',
		isSelected: false,
		value: 'WM $ Value Of Share Pt Chg vs YA'
	},
	{
		key: 'dollarValueOfShrPTChgTwoYA',
		isSelected: false,
		value: 'WM $ Value Of Share Pt Chg vs 2YA'
	},
	{
		key: 'salesPerChg',
		isSelected: false,
		value: 'WM $ Sales % Chg vs YA'
	},
	{
		key: 'salesPerChgTwoYA',
		isSelected: false,
		value: 'WM $ Sales % Chg vs 2YA'
	},
	{
		key: 'salesActualChg',
		isSelected: false,
		value: 'WM $ Sales Actual Chg vs YA'
	},
	{
		key: 'salesActualChgTwoYA',
		isSelected: false,
		value: 'WM $ Sales Actual Chg vs 2YA'
	},
	{
		key: 'unitsMarketShare',
		isSelected: false,
		value: 'WM Units Share'
	},
	{
		key: 'unitsPTChg',
		isSelected: false,
		value: 'WM Units Share Pt Chg (BPS) vs YA'
	},
	{
		key: 'unitsPTChgTwoYA',
		isSelected: false,
		value: 'WM Units Share Pt Chg (BPS) vs 2YA'
	},
	{
		key: 'unitValueOfShrPTChg',
		isSelected: false,
		value: 'WM Units Value Of Share Pt Chg vs YA'
	},
	{
		key: 'unitValueOfShrPTChgTwoYA',
		isSelected: false,
		value: 'WM Units Value Of Share Pt Chg vs 2YA'
	},
	{
		key: 'unitsSalesChg',
		isSelected: false,
		value: 'WM Units % Chg vs YA'
	},
	{
		key: 'unitsSalesChgTwoYA',
		isSelected: false,
		value: 'WM Units % Chg vs 2YA'
	},
	{
		key: 'unitsActualChg',
		isSelected: false,
		value: 'WM Units Actual Chg vs YA'
	},
	{
		key: 'unitsActualChgTwoYA',
		isSelected: false,
		value: 'WM Units Actual Chg vs 2YA'
	}
];
