import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import '@nielsen/nd-ui-components/src/elements.js';
import { SaveDefaultComponent } from './save-default/save-default.component';
import { MultiselectDropdownComponent } from './multiselect-dropdown/multiselect-dropdown.component';
import { MultilayerDropdownComponent } from './multilayer-dropdown/multilayer-dropdown.component';
import { DropdownSelectionComponent } from './dropdown-selection/dropdown-selection.component';
import { DropdownMenuComponent } from './dropdown-menu/dropdown-menu.component';
import {
	ButtonModule,
	CheckboxModule,
	ItemModule,
	MenuModule,
	RadioButtonModule,
	SearchBarModule,
	IconModule,
	TextFieldModule,
	ChipModule,
	CardModule,
	SpinnerModule,
	ExpanderModule,
	AccordionModule
} from '@nielseniq/athena-core';
import { FactSelectorComponent } from './fact-selector/fact-selector.component';
import { DashboardFootnoteComponent } from './dashboard-footnote/dashboard-footnote.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MarketFilterComponent } from './market-filter/market-filter.component';
import { ProductSelectorSingleComponent } from './product-selector-single/product-selector-single.component';
import { TextChangePipe } from '../../../pipes/text-change.pipe';
import { PreserveOrderPipe } from '../../../pipes/preserve-order.pipe';
import { FactSelectorSingleComponent } from './fact-selector-single/fact-selector-single.component';
import { LastElemVisibleDirective } from '../../../directive/lastelemvisible.directive';
import { FilterPipe } from '../../../pipes/filter.pipe';
import { ExportReportComponent } from './export-report/export-report.component';
@NgModule({
	declarations: [
		SaveDefaultComponent,
		MultiselectDropdownComponent,
		MultilayerDropdownComponent,
		DropdownSelectionComponent,
		FactSelectorComponent,
		FactSelectorSingleComponent,
		DropdownMenuComponent,
		DashboardFootnoteComponent,
		MarketFilterComponent,
		ProductSelectorSingleComponent,
		TextChangePipe,
		PreserveOrderPipe,
		LastElemVisibleDirective,
		ExportReportComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [
		ExpanderModule,
		CheckboxModule,
		ButtonModule,
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		ItemModule,
		MenuModule,
		SearchBarModule,
		TextFieldModule,
		IconModule,
		ChipModule,
		CardModule,
		RadioButtonModule,
		SpinnerModule,
		ExpanderModule,
		AccordionModule,
		FilterPipe
	],

	exports: [
		SaveDefaultComponent,
		MultiselectDropdownComponent,
		MultilayerDropdownComponent,
		DropdownSelectionComponent,
		FactSelectorComponent,
		FactSelectorSingleComponent,
		DropdownMenuComponent,
		DashboardFootnoteComponent,
		MarketFilterComponent,
		ProductSelectorSingleComponent,
		ExportReportComponent,
		SpinnerModule
	],
	providers: [FilterPipe]
})
export class ComponentModule {}
