<div class="container m-0 p-0">
	<div class="row">
		<div class="col-12">
			<crf-ca-report-selector-header
				[questionId]="questionId"
				[selectorConfig]="headerComponentSelectorConfiguration"
				(selectorChange)="selectorChangeHandler($event)"
				(factValueChange)="selectorChangeHandler($event)"
				(export)="exportReport($event)"
			>
			</crf-ca-report-selector-header>
		</div>
	</div>
	<div class="row p-3">
		<div class="col-12">
			<p>Adjust to display specific weeks using slider below chart.</p>
			<div class="trended-weekly-dashboard" style="height: 500px"></div>
			<niq-double-slider
				[minValue]="1"
				[maxValue]="52"
				[leftValue]="minRangeSlider.minValue"
				[rightValue]="minRangeSlider.maxValue"
				[stepSize]="1"
				[tickStepSize]="10"
				[showTicks]="false"
				[variant]="'new'"
				(change)="rangeChange($event)"
				[style.width]="sliderWidth + 'px'"
				class="slider"
			></niq-double-slider>
		</div>
	</div>
	<div class="row mt-2">
		<div class="col-xs-12 footnote-block">
			<crf-ca-dashboard-footnote
				[reportDimension]="{
					dashboardName: 'Trended Weekly Comparison (Scan)',
					selectedProduct: setSelectedProduct(selectedProducts),
					selectedMarket: setMarketArray(selectorValues?.market),
					period: '52 Individual Weeks',
					periodEndDate: ui.periodEndDate,
					volumeBasis: selectorValues?.fact?.factType?.[0] === 'DOLLAR' ? '$' : 'Units',
					twoYAEnabled: 'true',
                	multiMarketEnabled: 'true'
				}"
			>
			</crf-ca-dashboard-footnote>
		</div>
	</div>

	<niq-spinner *ngIf="displaySpinner" size="medium"></niq-spinner>
</div>
