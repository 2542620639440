import { Injectable } from '@angular/core';

@Injectable()
export class NlsnDataStoreService {
	private savedItems: any = {};

	deleteAll(): void {
		this.savedItems = {};
	}

	getItem(key: string): any {
		return this.savedItems[key];
	}

	putItem(key: string, value: any): void {
		this.savedItems[key] = value;
	}
}
