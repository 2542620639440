import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, Subject, map } from 'rxjs';
import { KEY } from 'src/app/constants/data-constants';
import { NlsnDataStoreService } from 'src/app/core/service/store/nlsn-data-store.service';
import { BASE_URL, OVT_UI_URL_DASHBOARD_SELECTION } from 'src/environments/env.config.constants';
import { USER_SESSION_DETAILS } from '../../constants/ui-constants';
import { NlsnGlobalsContextExtract } from 'src/app/core/service/auth/nlsn-globals.service';
import * as fileSaver from 'file-saver';
import { ToastService } from '@nielseniq/athena-core';
import { TOAST } from 'src/app/constants/toast.constant';

@Injectable({
	providedIn: 'root'
})
export class UtilityService {
	public isExport$: Subject<boolean> = new Subject();

	constructor(
		private http: HttpClient,
		private nlsnDataStoreService: NlsnDataStoreService,
		private nlsnGlobalsContextExtract: NlsnGlobalsContextExtract,
		private toastService: ToastService
	) {}

	defaultSelectorSplit(val) {
		return val ? val.split(';') : [];
	}

	// Method to check empty, null, undefined
	isValid(obj) {
		return typeof obj == undefined ||
			obj === null ||
			obj === undefined ||
			obj === 'undefined' ||
			obj === 'null' ||
			obj.length === 0
			? false
			: true;
	}

	getDefaultSelectors(configId): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			OVT_UI_URL_DASHBOARD_SELECTION.GET_SAVED_SELECTION +
			configId;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.get<any>(pingURL, headers).pipe(
			map(result => {
				return result.responseData;
			})
		);
	}

	saveItemAsDefault(payload, queryParams): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			OVT_UI_URL_DASHBOARD_SELECTION.DASHBOARD_SAVE_USER_SELECTION +
			queryParams;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.post<any>(pingURL, payload, headers);
	}

	/**
	 *
	 * @param tableColumns
	 * @param defaultColumns
	 * @returns filtered columns to display in the grid
	 */
	getDefaultColumnsByHeaderName(tableColumns, defaultColumns: string[]) {
		return tableColumns.filter(column => defaultColumns.includes(column.headerName));
	}

	/**
	 *
	 * @param tableColumns
	 * @param defaultColumns
	 * @returns filtered columns to display in the grid
	 */
	getDefaultColumnsByGkey(tableColumns, defaultColumns: string[]) {
		return tableColumns.filter(column => defaultColumns.includes(column.gkey));
	}
	getDefaultColumnsByFact(tableColumns, defaultColumns: string[]) {
		return tableColumns.filter(column => defaultColumns.includes(column.factName));
	}
	setDefaultColumnsPropertyByGkey(tableColumns, defaultColumns: string[]) {
		return tableColumns.map(column =>
			defaultColumns.includes(column.gkey)
				? { ...column, isSelected: true, isPinned: true }
				: { ...column, isSelected: false, isPinned: false }
		);
	}

	setDefaultColumnsPropertyByFact(tableColumns, defaultColumns: string[], savedFacts?) {
		let filterCol = tableColumns.filter(
			ele =>
				ele.gkey !== 'WM % ACV' &&
				ele.gkey !== 'RM % ACV' &&
				ele.gkey !== 'WM Numeric Distribution' &&
				ele.gkey !== 'RM Numeric Distribution'
		);
		return filterCol.map(column =>
			defaultColumns.includes(column.factName)
				? { ...column, isSelected: true, isPinned: true }
				: !savedFacts.includes(column?.factName)
				? { ...column, isSelected: false, isPinned: false }
				: { ...column, isSelected: true, isPinned: false }
		);
	}

	updateDefaultAndSelected(tableColumns, fixeddefaultColumns: string[], newFactsSelected) {
		return tableColumns.map(column =>
			fixeddefaultColumns.includes(column.factName)
				? { ...column, isSelected: true, isPinned: true }
				: newFactsSelected.includes(column.factName)
				? { ...column, isSelected: true, isPinned: false }
				: { ...column, isSelected: false, isPinned: false }
		);
	}
	/**
	 *
	 * @param columns
	 * @returns remove UPC columns and return.
	 */
	remove_UPC_Columns(columns: any[]) {
		return _.remove(columns, n => {
			n.headerName = n.name;
			if (n.name === 'Products') {
				n.cellClass = 'special special-header-left ';
			}
			if (
				n.cellFilter &&
				(n.cellFilter === "zip:'dollar'" || n.cellFilter === "zip:'roundeddollar'")
			) {
				n.cellFilter = n.valuebase === 'Units' ? "zip:'roundednumber'" : "zip:'dollar'";
			}
			return (
				n.name !== 'WM Number of UPCs Selling' &&
				n.name !== 'TM Number of UPCs Selling' &&
				n.name !== 'RM Number of UPCs Selling' &&
				n.name !== 'WM TDP' &&
				n.name !== 'RM TDP'
			);
		});
	}

	// Method to check empty, null, undefined for multiSelecct
	isValidForMulti(obj) {
		if (
			typeof obj == undefined ||
			obj === null ||
			obj === undefined ||
			obj === 'undefined' ||
			obj === 'null' ||
			obj.length === 0
		) {
			return '';
		} else {
			var str = [];
			for (var key in obj) {
				if (obj[key]) {
					str.push(obj[key].replace('%', '%25').replace('+', '%2B').replace('&', '%26'));
				}
			}

			return str.join(';');
		}
	}

	/**
	 *
	 * @param payload
	 * @param type excel or ppt
	 * @param fileName string
	 */
	exportFile(payload: any, type: 'excel' | 'ppt') {
		const url = `${BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment]}/sbs/export/${type}`;
		const headers = new HttpHeaders({
			'X-NIQ-ACCESS-TOKEN': this.nlsnDataStoreService.getItem(USER_SESSION_DETAILS.ACCESS_TOKEN),
			'Content-Type': 'application/json'
		});

		this.http.post(url, payload, { headers, responseType: 'blob', observe: 'response' }).subscribe(
			(response: HttpResponse<Blob>) => {
				if (response.body.size !== 0) {
					let reportType;
					if (type === 'excel') {
						reportType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
					} else if (type === 'ppt') {
						reportType =
							'application/vnd.openxmlformats-officedocument.presentationml.presentation';
					} else {
						reportType = 'application/text/csv';
					}
					const blob = new Blob([response.body], {
						type: reportType
					});

					let filename = 'exportfile';
					const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					const headers = response.headers;
					const contentDisposition = headers.get('Content-Disposition');
					const matches = filenameRegex.exec(contentDisposition);
					if (matches !== null && matches[1]) {
						filename = matches[1].replace(/['"]/g, '');
					}
					fileSaver.saveAs(blob, filename);
					this.toastService.InjectToast(
						'success',
						'Successfully',
						'',
						5000,
						'',
						`${type.toLocaleUpperCase()} Downloaded `,
						TOAST.SIZE
					);
					this.isExport$.next(true);
				} else {
					this.toastService.InjectToast(
						'error',
						'Problem while downloading report. Please try again',
						'',
						5000,
						'',
						`${type.toLocaleUpperCase()} Export Failed`,
						TOAST.SIZE
					);
					this.isExport$.next(true);
				}
			},
			error => {
				this.toastService.InjectToast(
					'error',
					error.message,
					'',
					5000,
					'',
					'Export Failed',
					TOAST.SIZE
				);
			}
		);
	}

	preselectDefault(list, selectedValue) {
		let selected = list.find(item => item.value === selectedValue);
		if (selected) {
			selected.isSelected = true;
		}
	}
}
