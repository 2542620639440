import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from 'src/app/directive/promptclickoutside.directive';
import { SalesDriverReportModule } from 'src/app/page/sales-driver-report/sales-driver-report.module';

@NgModule({
	declarations: [ClickOutsideDirective],
	imports: [CommonModule],
	exports: [ClickOutsideDirective]
})
export class SharedDirectiveModule {}
