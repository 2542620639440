import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { ToastService } from '@nielseniq/athena-core';
import { Subscription } from 'rxjs';
import { GridService } from 'src/app/shared/components/grid/grid-service/grid.service';
import { TOAST } from 'src/app/constants/toast.constant';
import { ConsumerDecisionTreeService } from 'src/app/service/consumer-decision-tree.service';

@Component({
	selector: 'crf-ca-consumer-decision-tree-upc-table',
	templateUrl: './consumer-decision-tree-upc-table.component.html',
	styleUrl: './consumer-decision-tree-upc-table.component.scss'
})
export class ConsumerDecisionTreeUpcTableComponent implements AfterViewInit {
	@Output() displayTreeViewEvent: EventEmitter<void> = new EventEmitter<void>();

	table = {
		columnDefinition: [],
		data: [],
		title: '',
		loaded: false,
		sort: {
			column: 'WM $ Sales',
			order: 'desc'
		}
	};

	ui = {
		loaded: false
	};

	constructor(
		private service: ConsumerDecisionTreeService,
		private gridService: GridService,
		private toastService: ToastService
	) {}

	ngAfterViewInit(): void {
		this.gridService.sortColumnSubject$.subscribe({
			next: res => {
				if (res && res.headerName && res.sortOrder) {
					this.table.sort.column = res.headerName;
					this.table.sort.order = res.sortOrder;
				}
			}
		});
	}

	loadUpcTable(factDefinition: any, payload: any): void {
		//Resetting values
		this.table.data = [];
		this.table.title = '';

		this.table.loaded = false;
		this.ui.loaded = false;

		//Setting column definition
		this.table.columnDefinition = factDefinition;

		//Setting title of the table
		this.table.title = payload.category;
		for (let i = 0; i <= 3; i++)
			if (payload['lvl' + i]) this.table.title = this.table.title + ' | ' + payload['lvl' + i];
			else break;

		//Making API call to fetch data from backend
		let subscription: Subscription = this.service.getUpcTableData(payload)?.subscribe({
			next: res => {
				if (res.responseData) this.table.data = res.responseData.products;
				else
					this.toast(
						'warning',
						'Consumer Decision Tree UPC Table',
						'Load UPC Table: Invalid Response'
					);
			},
			error: err => {
				this.table.loaded = true;
				this.ui.loaded = true;
				this.toast('error', 'Consumer Decision Tree UPC Table', 'Load UPC Table: Invalid Response');
				subscription.unsubscribe();
			},
			complete: () => {
				this.table.loaded = true;
				this.ui.loaded = true;
				subscription.unsubscribe();
			}
		});
	}

	updateColumnDefinition(facts: any) {
		this.ui.loaded = false;
		this.table.columnDefinition = facts;
		setTimeout(() => (this.ui.loaded = true), 10);
	}

	clearData(): void {
		this.table.data = [];
	}

	displayTreeView(): void {
		this.displayTreeViewEvent.emit();
	}

	toast(type: any, title: string, message: string): void {
		this.toastService.InjectToast(type, message, '', TOAST.TIMEOUT, '', title, TOAST.SIZE);
	}
}
