import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastService } from '@nielseniq/athena-core';
import { Observable, retry, catchError, of, map } from 'rxjs';
import { TOAST } from 'src/app/constants/toast.constant';
import { USER_SESSION_DETAILS } from 'src/app/constants/ui-constants';
import { NlsnGlobalsContextExtract } from 'src/app/core/service/auth/nlsn-globals.service';
import { NlsnDataStoreService } from 'src/app/core/service/store/nlsn-data-store.service';
import { BASE_URL, GEOGRAPHIC_DEEP_DIVE_ENDPOINTS } from 'src/environments/env.config.constants';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class GeographicDeepDriveService {
	headers: { headers: HttpHeaders };
	constructor(
		public httpRequest: HttpClient,
		public nlsnDataStoreService: NlsnDataStoreService,
		public toastService: ToastService,
		public nlsnGlobalsContextExtract: NlsnGlobalsContextExtract
	) {
		this.headers = {
			headers: new HttpHeaders().append(
				'X-NIQ-ACCESS-TOKEN',
				this.nlsnDataStoreService.getItem(USER_SESSION_DETAILS.ACCESS_TOKEN)
			)
		};
	}
	getTablePost(payload): Observable<any> {
		let baseUrl =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			GEOGRAPHIC_DEEP_DIVE_ENDPOINTS.TABLE_POST_URL;
		return this.httpRequest.post<any>(baseUrl, payload, this.headers).pipe(
			retry(3),
			catchError(error => {
				this.toastService.InjectToast(
					'error',
					error.message,
					'',
					5000,
					'',
					'Something went wrong',
					TOAST.SIZE
				);
				return of(null);
			})
		);
	}
	getMarketPost(payload): Observable<any> {
		let baseUrl =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			GEOGRAPHIC_DEEP_DIVE_ENDPOINTS.MARKET_POST_URL;
		return this.httpRequest.post<any>(baseUrl, payload, this.headers).pipe(
			map(res => {
				if (!_.isNil(res.markets) && res.markets[0]) {
					var resKeys = Object.keys(res.markets[0]);
					var modifiedMarket = _.map(res.markets, market => {
						var newObj = {};
						_.forEach(resKeys, keyName => {
							newObj[res.nameMap[keyName]] = market[keyName];
						});
						return newObj;
					});
					res.markets = modifiedMarket;
					return res;
				} else {
					return res;
				}
			}),
			retry(3),
			catchError(error => {
				this.toastService.InjectToast(
					'error',
					error.message,
					'',
					5000,
					'',
					'Something went wrong',
					TOAST.SIZE
				);
				return of(null);
			})
		);
	}
}
