import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FavoriteService } from 'src/app/landing-page/service/favorite.service';
import { LandingPageService } from 'src/app/service/landing-page.service';
import { Router } from '@angular/router';
import { NlsnDataStoreService } from 'src/app/core/service/store/nlsn-data-store.service';
import { KEY } from 'src/app/constants/data-constants';
import * as _ from 'lodash';

@Component({
	selector: 'crf-ca-quick-link-card',
	templateUrl: './quick-link-card.component.html',
	styleUrl: './quick-link-card.component.scss'
})
export class QuickLinkCardComponent implements OnInit {
	userDetails: any;
	firstName: any;
	// isFavorite: boolean = false;
	isCardHeaderMenuVisible: boolean = false;
	panelOpenState = false;
	selectedFilter = '';
	allFavorites: any;
	favoriteIDs: any = [];
	showSpinnerForProductApply: boolean;
	qid: number = 0;

	toggleCardHeaderMenu() {
		this.isCardHeaderMenuVisible = !this.isCardHeaderMenuVisible;
	}

	mainCategory: any[] = [];

	constructor(
		private nlsnDataStoreService: NlsnDataStoreService,
		private favoriteService: FavoriteService,
		private landingPageService: LandingPageService,
		public cd: ChangeDetectorRef,
		private router: Router
	) {}

	ngOnInit(): void {
		this.userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		this.getCardUserDetails();
		this.loadAllCard();
		this.getAllFavorites();
	}

	getCardUserDetails() {
		this.firstName = this.userDetails?.userinfo?.given_name;
	}

	getAllFavorites(): any {
		this.favoriteService.getFavorites().subscribe(res => {
			this.favoriteService.extractQuestionIds(res.result);
		});
	}

	extractQuestionIds(data: any): any {
		data.forEach(item => {
			this.favoriteIDs.push(item.tblQuestion.id);
		});
	}

	isFavorite(id: number): boolean {
		return this.favoriteService.isFavorite(id);
	}

	toggleFavorite(question: any): any {
		const isFav = this.isFavorite(question.id);
		if (isFav) {
			this.favoriteService.removeFavorite(question.id).subscribe();
		} else {
			this.favoriteService.addFavorite(question.id).subscribe();
		}
	}

	loadAllCard(): void {
		let cards = this.nlsnDataStoreService.getItem(KEY.QUESTIONS);
		if (!_.isEmpty(cards)) {
			// Fetch from cache
			this.mainCategory = cards;
		} else {
			// Call api and fetch new Data
			this.landingPageService?.getAllCard()?.subscribe(response => {
				if (response) {
					this.mainCategory = response;
				}
			});
		}
		this.cd.detectChanges();
	}
	showSpinner(flag: boolean) {
		this.showSpinnerForProductApply = !flag;
	}

	selectedProduct(data: any) {
		this.selectedFilter = data.value;
	}

	navigateToFavorites() {
		this.router.navigate(['/favorites']);
	}
}
