export const DOLLAR = 'dollar';
export const UNITS = 'units';

export const WIDTH = 140;
export const PERIOD_WEEK = '{period} Week';
export const AVAILABLE_PERIODS = [4, 13, 52];

export const ZIP_PERC = "zip:'perc'";
export const ZIP_ROUNDED_NUMBER = "zip:'roundednumber'";
export const ZIP_DOLLAR = "zip:'dollar'";

export const SPECIAL = 'special';
export const SHOW_COLOR = 'showColor';
export const NUMBER = 'number';

export const SHARE_REPORT_CONSTANTS = {
	BASE_FACT_LIST: [
		{
			gkey: 'Product',
			name: 'Product',
			headerName: 'Product',
			field: 'productLevel',
			isSelected: true,
			isPeriodHide: false,
			cellClass: 'normal',
			width: 245,
			pinnedLeft: true,
			isPinned: true
		},
		{
			gkey: 'WM Market Share',
			name: '{period}WM $ Market Share',
			headerName: '{period} Week WM $ Share',
			key: 'WM $ Share',
			field: 'dollarMarketShare_{period}Week',
			valuebase: DOLLAR,
			isSelected: true,
			isPinned: true,
			cellFilter: ZIP_PERC,
			cellClass: SPECIAL,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Share Pt Chg (BPS) vs YA',
			name: '{period}WM $ Share Pt Chg (BPS) vs YA',
			headerName: '{period} Week WM $ Share Pt Chg (BPS) vs YA',
			key: 'WM $ Share Pt Chg (BPS) vs YA',
			field: 'wmDollarSharePTChgBpsYA_{period}Week',
			valuebase: DOLLAR,
			isSelected: true,
			formatType: '',
			isPinned: true,
			cellFilter: ZIP_ROUNDED_NUMBER,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Share Pt Chg (BPS) vs 2YA',
			name: '{period}WM $ Share Pt Chg (BPS) vs 2YA',
			headerName: '{period} Week WM $ Share Pt Chg (BPS) vs 2YA',
			key: 'WM $ Share Pt Chg (BPS) vs 2YA',
			field: 'wmDollarSharePTChgBpsTwoYA_{period}Week',
			valuebase: DOLLAR,
			isSelected: true,
			formatType: '',
			isPinned: true,
			cellFilter: ZIP_ROUNDED_NUMBER,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Value of Share Pt Chg vs YA',
			name: '{period} $ Value of Share Pt Chg vs YA',
			headerName: '{period} Week WM $ Value of Share Pt Chg vs YA',
			key: 'WM $ Value of Share Pt Chg vs YA',
			field: 'dollarValueOfSharePTChgYA_{period}Week',
			valuebase: DOLLAR,
			isSelected: false,
			cellFilter: ZIP_DOLLAR,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Value of Share Pt Chg vs 2YA',
			name: '{period} $ Value of Share Pt Chg vs 2YA',
			headerName: '{period} Week WM $ Value of Share Pt Chg vs 2YA',
			key: 'WM $ Value of Share Pt Chg vs 2YA',
			field: 'dollarValueOfSharePTChgTwoYA_{period}Week',
			valuebase: DOLLAR,
			isSelected: false,
			cellFilter: ZIP_DOLLAR,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales',
			name: '{period}WM $ Sales',
			headerName: '{period} Week WM $ Sales',
			key: 'WM $ Sales',
			field: 'dollarWalmartSales_{period}Week',
			valuebase: DOLLAR,
			isSelected: false,
			cellFilter: ZIP_DOLLAR,
			cellClass: SPECIAL,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales % Chg vs YA',
			name: '{period}WM $ Sales % Chg vs YA',
			headerName: '{period} Week WM $ Sales % Chg vs YA',
			key: 'WM $ Sales % Chg vs YA',
			field: 'wmDollarSalesPctChgYA_{period}Week',
			valuebase: DOLLAR,
			isSelected: false,
			cellFilter: ZIP_PERC,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales % Chg vs 2YA',
			name: '{period}WM $ Sales % Chg vs 2YA',
			headerName: '{period} Week WM $ Sales % Chg vs 2YA',
			key: 'WM $ Sales % Chg vs 2YA',
			field: 'wmDollarSalesPctChgTwoYA_{period}Week',
			valuebase: DOLLAR,
			isSelected: false,
			cellFilter: ZIP_PERC,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales Actual Chg vs YA',
			name: '{period}WM $ Sales Actual Chg vs YA',
			headerName: '{period} Week WM $ Sales Actual Chg vs YA',
			key: 'WM $ Sales Actual Chg vs YA',
			field: 'wmDollarSalesActualChgYA_{period}Week',
			valuebase: DOLLAR,
			isSelected: false,
			cellFilter: ZIP_DOLLAR,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales Actual Chg vs 2YA',
			name: '{period}WM $ Sales Actual Chg vs 2YA',
			headerName: '{period} Week WM $ Sales Actual Chg vs 2YA',
			key: 'WM $ Sales Actual Chg vs 2YA',
			field: 'wmDollarSalesActualChgTwoYA_{period}Week',
			valuebase: DOLLAR,
			isSelected: false,
			cellFilter: ZIP_DOLLAR,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Contribution to Total',
			name: '{period}WM $ Contribution to Total',
			headerName: '{period} Week WM $ Contribution',
			key: 'WM $ Contribution',
			field: 'dollarContributionToTotal_{period}Week',
			valuebase: DOLLAR,
			isSelected: false,
			cellFilter: ZIP_PERC,
			cellClass: SPECIAL,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Fair Share Opportunity',
			name: '{period}$ Fair Share Opportunity',
			headerName: '{period} Week WM $ Fair Share Opportunity',
			key: 'WM $ Fair Share Opportunity',
			field: 'dollarFairShareOpportunity_{period}Week',
			valuebase: DOLLAR,
			isSelected: false,
			cellClass: SHOW_COLOR,
			cellFilter: ZIP_DOLLAR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},

		{
			gkey: 'WM Market Share',
			name: '{period}WM Units Market Share',
			headerName: '{period} Week WM Units Share',
			key: 'WM Units Share',
			field: 'unitsMarketShare_{period}Week',
			valuebase: UNITS,
			isSelected: false,
			isPinned: false,
			cellFilter: ZIP_PERC,
			cellClass: SPECIAL,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Share Pt Chg (BPS) vs YA',
			name: '{period}WM Units Share Pt Chg (BPS) vs YA',
			headerName: '{period} Week WM Units Share Pt Chg (BPS) vs YA',
			key: 'WM Units Share Pt Chg (BPS) vs YA',
			field: 'wmUnitsSharePTChgBpsYA_{period}Week',
			valuebase: UNITS,
			isSelected: false,
			cellFilter: ZIP_ROUNDED_NUMBER,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Share Pt Chg (BPS) vs 2YA',
			name: '{period}WM Units Share Pt Chg (BPS) vs 2YA',
			headerName: '{period} Week WM Units Share Pt Chg (BPS) vs 2YA',
			key: 'WM Units Share Pt Chg (BPS) vs 2YA',
			field: 'wmUnitsSharePTChgBpsTwoYA_{period}Week',
			valuebase: UNITS,
			isSelected: false,
			cellFilter: ZIP_ROUNDED_NUMBER,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Value of Share Pt Chg vs YA',
			name: '{period}Units Value of Share Pt Chg vs YA',
			headerName: '{period} Week WM Units Value of Share Pt Chg vs YA',
			key: 'WM Units Value of Share Pt Chg vs YA',
			field: 'unitsValueOfSharePTChgYA_{period}Week',
			valuebase: UNITS,
			isSelected: false,
			cellFilter: ZIP_ROUNDED_NUMBER,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Value of Share Pt Chg vs 2YA',
			name: '{period}Units Value of Share Pt Chg vs 2YA',
			headerName: '{period} Week WM Units Value of Share Pt Chg vs 2YA',
			key: 'WM Units Value of Share Pt Chg vs 2YA',
			field: 'unitsValueOfSharePTChgTwoYA_{period}Week',
			valuebase: UNITS,
			isSelected: false,
			cellFilter: ZIP_ROUNDED_NUMBER,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales',
			name: '{period}WM Units Sales',
			headerName: '{period} Week WM Units',
			key: 'WM Units',
			field: 'unitsWalmartSales_{period}Week',
			valuebase: UNITS,
			isSelected: false,
			cellFilter: ZIP_ROUNDED_NUMBER,
			cellClass: SPECIAL,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales % Chg vs YA',
			name: '{period}WM Unit Sales % Chg vs YA',
			headerName: '{period} Week WM Units % Chg vs YA',
			key: 'WM Units % Chg vs YA',
			field: 'wmUnitSalesPctChgYA_{period}Week',
			valuebase: UNITS,
			isSelected: false,
			cellFilter: ZIP_PERC,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales % Chg vs 2YA',
			name: '{period}WM Unit Sales % Chg vs 2YA',
			headerName: '{period} Week WM Units % Chg vs 2YA',
			key: 'WM Units % Chg vs 2YA',
			field: 'wmUnitSalesPctChgTwoYA_{period}Week',
			valuebase: UNITS,
			isSelected: false,
			cellFilter: ZIP_PERC,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales Actual Chg vs YA',
			name: '{period}WM Unit Sales Actual Chg vs YA',
			headerName: '{period} Week WM Units Actual Chg vs YA',
			key: 'WM Units Actual Chg vs YA',
			field: 'wmUnitSalesActualChgYA_{period}Week',
			valuebase: UNITS,
			isSelected: false,
			cellFilter: ZIP_ROUNDED_NUMBER,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Sales Actual Chg vs 2YA',
			name: '{period}WM Unit Sales Actual Chg vs 2YA',
			headerName: '{period} Week WM Units Actual Chg vs 2YA',
			key: 'WM Units Actual Chg vs 2YA',
			field: 'wmUnitSalesActualChgTwoYA_{period}Week',
			valuebase: UNITS,
			isSelected: false,
			cellFilter: ZIP_ROUNDED_NUMBER,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Contribution to Total',
			name: '{period}WM Units Contribution to Total',
			headerName: '{period} Week WM Units Contribution',
			key: 'WM Units Contribution',
			field: 'unitsContributionToTotal_{period}Week',
			valuebase: UNITS,
			isSelected: false,
			cellFilter: ZIP_PERC,
			cellClass: SPECIAL,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'WM Fair Share Opportunity',
			name: '{period}Units Fair Share Opportunity',
			headerName: '{period} Week WM Units Fair Share Opportunity',
			key: 'WM Units Fair Share Opportunity',
			field: 'unitsFairShareOpportunity_{period}Week',
			valuebase: UNITS,
			isSelected: false,
			cellClass: SHOW_COLOR,
			cellFilter: ZIP_ROUNDED_NUMBER,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},

		{
			gkey: 'RM Sales',
			name: '{period}RM $ Sales',
			headerName: '{period} Week RM $ Sales',
			key: 'RM $ Sales',
			field: 'dollarRemainingSales_{period}Week',
			valuebase: DOLLAR,
			isSelected: false,
			cellFilter: ZIP_DOLLAR,
			cellClass: SPECIAL,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales % Chg vs YA',
			name: '{period}RM $ Sales % Chg vs YA',
			headerName: '{period} Week RM $ Sales % Chg vs YA',
			key: 'RM $ Sales % Chg vs YA',
			field: 'rmDollarSalesPctChgYA_{period}Week',
			valuebase: DOLLAR,
			isSelected: false,
			cellFilter: ZIP_PERC,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales % Chg vs 2YA',
			name: '{period}RM $ Sales % Chg vs 2YA',
			headerName: '{period} Week RM $ Sales % Chg vs 2YA',
			key: 'RM $ Sales % Chg vs 2YA',
			field: 'rmDollarSalesPctChgTwoYA_{period}Week',
			valuebase: DOLLAR,
			isSelected: false,
			cellFilter: ZIP_PERC,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales Actual Chg vs YA',
			name: '{period}RM $ Sales Actual Chg vs YA',
			headerName: '{period} Week RM $ Sales Actual Chg vs YA',
			key: 'RM $ Sales Actual Chg vs YA',
			field: 'rmDollarSalesActualChgYA_{period}Week',
			valuebase: DOLLAR,
			isSelected: false,
			cellFilter: ZIP_DOLLAR,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales Actual Chg vs 2YA',
			name: '{period}RM $ Sales Actual Chg vs 2YA',
			headerName: '{period} Week RM $ Sales Actual Chg vs 2YA',
			key: 'RM $ Sales Actual Chg vs 2YA',
			field: 'rmDollarSalesActualChgTwoYA_{period}Week',
			valuebase: DOLLAR,
			isSelected: false,
			cellFilter: ZIP_DOLLAR,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales',
			name: '{period}RM Units Sales',
			headerName: '{period} Week RM Units',
			key: 'RM Units',
			field: 'unitsRemainingSales_{period}Week',
			valuebase: UNITS,
			isSelected: false,
			cellFilter: ZIP_ROUNDED_NUMBER,
			cellClass: SPECIAL,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales % Chg vs YA',
			name: '{period}RM Unit Sales % Chg vs YA',
			headerName: '{period} Week RM Units % Chg vs YA',
			key: 'RM Units % Chg vs YA',
			field: 'rmUnitSalesPctChgYA_{period}Week',
			valuebase: UNITS,
			isSelected: false,
			cellFilter: ZIP_PERC,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales % Chg vs 2YA',
			name: '{period}RM Unit Sales % Chg vs 2YA',
			headerName: '{period} Week RM Units % Chg vs 2YA',
			key: 'RM Units % Chg vs 2YA',
			field: 'rmUnitSalesPctChgTwoYA_{period}Week',
			valuebase: UNITS,
			isSelected: false,
			cellFilter: ZIP_PERC,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales Actual Chg vs YA',
			name: '{period}RM Unit Sales Actual Chg vs YA',
			headerName: '{period} Week RM Units Actual Chg vs YA',
			key: 'RM Units Actual Chg vs YA',
			field: 'rmUnitSalesActualChgYA_{period}Week',
			valuebase: UNITS,
			isSelected: false,
			cellFilter: ZIP_ROUNDED_NUMBER,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		},
		{
			gkey: 'RM Sales Actual Chg vs 2YA',
			name: '{period}RM Unit Sales Actual Chg vs 2YA',
			headerName: '{period} Week RM Units Actual Chg vs 2YA',
			key: 'RM Units Actual Chg vs 2YA',
			field: 'rmUnitSalesActualChgTwoYA_{period}Week',
			valuebase: UNITS,
			isSelected: false,
			cellFilter: ZIP_ROUNDED_NUMBER,
			cellClass: SHOW_COLOR,
			width: 140,
			period: '{period} Week',
			type: 'number',
			availablePeriods: [4, 13, 52]
		}
	],

	PERIOD_LIST: [
		{
			key: '4weeks',
			gkey: 4,
			value: 'Last_4_Weeks',
			isSelected: true,
			name: '4 Week'
		},
		{
			key: '13weeks',
			gkey: 13,
			value: 'Last_13_Weeks',
			isSelected: true,
			name: '13 Week'
		},
		{
			key: '52weeks',
			gkey: 52,
			value: 'Last_52_Weeks',
			isSelected: true,
			name: '52 Week'
		}
	],

	parentBarColorSet: ['#EE97EB', '#B21DAD', '#590E57'],
	childBarColorSet: ['#93E2FF', '#00AFEF', '#005777'],
	xAxisChartKeys: [
		'dolUnitMarketShare_4Week',
		'dolUnitMarketShare_13Week',
		'dolUnitMarketShare_52Week',
		'dolUnitWMSharePtChangeBPS_4Week',
		'dolUnitWMSharePtChangeBPS_13Week',
		'dolUnitWMSharePtChangeBPS_52Week'
	]
};
