import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	IconModule,
	RadioButtonModule,
	TabModule,
	ToastModule,
	TooltipModule
} from '@nielseniq/athena-core';
import { CommonComponentModule } from 'src/app/shared/components/grid/common-component.module';
import { FooterComponent } from './report/footer/footer.component';
import { HeaderComponent } from './report/header/header.component';
import { ComponentModule } from './selector/component.module';
import { GisMapComponent } from './gis-map/gis-map.component';
import { SharedDirectiveModule } from '../shared-directive/shared-directive.module';

@NgModule({
	declarations: [HeaderComponent, FooterComponent, GisMapComponent],
	imports: [
		SharedDirectiveModule,
		CommonModule,
		IconModule,
		TooltipModule,
		ComponentModule,
		TabModule,
		CommonComponentModule,
		ToastModule,
		RadioButtonModule
	],
	exports: [HeaderComponent, FooterComponent, TabModule, GisMapComponent]
})
export class SharedComponentModule {}
