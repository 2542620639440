<niq-page-header class="page-header-container" variant="default">
	<div class="align-items-stretch d-flex" style="box-shadow: 2px 2px 8px rgb(0 0 0 / 20%)">
		<div class="title d-flex align-items-center flex-grow-1">
			<img
				class="ml-2 logo-padding logo-cursor"
				(click)="navigate()"
				src="assets/img/niqLogo.svg"
				alt="NielsenIQ"
			/>
			<!-- </div> -->
			<h3 class="m-0 py-3">{{ header.bannerTitleText }}</h3>
		</div>
		<div class="icons-top d-flex align-items-center flex-shrink-0">
			<button
				niqTooltip="Contact client services"
				type="image"
				class="button"
				(click)="contactClientDialog.open()"
				[niqTooltipShowArrow]="true"
			>
				<img
					src="assets/img/header-contact-client-services.svg"
					alt="Client-Feedback"
					(click)="contactClientDialog.open()"
					style="color: #060a45"
				/>
			</button>

			<button
				niqTooltip="Applications"
				type="image"
				class="button"
				(click)="toggleAppMenuPanel()"
				[niqTooltipShowArrow]="true"
			>
				<niq-icon
					niqTooltipShowArrow="true"
					size="medium"
					iconName="menu-app-bento"
					class="no-outside-click-check"
					style="color: #060a45"
				></niq-icon>
			</button>

			<button
				niqTooltip="Notifications"
				type="image"
				class="button"
				(click)="toggleNotificationPanel()"
				[niqTooltipShowArrow]="true"
			>
				<img
					src="assets/img/alert-icon.svg"
					alt="Notifications"
					class="no-outside-click-check"
					style="color: #060a45"
				/>
				<div class="red-badge" *ngIf="unreadNotificationCount > 0">
					<img src="assets/img/badge-one.svg" alt="badge" class="no-outside-click-check" />
				</div>
			</button>

			<button
				type="image"
				class="button-profile profile-container d-flex align-items-center no-outside-click-check"
			>
				<p class="m-0 margin-right-2 user-name user-name-topbar">{{ firstName }}</p>
				<niq-icon
					size="medium"
					iconName="account-outline"
					colorTheme="light"
					class="no-outside-click-check margin-right-2"
				></niq-icon>
				<niq-icon
					(click)="toggleProfileMenu()"
					size="medium"
					iconName="chevron-down"
					colorTheme="light"
					class="no-outside-click-check margin-right-2"
				></niq-icon>
				<div
					class="profile-dropdown"
					*ngIf="isProfileMenuVisible"
					clickOutside
					(clickOutsideEvent)="closeAllPanel()"
					(click)="onLogout()"
				>
					Logout
				</div>
			</button>

			<button type="image" class="button" routerLink="/training">
				<niq-icon
					niqTooltip="Training"
					niqTooltipPosition="top"
					niqTooltipShowArrow="true"
					size="medium"
					iconName="help"
					style="color: #060a45"
				></niq-icon>
			</button>
		</div>
	</div>
</niq-page-header>

<!--App Menu Side Panel-->
<div
	class="panel-container app-menu-side-panel"
	[hidden]="!isAppMenuPanelVisible"
	style="position: fixed; overflow-x: hidden; height: 100%"
>
	<!-- 
		titleAlignment="left" -->
	<niq-side-panel
		#sidePanel
		clickOutside
		title=""
		(clickOutsideEvent)="closeAllPanel()"
		withClose="true"
	>
		<ng-container side-panel-content>
			<div class="application-title">Applications</div>
			<div style="margin-top: -18px; margin-bottom: 28px">
				<niq-icon
					state="primary"
					size="x-small"
					iconName="close"
					colorTheme="light"
					(click)="closeAllPanel()"
				></niq-icon>
			</div>

			<div class="side-panel-content">
				<crf-ca-app-section (appMenuSelect)="closeAllPanel()" [isConcierge]="page.isConcierge">
				</crf-ca-app-section>
			</div>
		</ng-container>
	</niq-side-panel>
</div>

<!--Notification Side Panel-->
<!-- (clickOutsideEvent)="closeAllPanel()" -->
<div
	class="panel-container notification-side-panel"
	[hidden]="!isNotificationPanelVisible"
	style="height: 580%"
>
	<niq-side-panel #sideNotificationPanel title="Notifications" titleAlignment="left" clickOutside>
		<ng-container side-panel-content>
			<ul>
				<li
					*ngFor="let notification of notificationData"
					[class]="{
						'd-flex': true,
						'align-items-center': true,
						'p-4': true,
						unread: notification.isViewed
					}"
					(click)="setNotificationAsRead(notification.messageId)"
				>
					<p class="app-text m-0 p-0">{{ notification.heading }}</p>
					<niq-icon size="small" iconName="arrow-forward-ios-new"></niq-icon>
				</li>
			</ul>
		</ng-container>
	</niq-side-panel>
</div>

<!--Message Dialog-->
<niq-dialog #contactClientDialog size="medium" closeOnClickOutside="true">
	<div dialogTitle>Message</div>
	<div dialogBody>
		<p>
			For One Version of Truth (OVT) help or support, please contact
			<a target="_blank" href="mailto:Walmart.OVTSupport@smb.nielseniq.com">
				<strong>Walmart.OVTSupport&#64;smb.nielseniq.com</strong> </a
			>.<br />
			Our team is committed to you and we will respond within two (2) business days.
		</p>
	</div>
	<div dialogFooter>
		<niq-button (click)="contactClientDialog.close()" size="medium" variant="primary"
			>OK</niq-button
		>
	</div>
</niq-dialog>

<!--Feedback Dialog-->
<!-- <niq-dialog #feedbackDialog size="medium" closeOnClickOutside="true">
	<div dialogTitle>Send us your feedback</div>
	<div dialogBody>
		<form method="post" [formGroup]="feedbackFormGroup">
			<div class="form-group">
				<label for="feedback-select" class="mr-5">Report <span class="required">*</span></label>
				<select name="feedback-select" formControlName="dashboardIndex">
					<option
						*ngFor="
							let feedbackFormReportDropdownOption of feedbackFormReportDropdownOptions;
							let index = index
						"
						[value]="index"
					>
						{{ feedbackFormReportDropdownOption.text }}
					</option>
				</select>
				<div
					*ngIf="
						feedbackFormGroup.controls['dashboardIndex'].invalid &&
						(feedbackFormGroup.controls['dashboardIndex'].dirty ||
							feedbackFormGroup.controls['dashboardIndex'].touched ||
							isFeedbackFormSubmittedAndInvalid)
					"
				>
					<small class="required">Report is required.</small>
				</div>
			</div>
			<div class="form-group">
				<label for="feedback-textarea">Feedback <span class="required">*</span></label>
				<textarea name="feedback" formControlName="feedback" rows="5"> </textarea>
				<div
					*ngIf="
						feedbackFormGroup.controls['feedback'].invalid &&
						(feedbackFormGroup.controls['feedback'].dirty ||
							feedbackFormGroup.controls['feedback'].touched ||
							isFeedbackFormSubmittedAndInvalid)
					"
				>
					<small class="required">Feedback is required.</small>
				</div>
			</div>
		</form>
	</div>
	<div dialogFooter>
		<niq-button (click)="feedbackDialog.close()" size="medium" variant="ghost">Cancel</niq-button>
		<niq-button size="medium" variant="primary" class="ml-3" (click)="submitFeedback()"
			>Send</niq-button
		>
	</div>
</niq-dialog> -->

<!--Notification Dialog-->
<niq-dialog #notificationDialog size="medium" [closeOnClickOutside]="true" [closeOnEsc]="true">
	<div dialogTitle>Notifications {{ notificationData.length }}</div>
	<div dialogBody>
		<div class="notifications-container p-3">
			<div
				[class]="{ 'notification-container': true, 'p-4': true, unread: notification.isViewed }"
				*ngFor="let notification of notificationData"
			>
				<h4 class="m-0 p-0">{{ notification.heading }}</h4>
				<h5 class="m-0 p-0">{{ notification.subheading }}</h5>
				<small>{{ notification.date | date }}</small>
				<p class="mt-3 p-0">{{ notification.content }}</p>
			</div>
		</div>
	</div>
	<div dialogFooter>
		<niq-button (click)="notificationDialog.close()" size="medium" variant="primary"
			>Close</niq-button
		>
	</div>
</niq-dialog>
