import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BASE_URL } from 'src/environments/env.config.constants';
import { REST_API_CONSTANTS } from '../../../constants/rest-api-constants';
import { Observable, map } from 'rxjs';
import { NlsnDataStoreService } from '../../../core/service/store/nlsn-data-store.service';
import { KEY } from '../../../constants/data-constants';
import { NlsnGlobalsContextExtract } from '../../../core/service/auth/nlsn-globals.service';
import { ReportService } from '../../../service/report.service';

@Injectable({
	providedIn: 'root'
})
export class PerformanceQuadrantReportService extends ReportService {
	options: any = {};

	constructor(
		http: HttpClient,
		nlsnDataStoreService: NlsnDataStoreService,
		nlsnGlobalsContextExtract: NlsnGlobalsContextExtract
	) {
		super(http, nlsnDataStoreService, nlsnGlobalsContextExtract);
		this.options.headers = new HttpHeaders().append(
			'X-NIQ-ACCESS-TOKEN',
			this.nlsnDataStoreService.getItem(KEY.COOKIE)?.access_token
		);
	}

	getData(period: string, by: string, factType: string, payload: any): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			REST_API_CONSTANTS.REPORT.PERFORMANCE_QUADRANT_GRID_DATA.URL +
			'/' +
			period +
			'/' +
			by +
			'/' +
			factType;

		return this.http.post<any>(encodeURI(pingURL), payload, this.options).pipe(
			map(result => {
				return result;
			})
		);
	}
}
