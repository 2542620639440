import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import * as _ from 'lodash';
import { BASE_FACT_LIST } from '../model/pricebeat.const';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
	BASE_URL,
	OVT_UI_URL_DASHBOARD_SELECTION,
	PRICE_BEATS_DASHBOARD_URL
} from 'src/environments/env.config.constants';
import { NlsnDataStoreService } from 'src/app/core/service/store/nlsn-data-store.service';
import { NlsnGlobalsContextExtract } from 'src/app/core/service/auth/nlsn-globals.service';
import { KEY } from 'src/app/constants/data-constants';
interface Product {
	[key: string]: any;
}

interface BaseFact {
	value: string;
	key: string;
}
interface BaseFact {
	[key: string]: any;
}

@Injectable({
	providedIn: 'root'
})
export class PricebeatApiService {
	defaultSelectors: any;
	constructor(
		private http: HttpClient,
		private nlsnDataStoreService: NlsnDataStoreService,
		private nlsnGlobalsContextExtract: NlsnGlobalsContextExtract
	) {}

	@Injectable({
		providedIn: 'root'
	})
	public getAllBeatsPeriod(): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			OVT_UI_URL_DASHBOARD_SELECTION.GET_ALL_BEATS_PERIODS;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.get<any>(pingURL, headers).pipe(
			map(result => {
				return result.responseData;
			})
		);
	}

	public getPeriodEndDate(queid, period, data): Observable<any> {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			OVT_UI_URL_DASHBOARD_SELECTION.PERIODS_END_DATE +
			queid +
			'/' +
			period;
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		return this.http.post<any>(pingURL, data, headers).pipe(
			map(result => {
				return result.responseData;
			})
		);
	}

	totalMarketList(payload: any): Observable<any> {
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			PRICE_BEATS_DASHBOARD_URL.TABLE_POST;
		return this.http.post(pingURL, payload, headers);
	}

	getMapData(payload: any): Observable<any> {
		const userDetails = this.nlsnDataStoreService.getItem(KEY.COOKIE);
		const headers = {
			headers: new HttpHeaders().append('X-NIQ-ACCESS-TOKEN', userDetails?.access_token)
		};
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			PRICE_BEATS_DASHBOARD_URL.MARKET_POST;
		return this.http.post(pingURL, payload, headers).pipe(
			map((response: any) => {
				if (!_.isNil(response.markets) && response.markets[0]) {
					let responseKeys = Object.keys(response.markets[0]);
					let modifiedMarket = response.markets.map(market => {
						let newObj = {};
						responseKeys.forEach(keyName => {
							newObj[response.nameMap[keyName]] = market[keyName];
						});
						return newObj;
					});
					response.markets = modifiedMarket;
					return response;
				} else {
					return response;
				}
			})
		);
	}

	prepareTableData(resData: Product[], baseFactValue: string) {
		const baseFact = this.getBaseFactByProperty('value', baseFactValue);
		let tempData = [];
		let tableData = {
			top: [],
			bottom: [],
			main: []
		};
		let tableDataOrdered = [];
		let topVal = [],
			botVal = [];

		if (resData.length === 0) {
			return tableData;
		}

		tableData.main = resData;

		const isPctPriceBeats = baseFact.value === '%Beats';

		tableDataOrdered = this.orderBy(tableData.main, baseFact, isPctPriceBeats ? 'asc' : 'desc');

		if (baseFact.value === 'Units Beats' || isPctPriceBeats) {
			let tableDataTemp = [...tableDataOrdered];
			tableData.top = tableDataTemp.splice(0, 10);
			tableData.bottom = this.orderBy(
				tableDataTemp.slice(-10),
				baseFact,
				isPctPriceBeats ? 'desc' : 'asc'
			);
		} else {
			tableDataOrdered.forEach(product => {
				let num = parseFloat(product[baseFact.key]);
				if (num >= 0) {
					topVal.push(product);
				} else {
					botVal.push(product);
				}
			});

			tableData.top = topVal.slice(0, 10);

			tableData.bottom = this.orderBy(botVal, baseFact, 'asc');
			tableData.bottom = tableData.bottom.slice(0, 10);

			if (baseFact.value === 'Beats%Chg') {
				tempData = tableData.top;
				tableData.top = tableData.bottom;
				tableData.bottom = tempData;
			}
		}
		return tableData;
	}

	getBaseFactByProperty(property: string, value: any): BaseFact | undefined {
		return _.find(BASE_FACT_LIST, (baseFact: BaseFact) => baseFact[property] === value);
	}

	orderBy(elements, baseFact, orderType) {
		return _.orderBy(
			elements,
			[
				product => {
					return parseFloat(product[baseFact.key]);
				}
			],
			[orderType]
		);
	}
}
