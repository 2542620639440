import { SelectorConfig } from 'src/app/shared/model/report/report-header.type';
import { SELECTOR } from 'src/app/shared/constants/selector.constant';
import { FACT_SPECIFICATION } from 'src/app/shared/constants/fact-sepcification/consumer-decision-tree.constant';

export const selectorConfigurations: SelectorConfig[] = [
	{
		id: SELECTOR.PRODUCT.ID,
		available: true,
		multiSelect: false,
		apiFetched: true
	},
	{
		id: SELECTOR.FACT.ID,
		available: true,
		multiSelect: {
			header: true,
			facts: true
		},
		column: {
			others: true
		},
		apiFetched: false,
		value: FACT_SPECIFICATION
	}
];
