<div class="main-menu consumer-drive-header">
	<div>
		<div class="mr-4">
			<div class="reportHeader">
				<h4 class="m-0 reportName">{{ pageTitle }}</h4>
			</div>
			<br />
			<div class="mb-0 reportMenu">
				<div>
					<span
						promptClickOutside
						(clickOutside)="closeDropdown('fact')"
						#fact
						class="report-dropdown dropdown dropdown-ovt"
					>
						<a
							id="baseFactSelectBtn"
							type="button"
							(click)="toggleDropdown('fact')"
							[niqTooltip]="factTooltipContent"
							niqTooltipPosition="bottom"
							[niqTooltipColorTheme]="'dark'"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<span class="text">{{ productPerformanceCtrl.volumeBasis }}</span>
						</a>
						<ng-template #factTooltipContent>
							<div style="padding: 9px 14px; color: #000000">
								{{ pageHoverTexts.basefactTooltip }}
							</div>
						</ng-template>
						<app-dropdown-menu
							[hidden]="!showDropdown.fact"
							[items]="factList"
							(selectedItem)="factSelected($event)"
							[selectorType]="4"
							(cancel)="showDropdown.fact = false"
							[configId]="qid"
							(onDefaultChange)="onMoreOptionClicked($event)"
						></app-dropdown-menu>
					</span>
					<!-- Product -->
					for
					<span
						promptClickOutside
						(clickOutside)="closeDropdown('product')"
						#product
						class="report-dropdown dropdown dropdown-ovt select-dropdown"
					>
						<a
							id="productSelect"
							type="button"
							(click)="toggleDropdown('product')"
							[niqTooltip]="productTooltipContent"
							niqTooltipPosition="bottom"
							[niqTooltipColorTheme]="'dark'"
						>
							<span class="product-input-text"> {{ selectedProductToShow.value }} </span>
						</a>
						<crf-ca-product-selector-single
							[hidden]="!showDropdown.product"
							(onClose)="toggleDropdown('product')"
							[selectorType]="3"
							[configId]="qid"
							(updateReport)="updateProductSelection($event)"
							(onDefaultChange)="onMoreOptionClicked($event)"
						></crf-ca-product-selector-single>
						<ng-template #productTooltipContent>
							<div style="padding: 9px 14px; color: #000000">
								{{ pageHoverTexts.productTooltip }}
							</div>
						</ng-template>
					</span>
					<!-- By Level Menu -->
					by
					<span
						promptClickOutside
						(clickOutside)="closeDropdown('byLevel')"
						#byLevel
						class="report-dropdown dropdown dropdown-ovt"
					>
						<a
							id="levelSelectorBtn"
							type="button"
							(click)="toggleDropdown('byLevel')"
							[niqTooltip]="levelTooltipContent"
							niqTooltipPosition="bottom"
							[niqTooltipColorTheme]="'dark'"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<span>{{ productPerformanceCtrl.selectedLevelToShow }}</span>
						</a>
						<ng-template #levelTooltipContent>
							<div style="padding: 9px 14px; color: #000000">
								{{ pageHoverTexts.levelTooltip }}
							</div>
						</ng-template>
						<app-dropdown-menu
							[hidden]="!showDropdown.byLevel"
							[items]="categoryList"
							(selectedItem)="levelSelected($event)"
							[selectorType]="1"
							(cancel)="showDropdown.byLevel = false"
							[configId]="qid"
						></app-dropdown-menu>
					</span>
					<!-- Market -->
					in
					<span
						promptClickOutside
						(clickOutside)="closeDropdown('market')"
						class="report-dropdown dropdown dropdown-ovt"
					>
						<a
							id="marketSelectorBtn"
							type="button"
							(click)="toggleDropdown('market')"
							[niqTooltip]="marketTooltipContent"
							niqTooltipPosition="bottom"
							[niqTooltipColorTheme]="'dark'"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
							><span>{{ productPerformanceCtrl.selectedMarket[0] }}</span>
							<span
								*ngIf="productPerformanceCtrl.selectedMarket.length > 1"
								title="{{ productPerformanceCtrl.selectedMarket.join(',') }}"
								class="market-more-icon"
								>+{{ productPerformanceCtrl.selectedMarket.length - 1 }}</span
							>
						</a>
						<ng-template #marketTooltipContent>
							<div style="padding: 9px 14px; color: #000000">
								{{ pageHoverTexts.marketTooltip }}
							</div>
						</ng-template>
						<app-multiselect-dropdown
							*ngIf="showDropdown.market"
							[placeholder]="'Search markets'"
							[selectorType]="2"
							[configId]="qid"
							[marketData]="marketMenuItems"
							(onCancel)="toggleDropdown('market')"
							(onApply)="addselectedMarket($event)"
							(onDefaultChange)="onMoreOptionClicked($event)"
						></app-multiselect-dropdown>
					</span>
					during
					<span
						promptClickOutside
						(clickOutside)="closeDropdown('period')"
						#period
						class="report-dropdown dropdown dropdown-ovt"
					>
						<a
							id="periodSelectorBtn"
							type="button"
							(click)="toggleDropdown('period')"
							[niqTooltip]="periodTooltipContent"
							niqTooltipPosition="bottom"
							[niqTooltipColorTheme]="'dark'"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							<span>
								{{ productPerformanceCtrl.period }}
							</span>
						</a>
						<ng-template #periodTooltipContent>
							<div style="padding: 9px 14px; color: #000000">
								{{ pageHoverTexts.periodTooltip }}
							</div>
						</ng-template>
						<app-dropdown-menu
							[hidden]="!showDropdown.period"
							[items]="periodList"
							(selectedItem)="periodSelected($event)"
							(cancel)="showDropdown.period = false"
							[selectorType]="5"
							[configId]="qid"
							(onDefaultChange)="onMoreOptionClicked($event)"
						></app-dropdown-menu>
					</span>
				</div>
			</div>
			<crf-ca-export-report (onExport)="reportDownload($event)"></crf-ca-export-report>

			<crf-ca-save-default
				class="save-selection-all"
				[selectorType]="7"
				[configId]="qid"
				(menuSelector)="onMoreOptionClicked($event)"
			>
			</crf-ca-save-default>
		</div>
	</div>
</div>
<!--Selectors -->
<div>
	<div class="clearfix spacer-20"></div>
	<!-- <div
		class="overlay-bg-ppt"
		[hidden]="isProductPerformanceInfoReady && !isExportDownloadInProcess"
	></div> -->
	<span *ngIf="isExportDownloadInProcess" class="spinner-large">
		<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
	</span>
	<div>
		<div class="consumer-drive-container">
			<div id="loadData" class="inner-second" *ngIf="isDataNOTAvailable">
				<div class="ovt-no-data-message">
					<span class="glyphicon glyphicon-exclamation-sign"></span>
					Data Not Available
					<span id="closeId" class="ovt-no-data-message-close" (click)="isDataNOTAvailable = false"
						>&times;</span
					>
				</div>
			</div>
			<div class="panel-dashboard-container">
				<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
					<div class="panel-dashboard-data">
						<!--Left Side -->
						<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 panel-left">
							<div class="panel panel-default panel-pp-left">
								<div class="purchase-dyna-block">
									<span> {{ PP_CON.SALES_MARKET }} </span>
								</div>
								<div class="clearfix"></div>
								<div class="pp-market-share">
									<span *ngIf="!chartInfoReady" class="spinner-large">
										<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
									</span>

									<h5 class="ma-sort-head">
										<span *ngIf="isUnitsSelected"
											><span> {{ PP_CON.UNIT_SALES }} </span></span
										>
										<span *ngIf="!isUnitsSelected"
											><span> {{ PP_CON.DOLLAR_SALES }} </span></span
										>
									</h5>
									<h5 class="ma-sales-head">
										<span *ngIf="isUnitsSelected"
											><span> {{ PP_CON.UNIT_CHG }} </span></span
										>
										<span *ngIf="!isUnitsSelected"
											><span> {{ PP_CON.SALES_CHG }} </span></span
										>
									</h5>
									<h5 class="ma-share-head">
										<span *ngIf="isUnitsSelected"
											><span> {{ PP_CON.UNIT_SHARE }} </span></span
										>
										<span *ngIf="!isUnitsSelected"
											><span> {{ PP_CON.DOLLAR_SHARE }} </span></span
										>
									</h5>
									<!--List-->
									<ul class="ma-text-graph-value">
										<li *ngFor="let list of RHS_LIST">
											<!-- Parent -->
											<div
												class="ma-row"
												[ngStyle]="setLabelListHeight()"
												(click)="getProductPerformanceInfo(list.current)"
												[ngClass]="{ active: isActive(list, 'level1') }"
											>
												<span class="ma-text" title="{{ list.current.productName }}">
													{{ list.current.productName | truncateString: 18 }}
												</span>
												<span [ngStyle]="checkColor(list.current.sharePtChange)">
													<span class="ma-value">
														<em> {{ list.current.sharePtChange | formatNumber }} </em>
													</span>
												</span>
											</div>
											<ul *ngIf="list.childData.length">
												<li *ngFor="let child of list.childData">
													<div
														class="ma-row"
														[ngStyle]="setLabelListHeight()"
														(click)="getProductPerformanceInfo(child)"
														[ngClass]="{ active: isActive(child, 'level2') }"
													>
														<!-- active-->
														<span class="ma-text" title="{{ child.productName }}">
															{{ child.productName | truncateString: 18 }}
														</span>
														<span [ngStyle]="checkColor(child.sharePtChange)">
															<span class="ma-value"
																><em>{{ child.sharePtChange | formatNumber }}</em></span
															>
														</span>
													</div>
												</li>
											</ul>
										</li>
									</ul>
									<!--Chart-->
									<div class="ma-group-chart">
										<div id="maGroupChart"></div>
									</div>
								</div>
							</div>

							<div class="pp-bottom-help-text">
								<div class="col-lg-5 col-md-4 col-sm-4 col-xs-12"></div>

								<div class="col-lg-7 col-md-8 col-sm-8 col-xs-12">
									<div class="color-index" [hidden]="!chartInfoReady">
										<span class="blue-index"><span class="blue-box"></span>Walmart</span>
										<span class="yellow-pen"><span class="yellow-box"></span>Remaining Market</span>
									</div>
								</div>
							</div>
						</div>

						<!--Right Side -->
						<div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 panel-right">
							<span *ngIf="!isProductPerformanceInfoReady" class="spinner-large">
								<!-- <em class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></em> -->
								<niq-spinner colorTheme="light" size="medium" variant="brand"></niq-spinner>
							</span>
							<div class="panel panel-default panel-pp-right">
								<!--header-->
								<div class="purchase-dyna-block">
									<span>
										<span>{{ categoryText }}</span>
									</span>
								</div>

								<div class="product-performance-status-right">
									<div class="top-details">
										<div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
											<table *ngIf="isProductPerformanceInfoReady" aria-describedby="">
												<tbody>
													<tr>
														<th scope="col" colspan="2">
															<h4>
																<span>{{ PP_CON.REMAINING_MARKET }}</span>
															</h4>
														</th>
													</tr>
													<tr>
														<td>
															<h6>
																<span>
																	RM
																	{{
																		productPerformanceCtrl.volumeBasis === '$'
																			? productPerformanceCtrl.volumeBasis + ' ' + PP_CON.SALES
																			: productPerformanceCtrl.volumeBasis
																	}}</span
																>
															</h6>
															<div class="clearfix"></div>
															<!--SALES % CHG-->
														</td>
														<td>
															<h6>
																<span>
																	{{
																		'RM' +
																			' ' +
																			productPerformanceCtrl.volumeBasis +
																			' ' +
																			PP_CON.CONTRI_PER
																	}}
																</span>
															</h6>
														</td>
													</tr>
													<tr>
														<td>
															<h3>
																<span>
																	{{ pp_values.remainingMarketSales }}
																</span>
															</h3>
														</td>
														<td title="{{ contribution_metrics_hovertext }}">
															<h3>
																<span *ngIf="pp_values.remainingContribution !== null">{{
																	pp_values.remainingContribution
																}}</span>
															</h3>
															<span
																*ngIf="pp_values.remainingContribution === null"
																title="Metric Not Applicable"
																>-</span
															>
															<span *ngIf="pp_values.remainingContributionFlag">*</span>
															<div class="clearfix"></div>
														</td>
													</tr>
													<tr>
														<td>
															<h6>
																<span>
																	{{
																		'RM ' +
																			productPerformanceCtrl.volumeBasis +
																			' ' +
																			PP_CON.SALES_PT_CHANGE
																	}}
																</span>
															</h6>

															<div class="clearfix"></div>
														</td>
														<td>
															<h6>
																<span>
																	{{
																		'RM ' +
																			productPerformanceCtrl.volumeBasis +
																			' ' +
																			PP_CON.PT_CHG_BPS
																	}}
																</span>
															</h6>
														</td>
													</tr>
													<tr>
														<td>
															<h3>
																<span [ngStyle]="checkColor(pp_values.remainingMarketSalesChange)">
																	<span>{{ pp_values.remainingMarketSalesChange }}</span>
																</span>
															</h3>
														</td>
														<td title="{{ contribution_metrics_hovertext }}">
															<h3>
																<span
																	[ngStyle]="checkColor(pp_values.remainingContributionPtChange)"
																>
																	<span *ngIf="pp_values.remainingContributionPtChange !== null">
																		{{ pp_values.remainingContributionPtChange }}
																	</span>
																</span>
																<span
																	*ngIf="pp_values.remainingContributionPtChange === null"
																	title="Metric Not Applicable"
																	>-</span
																>
															</h3>
														</td>
													</tr>
												</tbody>
											</table>
										</div>

										<div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
											<table *ngIf="isProductPerformanceInfoReady" aria-describedby="">
												<tbody>
													<tr>
														<th scope="col" colspan="4">
															<h4>
																<span> {{ PP_CON.WALMART }} </span>
															</h4>
															<div class="clearfix"></div>
														</th>
													</tr>
													<tr>
														<td>
															<h6>
																<span
																	>WM
																	{{
																		productPerformanceCtrl.volumeBasis === '$'
																			? productPerformanceCtrl.volumeBasis + ' ' + PP_CON.SALES
																			: productPerformanceCtrl.volumeBasis
																	}}</span
																>
															</h6>
														</td>
														<td>
															<h6>
																<span>
																	{{
																		'WM ' +
																			productPerformanceCtrl.volumeBasis +
																			' ' +
																			PP_CON.MARKET_SHARE
																	}}
																</span>
															</h6>
														</td>
														<td>
															<h6>
																<span>
																	{{
																		'WM ' +
																			productPerformanceCtrl.volumeBasis +
																			' ' +
																			PP_CON.CONTRI_PER
																	}}
																</span>
															</h6>
														</td>
														<td>
															<h6 class="green">
																<span>&nbsp; </span>
															</h6>
														</td>
													</tr>
													<tr>
														<td>
															<h3>
																<span>{{ pp_values.walmartSales }}</span>
															</h3>
														</td>
														<td>
															<h3>
																<span> {{ pp_values.walmartMarketShare }} </span>
															</h3>
														</td>
														<td title="{{ contribution_metrics_hovertext }}">
															<h3>
																<span *ngIf="pp_values.walmartContribution !== null">{{
																	pp_values.walmartContribution
																}}</span>
																<span
																	*ngIf="pp_values.walmartContribution === null"
																	title="Metric Not Applicable"
																	>-</span
																>
															</h3>
														</td>
														<td>
															<h6 class="green">
																<span>&nbsp; </span>
															</h6>
														</td>
													</tr>
													<tr>
														<td>
															<h6 class="green">
																<span>
																	{{
																		'WM ' +
																			productPerformanceCtrl.volumeBasis +
																			' ' +
																			PP_CON.SALES_PT_CHANGE
																	}}</span
																>
															</h6>
														</td>
														<td>
															<h6>
																<span>
																	{{
																		'WM ' +
																			productPerformanceCtrl.volumeBasis +
																			' ' +
																			PP_CON.SHARE_PT_CHG
																	}}
																</span>
															</h6>
														</td>
														<td>
															<h6 class="green">
																<span>
																	{{
																		'WM ' +
																			productPerformanceCtrl.volumeBasis +
																			' ' +
																			PP_CON.PT_CHG_BPS
																	}}
																</span>
															</h6>
														</td>
														<td>
															<h6 class="green">
																<span>
																	{{
																		'WM ' +
																			productPerformanceCtrl.volumeBasis +
																			' ' +
																			PP_CON.DOLLAR_SHARE_PT_CHG
																	}}
																</span>
															</h6>
														</td>
													</tr>
													<tr>
														<td>
															<h3>
																<span [ngStyle]="checkColor(pp_values.walmartSalesChange)">
																	<span>{{ pp_values.walmartSalesChange }}</span>
																</span>
															</h3>
														</td>
														<td>
															<h3>
																<span [ngStyle]="checkColor(pp_values.walmartSharePtChange)">
																	<span>{{ pp_values.walmartSharePtChange }}</span>
																</span>
															</h3>
														</td>
														<td title="{{ contribution_metrics_hovertext }}">
															<h3>
																<span [ngStyle]="checkColor(pp_values.walmartContributionPtChange)">
																	<span *ngIf="pp_values.walmartContributionPtChange !== null">
																		{{ pp_values.walmartContributionPtChange }}
																	</span>
																	<span
																		*ngIf="pp_values.walmartContributionPtChange == null"
																		title="Metric Not Applicable"
																		>-</span
																	>
																</span>
															</h3>
														</td>
														<td>
															<h3>
																<span [ngStyle]="checkColor(pp_values.walmartValueOfSharePtChange)">
																	<span>{{ pp_values.walmartValueOfSharePtChange }}</span>
																</span>
															</h3>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
									<div class="clearfix"></div>
									<div class="dashboard-tabs-container">
										<div class="dashboard-tabs-nav">
											<div class="dashboard-tabs-pp">
												<nav class="navbar">
													<ul class="nav navbar-nav">
														<li [class.active]="selectedTab === 'top10_div'">
															<a (click)="selectTab('top10_div')">WM Top 10</a>
														</li>
														<li [class.active]="selectedTab === 'bottom10_div'">
															<a (click)="selectTab('bottom10_div')">WM Bottom 10</a>
														</li>
														<li [class.active]="selectedTab === 'RMtop10_div'">
															<a (click)="selectTab('RMtop10_div')">RM Top 10</a>
														</li>
														<li [class.active]="selectedTab === 'RMbottom10_div'">
															<a (click)="selectTab('RMbottom10_div')">RM Bottom 10</a>
														</li>
														<li title="Click to Sortby fact" class="pp-sort-by">
															<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
																<div class="dropdown dropdown-ovt">
																	<span style="font-size: 11px">Based on</span>
																	<div class="row">
																		<niq-menu (selectedChange)="sortDataBy($event)">
																			<niq-drop-down
																				variant="inline"
																				[White]="true"
																				[value]="selectedSort.label"
																				[isOpen]="isOpen"
																				dropdown-trigger
																			>
																			</niq-drop-down>
																			<div dropdown-content>
																				<niq-item *ngFor="let wm of sortOptions">{{
																					wm.label
																				}}</niq-item>
																			</div>
																		</niq-menu>
																	</div>
																</div>
															</div>
														</li>
													</ul>
												</nav>
											</div>
										</div>

										<div class="dashboard-tabs-pp-data tab-content" *ngIf="objFact">
											<div
												class="tab-pane"
												id="top10_div"
												[class.active]="selectedTab === 'top10_div'"
											>
												<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
													<!-- Grid table -->
													<crf-ag-grid
														#grid
														[columns]="
															productPerformanceCtrl.volumeBasis === '$'
																? WMDollarDefs
																: WMUnitsDefs
														"
														[rowData]="objFact[selectedSort.value].top10"
														[customStyle]="'height:240px'"
														[flex]="true"
													>
													</crf-ag-grid>
												</div>
											</div>
											<div
												class="tab-pane"
												id="bottom10_div"
												[class.active]="selectedTab === 'bottom10_div'"
											>
												<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
													<!-- Grid table -->
													<crf-ag-grid
														#grid
														[columns]="
															productPerformanceCtrl.volumeBasis === '$'
																? WMDollarDefs
																: WMUnitsDefs
														"
														[rowData]="objFact[selectedSort.value].bottom10"
														[customStyle]="'height:240px'"
														[flex]="true"
													>
													</crf-ag-grid>
												</div>
											</div>
											<div
												class="tab-pane"
												id="RMtop10_div"
												[class.active]="selectedTab === 'RMtop10_div'"
											>
												<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
													<!-- Grid table -->
													<crf-ag-grid
														#grid
														[columns]="
															productPerformanceCtrl.volumeBasis === '$'
																? RMDollarDefs
																: RMUnitsDefs
														"
														[rowData]="objFact[selectedSort.value].remainingtop10"
														[customStyle]="'height:240px'"
														[flex]="true"
													>
													</crf-ag-grid>
												</div>
											</div>
											<div
												class="tab-pane"
												id="RMbottom10_div"
												[class.active]="selectedTab === 'RMbottom10_div'"
											>
												<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
													<!-- Grid table -->
													<crf-ag-grid
														#grid
														[columns]="
															productPerformanceCtrl.volumeBasis === '$'
																? RMDollarDefs
																: RMUnitsDefs
														"
														[rowData]="objFact[selectedSort.value].remainingbottom10"
														[customStyle]="'height:240px'"
														[flex]="true"
													>
													</crf-ag-grid>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!--EOF Pannel-->
						</div>
						<!--EOF Right Pannel -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="col-xs-12 footnote-block">
	<crf-ca-dashboard-footnote [reportDimension]="productPerformanceCtrl">
	</crf-ca-dashboard-footnote>
</div>
