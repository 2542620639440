import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastService, ToastState } from '@nielseniq/athena-core';
import { Subscription } from 'rxjs';
import { TOAST } from 'src/app/constants/toast.constant';
import { ReportService } from 'src/app/service/report.service';
@Component({
	selector: 'app-dropdown-menu',
	templateUrl: './dropdown-menu.component.html',
	styleUrl: './dropdown-menu.component.scss'
})
export class DropdownMenuComponent {
	@Input() items: { key: string; value: string }[];
	@Input() selectorType: number;
	@Input() configId: number;
	@Input() questionId: number = -1;
	@Input() showMenu: boolean = true;
	@Input() showMenuIcon: boolean = true;
	@Input() disabledDefaultOptions: boolean;
	@Input() disableMenuOptions: boolean;

	@Output() selectedItem: EventEmitter<string> = new EventEmitter<string>();
	@Output() onDefaultChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() cancel: EventEmitter<any> = new EventEmitter<any>();

	selectedValue: any;
	constructor(private service: ReportService, private toastService: ToastService) {}

	onItemSelected(item: string) {
		this.selectedValue = item;
		this.selectedItem.emit(item);
	}

	onSelectedChange(item) {
		this.selectedValue = item;
	}

	apply() {
		this.selectedItem.emit(this.selectedValue);
	}

	getMenuResponse(data) {
		let subscription: Subscription = new Subscription(); // Initialize subscription with a default value

		if (this.questionId !== -1 && this.selectorType === 5) {
			if (data.menuId === 1 && this.selectedValue) {
				let payload = { periodIds: this.selectedValue.key };
				subscription = this.service
					.saveSelectors(this.questionId, 'periodIds', payload)
					?.subscribe({
						next: res => {
							this.toast('success', 'Success', 'Selections saved successfully');
						},
						error: err => {
							this.toast('error', 'Dropdown Menu Component', 'Get Menu Response: Invalid response');
							subscription.unsubscribe();
						},
						complete: () => subscription.unsubscribe()
					});
			} else if (data.menuId === 2) {
				subscription = this.service.getSelectors(this.questionId).subscribe({
					next: res => {
						if (res.responseData) {
							if (res.responseData.periodIds || res.responseData.periodIds !== '') {
								this.items.forEach(item => {
									if (item.key === res.responseData.periodIds) this.selectedValue = item;
								});
								this.onItemSelected(this.selectedValue);
							}
						} else
							this.toast(
								'warning',
								'Dropdown Menu Component',
								'Get Menu Response: Invalid response'
							);
					},
					error: err => {
						this.toast('error', 'Dropdown Menu Component', 'Get Menu Response: Invalid response');
						subscription.unsubscribe();
					},
					complete: () => subscription.unsubscribe()
				});
			}
		}

		this.onDefaultChange.emit(data);
	}

	toast(type: ToastState, title: string, message: string): void {
		this.toastService.InjectToast(type, message, '', TOAST.TIMEOUT, '', title, TOAST.SIZE);
	}
}
