import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgGridModule } from 'ag-grid-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NlsnGlobalsContextExtractModule } from './core/service/auth/nlsn-globals.module';
import { NlsnDataStoreModule } from './core/service/store/nlsn-data-store.module';
import { NlsnDataRequestInterceptorModule } from './core/interceptor/nlsn-data-request-interceptor/nlsn-data-request-interceptor.module';
import { LandingPageModule } from './landing-page/landing-page.module';
import { RouterModule } from '@angular/router';
// AoT requires an exported function for factories
import { routes } from './app.routes';
import { ComponentModule } from './shared/components/selector/component.module';
import { CommonModule, CurrencyPipe, DecimalPipe } from '@angular/common';
import {
	ButtonModule,
	CheckboxModule,
	DialogModule,
	IconModule,
	ItemModule,
	MenuModule,
	RadioButtonModule,
	TextFieldModule
} from '@nielseniq/athena-core';
import { TooltipModule } from '@nielseniq/athena-core';
import { ReportModule } from './reports/report.module';
import { TrainingPageModule } from './page/training-page/training-page.module';
import { PageModule } from './page/page.module';
import { TranslateModule } from '@ngx-translate/core';
import { LandingPageService } from './service/landing-page.service';
import { FormsModule } from '@angular/forms';
import { NlsnDataResponseInterceptorModule } from './core/interceptor/nlsn-data-response-interceptor/nlsn-data-response-interceptor.module';
import { NlsnDataResponseInterceptor } from './core/interceptor/nlsn-data-response-interceptor/nlsn-data-response-interceptor.service';
import { CoreModule } from './core/core.module';

@NgModule({
	declarations: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [
		RouterModule.forRoot(routes),
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		NlsnGlobalsContextExtractModule,
		NlsnDataStoreModule,
		NlsnDataResponseInterceptorModule,
		NlsnDataRequestInterceptorModule,
		CommonModule,
		IconModule,
		CheckboxModule,
		RadioButtonModule,
		ButtonModule,
		ComponentModule,
		ItemModule,
		MenuModule,
		TextFieldModule,
		DialogModule,
		TrainingPageModule,
		PageModule,
		AgGridModule,
		FormsModule,
		TooltipModule,
		TranslateModule.forRoot(),
		CoreModule,
		ReportModule
	],
	providers: [
		DecimalPipe,
		CurrencyPipe,
		LandingPageService,
		{ provide: HTTP_INTERCEPTORS, useClass: NlsnDataResponseInterceptor, multi: true }
	],
	exports: [ComponentModule, RouterModule],
	bootstrap: [AppComponent]
})
export class AppModule {}
