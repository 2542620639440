<div class="container card">
	<!--Close-->
	<div class="d-flex justify-content-end my-2">
		<niq-icon
			state="primary"
			size="small"
			(click)="onCloseDialog()"
			iconName="close"
			colorTheme="light"
		></niq-icon>
	</div>
	<!--Search-->
	<div class="selection-container">
		<niq-search-bar
			variant="expandable"
			size="medium"
			[onWhite]="true"
			placeholder="Choose a default product"
			(inputValueChange)="productSearch($event, false)"
			(searchChange)="productSearch($event, true)"
			(clear)="clearProductSearch()"
			class="searchbar"
		>
		</niq-search-bar>
		<ul class="typeahead selectors-suggestion" *ngIf="suggestions.length > 0 && isSuggestions">
			<li
				*ngFor="let suggestion of suggestions; track: by; $index"
				auto-suggest-menu-item
				(click)="suggetionChoosen(suggestion)"
			>
				<span class="search__suggestion"> {{ suggestion }} </span>
			</li>
		</ul>
		<div class="header-name d-flex justify-content-between p-3">
			<p class="mb-0">
				Product name
				<span> {{ matchText }} </span>
			</p>
			<p class="mb-0">Matches selected</p>
		</div>
		<div *ngIf="!isListAvailable">
			<div class="selectorspinner">
				<span class="spinner-large">
					<niq-spinner colorTheme="light" variant="brand" size="medium"></niq-spinner>
				</span>
			</div>
		</div>
		<!--Products-->
		<div class="product-selector-list">
			<div id="on-load-loader" class="overlay-bg" [hidden]="!tableLoader"></div>
			<div *ngIf="isListAvailable" class="selection-description">
				<div [hidden]="!tableLoader">
					<div class="selectorspinner">
						<span class="spinner-large">
							<niq-spinner colorTheme="light" variant="brand" size="medium"></niq-spinner>
						</span>
					</div>
				</div>

				<!-- Accordion Start -->
				<niq-accordion-group variant="single" size="medium" [expandedItem]="-1">
					<niq-accordion
						*ngFor="let item of searchData | preserveOrder; let tourIndex = index"
						class="accordion mb-2"
						size="medium"
						expandIcon="chevron-up"
						collapseIcon="chevron-down"
						[border]="'none'"
						[primaryText]="item.key | textChange: configId"
						[isExpanded]="activeAccordion === item.key"
						(toggle)="activeAccordion = item.key"
					>
						<div accordion-actions class="d-flex justify-content-between align-items-center">
							<span style="margin-left: -6px">:</span>
							<p>
								<niq-chip
									*ngIf="accordianView[item.key]"
									type="type"
									class="px-2 mx-2"
									active="active"
									size="small"
								>
									{{ accordianView[item.key]?.selectedOption }}
								</niq-chip>
								<niq-icon
									*ngIf="accordianView[item.key]?.selectedOption"
									class="reset-icon"
									state="primary"
									size="small"
									iconName="reset"
									(click)="selectItem(item.key, -1, tourIndex)"
								></niq-icon>
							</p>
							<p style="position: absolute; right: 50px">{{ item.value[1] }}</p>
						</div>
						<div accordion-content class="accordion-content-container p-3">
							<div *ngFor="let itemvalue of item.value[0]">
								<div
									class="radio-group"
									lastElemVisible
									(elementVisible)="loadMore($event, item.key)"
									[isTargetElement]="itemvalue === item.value[0][item.value[0].length - 1]"
								>
									<niq-radio-group size="small">
										<niq-radio-button
											(click)="selectItem(item.key, itemvalue, tourIndex)"
											[checked]="accordianView[item.key]?.selectedOption === itemvalue"
											[value]="itemvalue"
										>
											<p>{{ itemvalue }}</p>
										</niq-radio-button>
									</niq-radio-group>
									<span></span>
								</div>
							</div>
						</div>
					</niq-accordion>
				</niq-accordion-group>
			</div>
			<!--Footer-->
			<div class="button-container py-3 d-flex justify-content-between">
				<niq-button variant="ghost" size="small" class="mr-2" (click)="clearUserSelection()">
					Clear all
				</niq-button>
				<div>
					<niq-button
						*ngIf="!fromMainDashboard"
						[state]="!enableSaveButton && !fromMainDashboard ? 'disabled' : 'default'"
						variant="ghost"
						size="medium"
						class="mr-2"
						(click)="applyUserSelection(false)"
					>
						Apply
					</niq-button>
					<niq-button
						[state]="!enableSaveButton ? 'disabled' : 'default'"
						variant="primary"
						size="medium"
						class="mr-2"
						(click)="saveUserSelection()"
					>
						Apply and set as default
					</niq-button>
				</div>
			</div>
			<!--Error messages-->
			<div>
				<span
					class="imp-category"
					*ngIf="!enableSaveButton && productPerformanceMandatoryText != ''"
					><i class="fa fa-star" aria-hidden="true"></i
					><span> {{ productPerformanceMandatoryText }} </span></span
				>
				<span class="imp-category" *ngIf="!enableSaveButton && isonlyBrand"
					><i class="fa fa-star" aria-hidden="true"></i
					><span> {{ brandMandatoryTextForManufacturer }}</span></span
				>
				<span class="imp-category" *ngIf="!enableSaveButton && categoryMandatoryTextForCDT != ''"
					><i class="fa fa-star" aria-hidden="true"></i
					><span> {{ categoryMandatoryTextForCDT }}</span></span
				>
				<div class="clearfix spacer-10"></div>
			</div>
		</div>
	</div>
</div>
