import { Injectable } from '@angular/core';
import { PRODUCT_SELECTOR_MAPPING } from 'src/app/constants/report.constant';
import { ReportFooterItem } from 'src/app/models/report.model';
import { ExportUtilityService } from 'src/app/shared/service/report/export-utility.service';
import { SELECTOR } from 'src/app/shared/constants/selector.constant';

Injectable({
	providedIn: 'root'
});
export class ReportUtility extends ExportUtilityService {
	getFactMap(factData: any): Map<String, any[]> {
		let factDataMap: Map<String, any[]> = new Map<string, any[]>();

		factData.forEach(fact => {
			if (factDataMap.has(fact.gkey))
				if (fact.valuebase === 'Dollars') factDataMap.get(fact.gkey)[0] = fact;
				else if (fact.valuebase === 'Units') factDataMap.get(fact.gkey)[1] = fact;
				else factDataMap.get(fact.gkey)[2] = fact;
			else if (fact.valuebase === 'Dollars') factDataMap.set(fact.gkey, [fact, null, null]);
			else if (fact.valuebase === 'Units') factDataMap.set(fact.gkey, [null, fact, null]);
			else factDataMap.set(fact.gkey, [null, null, fact]);
		});

		return factDataMap;
	}

	getFactColumnDefinition(definitions: any, values: any): any[] {
		let factMap: Map<String, any[]> = this.getFactMap(definitions);

		let colDefinition: any[] = [];

		if (factMap.get('Products')) colDefinition.push(factMap.get('Products')[2]);
		if (factMap.get('Product Description'))
			colDefinition.push(factMap.get('Product Description')[2]);

		values.forEach(fact => {
			if (fact.isCheckedDollar) colDefinition.push(factMap.get(fact.value)[0]);
			if (fact.isCheckedUnit) colDefinition.push(factMap.get(fact.value)[1]);
			if (fact.isCheckedOther) colDefinition.push(factMap.get(fact.value)[2]);
		});

		return colDefinition;
	}

	getFooterItems(headerValues: any): ReportFooterItem[] {
		let footerItems: ReportFooterItem[] = [];

		Object.keys(headerValues)?.forEach(selectorKey => {
			switch (selectorKey) {
				case SELECTOR.PRODUCT.ID:
					//Creating a map for getting display name
					let productMap = new Map<string, string>();
					Object.keys(PRODUCT_SELECTOR_MAPPING).forEach(productMapKey => {
						productMap.set(
							PRODUCT_SELECTOR_MAPPING[productMapKey].PAYLOAD_KEY,
							PRODUCT_SELECTOR_MAPPING[productMapKey].NAME
						);
					});

					let productItems: ReportFooterItem[] = [];
					Object.keys(headerValues[selectorKey]).forEach(productKey => {
						if (
							headerValues[selectorKey][productKey] &&
							headerValues[selectorKey][productKey].length > 0
						)
							productItems.push({
								filter: productMap.get(productKey),
								value: headerValues[selectorKey][productKey].join(';')
							});
					});

					footerItems.push({ filter: 'Product', value: productItems });
					break;
				case SELECTOR.FACT.ID:
					if (headerValues.fact.factType.length === 1)
						footerItems.push({
							filter: 'Volume Basis',
							value: headerValues.fact.factType[0] == 'DOLLAR' ? '$' : 'Units'
						});
					break;
				default:
					if (selectorKey !== 'periodenddate') {
						let values = [];

						headerValues[selectorKey].forEach(item => {
							values.push(item.value);
						});

						if (values.length > 0)
							footerItems.push({
								filter: SELECTOR[selectorKey.toUpperCase()].DISPLAY_NAME,
								value: values.join('; ')
							});
					}
			}
		});

		return footerItems;
	}
}
