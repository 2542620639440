import { Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpInterceptor,
	HttpHandler,
	HttpRequest
} from '@angular/common/http';
import { NlsnGlobalsContextExtract } from '../../../core/service/auth/nlsn-globals.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { REDIRECTING_ANSWERS_PORTAL_URL_WHEN_LOGOUT } from '../../../../environments/env.config.constants';

@Injectable()
export class NlsnDataResponseInterceptor implements HttpInterceptor {
	constructor(private nlsnGlobalsContextExtract: NlsnGlobalsContextExtract) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError(error => {
				if (error instanceof HttpErrorResponse) {
					if (error.status === 401 || error.status === 403) {
						this.handleAuthError(error);
						return of(null);
					}
				}
				return throwError(() => error);
			})
		);
	}

	private handleAuthError(err: any): void {
		const timestamp = new Date().getTime();
		window.location.href =
			REDIRECTING_ANSWERS_PORTAL_URL_WHEN_LOGOUT[
				this.nlsnGlobalsContextExtract.extractEnvironment()
			];
	}
}
