<div class="container-fluid">
	<!-- Loader -->
	<div class="overlay-bg-ppt" *ngIf="enableLoader">
		<span class="spinner-large">
			<em class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></em>
		</span>
	</div>
	<!-- Loader End -->
	<ag-grid-angular
		style="width: 100%; height: 400px"
		[columnDefs]="colDefs"
		[defaultColDef]="defaultColDef"
		[autoGroupColumnDef]="autoGroupColumnDef"
		[rowData]="rowData"
		[icons]="icons"
		[treeData]="true"
		[groupDefaultExpanded]="groupDefaultExpanded"
		[getDataPath]="getDataPath"
		[class]="themeClass"
		[sort]="true"
		(gridReady)="onGridReady($event)"
		(sortChanged)="printSortColoumn($event)"
	></ag-grid-angular>
</div>
