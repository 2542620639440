import { Component } from '@angular/core';
import { ReportFooterContent } from 'src/app/models/report.model';

@Component({
	selector: 'crf-ca-report-footer',
	templateUrl: './footer.component.html',
	styleUrl: './footer.component.scss'
})
export class FooterComponent {
	footerContent: ReportFooterContent = { dashboard: null, filters: [], note: null };

	isString(value: any): boolean {
		if (typeof value === 'string') return true;
		return false;
	}

	setFooterContent(footerContent: ReportFooterContent) {
		this.footerContent = footerContent;
	}
}
