import { AfterViewInit, Component, OnDestroy, ViewChild } from '@angular/core';
import { ConsumerDecisionTreeService } from 'src/app/service/consumer-decision-tree.service';
import { ConsumerDecisionTreeUtility } from 'src/app/utility/report/consumer-decision-tree.utility';
import { ConsumerDecisionTreeUpcTableComponent } from './consumer-decision-tree-upc-table/consumer-decision-tree-upc-table.component';
import { ReportFooterContent, ReportFooterItem } from 'src/app/models/report.model';
import { SELECTOR } from 'src/app/shared/constants/selector.constant';
import { SelectorConfig } from 'src/app/shared/model/report/report-header.type';
import { FACT_SPECIFICATION } from 'src/app/shared/constants/fact-sepcification/consumer-decision-tree.constant';
import { FooterComponent } from 'src/app/shared/components/report/footer/footer.component';
import { SliderComponent, ToastService } from '@nielseniq/athena-core';
import { TOAST } from 'src/app/constants/toast.constant';
import { ReplaySubject, takeUntil } from 'rxjs';
import { selectorConfigurations } from 'src/app/shared/constants/header-configuration/consumer-decision-tree';
import { HeaderComponent } from 'src/app/shared/components/report/header/header.component';
import { UtilityService } from 'src/app/reports/services/utility.service';
import { ProductLevel } from 'src/app/models/report-dimension.model';
import { ReportService } from 'src/app/service/report.service';

@Component({
	selector: 'crf-ca-consumer-decision-tree-page',
	templateUrl: './consumer-decision-tree-page.component.html',
	styleUrl: './consumer-decision-tree-page.component.scss'
})
export class ConsumerDecisionTreePageComponent implements AfterViewInit {
	questionId: number = 14;
	headerComponentSelectorConfiguration: SelectorConfig[] = selectorConfigurations;

	@ViewChild(HeaderComponent) headerComponent: HeaderComponent;
	@ViewChild(FooterComponent) footerComponent: FooterComponent;

	@ViewChild(SliderComponent) sliderComponent: SliderComponent;

	//Binding Column Definition and data after the initialization of the component
	@ViewChild(ConsumerDecisionTreeUpcTableComponent)
	upcTableComponent: ConsumerDecisionTreeUpcTableComponent;

	values: any = {
		//Product Selector
		category: '',

		//Node values
		char0: '',
		lvl0: '',
		char1: '',
		lvl1: '',
		char2: '',
		lvl2: '',
		char3: '',
		lvl3: '',

		//Period Selector
		cyPeriod: '',
		yaPeriod: '',

		//Market Selector
		wmMarket: '',
		rmMarket: ''
	};

	tree = {
		data: { treenode: '', childs: [] },
		zoom: 100,
		expanded: false,
		loaded: false,

		table: {
			data: { rankings: [] },
			columnWidth: (base, corr) => ((corr / base) * 100).toFixed(1).replace('.0', '')
		}
	};

	displayExpandedUpcTableView: boolean = false;
	periodEnddate: string;
	selectedProducts: any;
	unsubscribeOnDestroy$: ReplaySubject<any> = new ReplaySubject<any>();

	constructor(
		private service: ConsumerDecisionTreeService,
		private utility: ConsumerDecisionTreeUtility,
		private toastService: ToastService,
		private exportService: UtilityService,
		private reportService: ReportService
	) {}

	ngAfterViewInit(): void {
		if (this.headerComponent) {
			this.headerComponent.loadProgress$.pipe(takeUntil(this.unsubscribeOnDestroy$)).subscribe({
				error: err => {
					this.toast('error', 'Consumer Decision Tree', 'Ng After View Init: Failed');
				},
				complete: () => {
					this.headerComponent.disableFilter(SELECTOR.FACT.ID);
					//Load the report
					this.loadReport();

					//Load footer section
					this.loadFooter();
				}
			});
		}

		//Event listening to the expanded table on node in the tree structure
		this.utility.expandTableView$.pipe(takeUntil(this.unsubscribeOnDestroy$)).subscribe({
			next: res => this.loadUpcTable(res),
			error: err => {
				this.toast('error', 'Consumer Decision Tree', 'Ng After View Init: Invalid Response');
			}
		});
	}

	loadReport(): void {
		//Clearing data
		this.clearData();

		let headerCategory = this.headerComponent?.getSelectorValues().product.category;
		this.values.category = headerCategory?.length > 0 ? headerCategory[0] : ''; //Need to change

		//Load tree structure
		this.tree.loaded = false;

		this.service
			.getTreeData({ category: this.values.category })
			.pipe(takeUntil(this.unsubscribeOnDestroy$))
			.subscribe({
				next: (res: any) => {
					if (res.responseData) this.tree.data = res.responseData;
					else this.toast('warning', 'Consumer Decision Tree', 'Load Report: Invalid Response');
				},
				error: (err: any) => {
					this.tree.loaded = true;
					this.toast('error', 'Consumer Decision Tree', 'Load Report: Invalid Response');
				},
				complete: () => {
					this.tree.loaded = true;
				}
			});

		this.service
			.getTreeTableData({ category: this.values.category })
			.pipe(takeUntil(this.unsubscribeOnDestroy$))
			.subscribe({
				//Update category based on the product select
				next: (res: any) => {
					if (res.responseData)
						this.tree.table.data.rankings = res.responseData[this.values.category].splice(0, 10);
					else this.toast('warning', 'Consumer Decision Tree', 'Load Report: Invalid Response');
				},
				error: (err: any) => {
					this.toast('error', 'Consumer Decision Tree', 'Load Report: Invalid Response');
				}
			});

		//Load footer
		this.loadFooter();
	}

	loadUpcTable(nodeValue: any): void {
		//Enabling Fact Selector and Disabling Product Selector
		this.headerComponent.disableFilter(SELECTOR.PRODUCT.ID);
		this.headerComponent.enableFilter(SELECTOR.FACT.ID);

		//Clearing existing values
		Object.keys(this.values).forEach(key => {
			this.values[key] = '';
		});

		//Binding category value
		let headerCategory = this.headerComponent.getSelectorValues().product.category;
		this.values.category = headerCategory.length > 0 ? headerCategory[0] : '';

		//Binding values from the node
		Object.keys(nodeValue).forEach(key => {
			this.values[key] = nodeValue[key] ? nodeValue[key] : '';
		});

		//Loading the report
		let factDefinition = this.utility.refactorFactDefintion(
			this.utility.getFactColumnDefinition(
				FACT_SPECIFICATION,
				this.headerComponent.getSelectorValues().fact.facts
			)
		);

		//Binding values of the market and period
		this.service
			.getPeriod()
			.pipe(takeUntil(this.unsubscribeOnDestroy$))
			.subscribe({
				next: res => {
					if (res.responseData) {
						this.values.cyPeriod = res.responseData['Latest 52 Weeks'].currentPeriod;
						this.values.yaPeriod = res.responseData['Latest 52 Weeks'].previousPeriod;

						let subscription2 = this.service.getMarket().subscribe({
							next: res => {
								this.values.wmMarket = res.responseData['Total US'].walmartMarket;
								this.values.rmMarket = res.responseData['Total US'].remainingMarket;

								this.upcTableComponent.loadUpcTable(factDefinition, this.values);
							},
							error: err => {
								this.toast('error', 'Consumer Decision Tree', 'Load UPC Table: Invalid Response');
								subscription2.unsubscribe();
							},
							complete: () => subscription2.unsubscribe()
						});
					}
				},
				error: err => {
					this.toast('error', 'Consumer Decision Tree', 'Load UPC Table: Invalid Response');
				}
			});

		this.upcTableComponent.clearData();

		//Displaying the UPC Table
		this.displayExpandedUpcTableView = true;
	}

	displayTreeView() {
		this.displayExpandedUpcTableView = false;

		//Enabling Fact Selector and Disabling Product Selector
		this.headerComponent?.disableFilter(SELECTOR.FACT.ID);
		this.headerComponent?.enableFilter(SELECTOR.PRODUCT.ID);
	}

	setSelectedProduct(selectedProduct): ProductLevel[] {
		return this.reportService.setSelectedProduct(selectedProduct, false);
	}

	loadFooter(): void {
		let selectorValues = this.headerComponent?.getSelectorValues();
		let reportFooterItems: ReportFooterItem[] = this.utility?.getFooterItems(selectorValues);

		this.periodEnddate = selectorValues.periodenddate;
		this.selectedProducts = reportFooterItems.filter(item => item.filter === 'Product')[0].value;

		reportFooterItems.push({ filter: 'Market', value: 'Total US' });

		let reportFilterContent: ReportFooterContent = {
			dashboard: 'Consumer Decision Tree',
			filters: reportFooterItems,
			note: ''
		};

		//Removing value basis if exists
		reportFooterItems.forEach((item, index) => {
			if (item.filter === 'Volume Basis') reportFooterItems.splice(index, 1);
		});

		reportFooterItems.push({ filter: 'By', value: 'Subcategory' });
		reportFooterItems.push({
			filter: 'Period End Date',
			value: 'Latest 52 Weeks Ending ' + selectorValues.periodenddate.join(';') + ' vs YA'
		});

		this.footerComponent.footerContent = reportFilterContent;
	}

	toggleTreeExpand() {
		this.utility.expandTree$.next(this.tree.expanded);
	}

	headerSelectorValueChangeHandler(event) {
		if (event === SELECTOR.FACT.ID)
			this.upcTableComponent.updateColumnDefinition(
				this.utility.refactorFactDefintion(
					this.utility.getFactColumnDefinition(
						FACT_SPECIFICATION,
						this.headerComponent.getSelectorValues().fact.facts
					)
				)
			);
		else this.loadReport();
	}

	exportReport(event: any) {
		let markets = this.headerComponent.getDropdownItems('market');
		let periods = this.headerComponent.getDropdownItems('period');

		let payload = this.utility.getConsumerDecisionTreePayload(
			this.headerComponent.getSelectorValues(),
			periods.find(period => period.key == 'Latest 52 Weeks'),
			markets.find(market => market.key == 'Total US'),
			this.displayExpandedUpcTableView,
			this.values
		);

		this.exportService.exportFile(payload, 'excel');
	}

	zoom(event) {
		this.tree.zoom = this.sliderComponent.value;
	}

	clearData(): void {
		this.tree.table.data.rankings = [];
		this.tree.data.childs = [];
		this.tree.data.treenode = '';
	}

	toast(type: any, title: string, message: string): void {
		this.toastService.InjectToast(type, message, '', TOAST.TIMEOUT, '', title, TOAST.SIZE);
	}

	ngOnDestroy(): void {
		this.unsubscribeOnDestroy$.next(true);
		this.unsubscribeOnDestroy$.complete();
	}
}
