import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of, retry, switchMap } from 'rxjs';
import { KEY } from 'src/app/constants/data-constants';
import { USER_SESSION_DETAILS } from 'src/app/constants/ui-constants';
import { NlsnDataStoreService } from 'src/app/core/service/store/nlsn-data-store.service';
import { NlsnGlobalsContextExtract } from 'src/app/core/service/auth/nlsn-globals.service';
import { BASE_URL, CONCIERGE_URL } from 'src/environments/env.config.constants';

@Injectable({
	providedIn: 'root'
})
export class AzureUtilityService {
	headers: { headers: any };

	constructor(
		public http: HttpClient,
		private nlsnDataStoreService: NlsnDataStoreService,
		public nlsnGlobalsContextExtract: NlsnGlobalsContextExtract
	) {
		this.headers = {
			headers: new HttpHeaders().append(
				'X-NIQ-ACCESS-TOKEN',
				this.nlsnDataStoreService.getItem(USER_SESSION_DETAILS.ACCESS_TOKEN)
			)
		};
	}

	getSASTokensWithFileName(name) {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			CONCIERGE_URL.SAS_TRAINING_MATERIAL;
		/*******Get  sasToken  and azureStorageURL ******/
		return this.http.post<any>(pingURL, {}, this.headers).pipe(
			map(res => {
				let dataSas = res.responseData;
				return dataSas.azureStorageURL + name + dataSas.sasToken;
			}),
			catchError((err: HttpErrorResponse): any => of(err))
		);
	}

	deleteTrainingMaterialFile(url) {
		/*******Delete file in azure blob storage******/
		let azureblobUrl = url;
		return this.http.delete(azureblobUrl, { headers: {} }).pipe(
			catchError((err: HttpErrorResponse): any => {
				// Handle error:
				return of(err); // Return an observable of the error
			})
		);
	}

	uploadNewTrainingMaterialFile(file, name, fileAsBuffer) {
		const pingURL =
			BASE_URL[this.nlsnGlobalsContextExtract.extractEnvironment()] +
			CONCIERGE_URL.SAS_TRAINING_MATERIAL;
		return this.http.post<any>(pingURL, {}, this.headers).pipe(
			retry(2),
			switchMap((data): any => {
				const dataSas = data.responseData;
				// PUT file in azure blob storage
				const azureblobUrl = dataSas.azureStorageURL + name + dataSas.sasToken;
				const azureReqHeader: any = {
					'x-ms-blob-type': 'BlockBlob'
				}; // EOF getHeaders;
				azureReqHeader['x-ms-blob-content-type'] = file.type;
				azureReqHeader['Content-Type'] = file.type;
				return this.http.put(azureblobUrl, fileAsBuffer, { headers: azureReqHeader }).pipe(
					catchError((err: HttpErrorResponse): any => {
						// Handle error here, for example:
						return of(err); // Return an observable of the error
					})
				);
			}),
			catchError((err: HttpErrorResponse): any => of(err))
		);
	}
}
