<div
	id="banner-carousel"
	class="carousel banner-carousel carousel-fade"
	data-ride="carousel"
	*ngIf="isShowBanner"
>
	<div class="banner-mark-viewed">
		<niq-icon
			state="primary"
			size="small"
			iconName="close"
			(click)="onBannerCloseButton()"
			[colorTheme]="'dark'"
		></niq-icon>
	</div>

	<!-- Indicators -->
	<ol class="carousel-indicators" *ngIf="bannerResults.length > 1">
		<li
			*ngFor="let bannerResult of bannerResults; let i = index"
			data-target="#banner-carousel"
			[ngClass]="{ active: i === currentBanner }"
			data-slide-to="2"
		></li>
	</ol>

	<!-- Wrapper for slides -->
	<div class="banner-carousel-inner carousel-inner" role="listbox">
		<div
			class="item banner-item"
			[ngClass]="{ active: i === currentBanner }"
			*ngFor="let bannerResult of bannerResults; let i = index"
		>
			<div
				(click)="onBannerClick(bannerResult)"
				[ngStyle]="{ 'background-image': 'url(' + bannerResult.uiThemeUrl + ')' }"
				class="mySlides"
			>
				<div class="banner-msg-block">
					<div
						class="text-content-block"
						[innerHTML]="sanitizer.bypassSecurityTrustHtml(bannerResult.message)"
					></div>
				</div>
			</div>
		</div>
	</div>

	<!-- Controls -->
	<a
		*ngIf="bannerResults.length > 1"
		(click)="changeBanner('prev')"
		class="left carousel-control"
		role="button"
		data-slide="prev"
	>
		<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
		<span class="sr-only">Previous</span>
	</a>
	<a
		*ngIf="bannerResults.length > 1"
		class="right carousel-control"
		role="button"
		data-slide="next"
		(click)="changeBanner('next')"
	>
		<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
		<span class="sr-only">Next</span>
	</a>
</div>
<!-- Document Section -->
<div class="doc-overlay" *ngIf="showDoc">
	<div class="doc-preview py-4 px-5 m-4 d-flex">
		<div class="navigate" *ngIf="currentDocument > 0 && this.docResults.length > 1">
			<niq-icon
				state="primary"
				size="large"
				iconName="chevron-left"
				colorTheme="dark"
				(click)="onChangeDocument('prev')"
			>
			</niq-icon>
		</div>

		<div class="document-container release-notes-popup" (onmouseover)="$event.stopPropagation()">
			<!-- PDF Document -->
			<div
				*ngIf="
					this.docResults[currentDocument].materialType === 'PDF' &&
					this.docResults[currentDocument].enhancementSummaryFileUrl
				"
			>
				<iframe
					[src]="
						sanitizer.bypassSecurityTrustResourceUrl(
							this.docResults[currentDocument].enhancementSummaryFileUrl
						)
					"
					class="pdfviewer"
					(onmouseover)="$event.stopPropagation()"
					width="100%"
					height="480"
				></iframe>
			</div>
			<!-- Image Document -->
			<div
				*ngIf="
					this.docResults[currentDocument].materialType === 'Image' &&
					this.docResults[currentDocument].enhancementSummaryFileUrl
				"
				class="image-popup-content"
				style="width: 500px; height: 500px"
			>
				<img
					[src]="
						sanitizer.bypassSecurityTrustResourceUrl(
							this.docResults[currentDocument].enhancementSummaryFileUrl
						)
					"
					alt="Image Document"
				/>
			</div>
			<!-- Video Document -->
			<div
				*ngIf="
					this.docResults[currentDocument].materialType === 'Video' &&
					this.docResults[currentDocument].enhancementSummaryFileUrl
				"
			>
				<div style="height: 100%; width: 100%">
					<video autoplay class="videoFrame" type="video/mp4" controls>
						<source [src]="this.docResults[currentDocument].enhancementSummaryFileUrl" />
					</video>
				</div>
			</div>
		</div>
		<div class="justify-content-end">
			<niq-icon
				state="primary"
				size="medium"
				iconName="x"
				colorTheme="dark"
				*ngIf="currentDocument === this.docResults.length - 1"
				(click)="onCloseDocumentSection()"
			>
			</niq-icon>
		</div>
		<!-- Controls -->
		<div
			class="navigate"
			*ngIf="docResults.length > 1 && currentDocument !== docResults.length - 1"
		>
			<niq-icon
				state="primary"
				size="large"
				iconName="chevron-right"
				colorTheme="dark"
				(click)="onChangeDocument('next')"
			>
			</niq-icon>
		</div>
	</div>
</div>
<niq-dialog #vidBannerModal [size]="'large'" [closeOnClickOutside]="false">
	<div dialogBody class="text-center modal-confirmation-main-text">
		<video autoplay class="videoFrame" controls>
			<source src="{{ vidBannerLink }}" type="video/mp4" />
		</video>
	</div>
	<div dialogFooter>
		<niq-button (click)="vidBannerModal.close()" size="medium" variant="danger">Close</niq-button>
	</div>
</niq-dialog>

<div class="page-container">
	<div class="main">
		<!-- <div class="row"> -->
		<!-- <div class="col-lg-9 pr-0"> -->
		<crf-ca-quick-link-card></crf-ca-quick-link-card>
		<!-- </div> -->
		<!-- <div class="col-lg-3">
				<div class="row">
					<div class="col-12">
						<h4 class="ml-3 py-3">Applications</h4>
						<crf-ca-app-section [hover]="false"></crf-ca-app-section>
					</div>
				</div>
			</div> -->
		<!-- </div> -->
	</div>
</div>
