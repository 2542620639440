import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import '@nielsen/nd-ui-components/src/elements.js';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'Welcome WALMART OVT';

	constructor() {}
	ngOnInit(): void {}
}
