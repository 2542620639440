import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductSearchService } from 'src/app/service/product-search.service';
import { REQ_OBJ, optedSelection, SELECT_PRODUCT } from 'src/app/constants/ui-constants';
import { UtilityService } from 'src/app/reports/services/utility.service';
import * as _ from 'lodash';

@Component({
	selector: 'crf-ca-multilayer-dropdown',
	templateUrl: './multilayer-dropdown.component.html',
	styleUrl: './multilayer-dropdown.component.scss'
})
export class MultilayerDropdownComponent implements OnInit {
	@Input() selectorType: number;
	@Output() onClose = new EventEmitter();
	@Output() onProductApply = new EventEmitter<any>();
	// It will give direct request payload no need to add additional logic in parent component
	@Output() onApply = new EventEmitter<any>();

	keyToSearch: string = '';
	activeAccordion: string = '';
	allDatas: any;
	ele: any;
	//isenterkey = true;
	suggestions: any;
	issuggestions = true;
	suggesstionSearch = false;
	show = {};
	selectedValue: any = [];
	selectedCheckedValue: any = [];
	selectedDes: any;
	ind: any;
	valueArray: any;
	selectedDataTabs: any;
	clickedValue = [];
	setTable: { key: any; value: any; selectedValue: any };
	disabledButton = true;
	setDefault: any;
	checkedArray: any;
	values: string;
	category: any;
	enableLoader: boolean = false;
	errorMessage: any;
	reqObj: any = REQ_OBJ;
	enableReset: { [key: string]: boolean } = {};
	isSelected = false;
	selectedCount: number = 0;
	allData: any;
	matchText: string = '';

	sortedResponseTotal = {
		TOTAL: {},
		MAJOR: {},
		DEPT: {},
		CATGRP: {},
		CAT: {},
		SUBCAT: {},
		SEG: {},
		BRDOWNHGH: {},
		BRDOWNLOW: {},
		BRDHGH: {},
		BRDFAMILY: {},
		BRDLOW: {},
		BRAND: {}
	};

	sortedResponseTemp = this.sortedResponseTotal;
	sortedResponseMulti = {
		TOTAL: [],
		MAJOR: [],
		DEPT: [],
		CATGRP: [],
		CAT: [],
		SUBCAT: [],
		SEG: [],
		BRDOWNHGH: [],
		BRDOWNLOW: [],
		BRDHGH: [],
		BRDFAMILY: [],
		BRDLOW: [],
		BRAND: []
	};
	searchData: any;
	selected: any;
	requestPayload;

	constructor(
		private utilityService: UtilityService,
		private productSearchService: ProductSearchService
	) {}

	ngOnInit() {
		this.searchProduct(false, '-1');
		/** End */
		this.allDatas = [];
		this.selectedDes = [];
		this.checkedArray = [];
	}

	clearProductSearch() {
		this.suggestions = [];
		this.suggesstionSearch = false;
		this.keyToSearch = '';
		this.searchProduct(false, '-1');
	}

	productSearch(searchString: string, isEnterKey: boolean) {
		this.keyToSearch = searchString;
		if (isEnterKey) {
			this.suggestions = [];
			this.issuggestions = false;
			this.searchProduct(false, '-1');
		} else {
			this.sendSuggestionReq(searchString);
		}
	}

	//when the user opted any one option among the list
	suggetionChoosen(item: string) {
		this.keyToSearch = item;
		this.suggesstionSearch = true;
		this.issuggestions = false;
		this.searchProduct(false, '-1');
	}

	sendSuggestionReq(searchString) {
		// Will not send search untill user has entered 2 characters
		if (searchString.length < 3) {
			this.suggestions = [];
			return;
		}
		if (searchString !== '') {
			this.productSearchService.getSuggestionSearch(this.selectorType, searchString).subscribe({
				next: (response: any) => {
					this.suggestions = this.utilityService.isValid(response.product) ? response.product : [];
					this.issuggestions = true;
				},
				error: error => {
					this.suggestions = [];
					this.issuggestions = false;
				}
			});
		}
	}

	closeDialog() {
		this.onClose.emit(true);
	}

	onSelectedChange(newRadioValue: string, ind) {
		this.enableLoader = true;
		this.enableReset[this.allDatas[ind].key] = true;
		this.disabledButton = false;

		this.selectedDes = [newRadioValue];
		this.ind = ind;
		this.selectedDataTabs = [];

		this.allDatas.map((element: any, index: number) => {
			element.value.forEach((d: any) => {
				if (d.name === this.selectedDes[0]) {
					this.selectedCheckedValue.push({
						key: this.allDatas[ind].key,
						value: newRadioValue,
						isChecked: true
					});
					d.isChecked = true;
				} else {
					this.selectedCheckedValue = this.selectedCheckedValue.filter(
						item => item.value !== d.name
					);
					d.isChecked = false;
				}
			});

			if (index === ind) {
				this.clickedValue = this.selectedDes;
			} else {
				this.clickedValue = element.selectedValue;
			}
			this.sortedResponseMulti[element.key] = this.clickedValue;
			this.setTable = {
				key: element.key,
				value: element.value,
				selectedValue: this.sortedResponseMulti[element.key]
			};
			const lastChangeIndex = this.allDatas.findIndex(b => b.key === element.key);

			if (lastChangeIndex !== -1) this.allDatas[lastChangeIndex] = this.setTable;
		});

		this.searchProduct(this.allDatas[ind].key, this.allDatas[ind].selectedValue[0]);
	}

	saveDefault() {
		this.activeAccordion = '';
		const uniqueProductSelection = this.getUniqueValues(this.selectedCheckedValue);
		this.setDefault = JSON.parse(JSON.stringify(this.allDatas));
		let defaultOptValue: any = [];
		defaultOptValue = SELECT_PRODUCT;
		//Make querystring for req obj
		let optedValuesSave =
			'?' +
			Object.keys(defaultOptValue)
				.map(key => key.toLowerCase() + '=' + this.utilityService.isValidForMulti(this.reqObj[key]))
				.join('&');

		this.productSearchService.saveUserSelection(this.selectorType, optedValuesSave).subscribe({
			next: response => {
				this.onApply.emit(this.requestPayload);
			},
			error: err => {
				this.errorMessage = err;
				this.enableLoader = false;
			}
		});
		this.onProductApply.emit(uniqueProductSelection);
		this.disabledButton = true;
		this.onClose.emit(true);
	}
	clearSelection() {
		this.keyToSearch = '';
		this.activeAccordion = '';
		this.sortedResponseMulti = {
			TOTAL: [],
			MAJOR: [],
			DEPT: [],
			CATGRP: [],
			CAT: [],
			SUBCAT: [],
			SEG: [],
			BRDOWNHGH: [],
			BRDOWNLOW: [],
			BRDHGH: [],
			BRDFAMILY: [],
			BRDLOW: [],
			BRAND: []
		};
		this.disabledButton = true;
		this.selectedCheckedValue = [];
		this.selectedDes = [];
		this.reqObj = [];
		this.searchProduct('reset', '-1');
		//this.ngOnInit();
	}
	checkedChanges(checkBoolean, category, value, i) {
		this.enableLoader = true;
		this.enableReset[category] = true;
		this.disabledButton = false;

		this.allDatas.map((element: any, index: number) => {
			if (element.key.localeCompare(category) === 0) {
				element.value.forEach((d: any) => {
					if (d.name === value.name && checkBoolean) {
						d.isChecked = true;
						// this.checkedArray.push(d.name);
						const newArr = element.value.filter(sub => sub.isChecked === true);
						newArr.forEach(element => {
							this.checkedArray.push(element.name);
							this.selectedCheckedValue.push({ key: category, value: d.name });
						});
						this.clickedValue = this.checkedArray;
						this.selectedCount++;

						this.searchProduct(this.allDatas[i].key, this.allDatas[i].selectedValue[0]);
						this.sortedResponseMulti[category].push(d.name);
					} else if (d.name === value.name && checkBoolean === false) {
						this.selectedCheckedValue = this.selectedCheckedValue.filter(
							item => item.value !== d.name
						);
						this.sortedResponseMulti[category] = this.sortedResponseMulti[category].filter(
							item => item !== d.name
						);
						d.isChecked = false;
						this.selectedCount--;
						this.searchProduct(this.allDatas[i].key, this.allDatas[i].selectedValue[0]);
						this.checkedArray = this.checkedArray.filter(item => item !== d.name);
					}
					if (index !== i) {
						this.clickedValue = element.selectedValue;
					}
				});
			}

			let setTab = {
				key: element.key,
				value: element.value,
				selectedValue: this.sortedResponseMulti[element.key]
			};
			const lastChangeIndex = this.allDatas.findIndex(b => b.key === element.key);
			if (lastChangeIndex !== -1) this.allDatas[lastChangeIndex] = setTab;
		});
	}

	applyUserSelection() {
		this.activeAccordion = '';
		const uniqueProductSelection = this.getUniqueValues(this.selectedCheckedValue);
		this.onProductApply.emit(uniqueProductSelection);
		this.onApply.emit(this.requestPayload);
		this.onClose.emit(true);
	}

	resetProduct(isSelected) {
		this.disabledButton = false;
		this.enableReset[isSelected] = true;

		this.selectedCheckedValue = this.selectedCheckedValue.filter(item => item.key !== isSelected);
		this.sortedResponseMulti[isSelected] = [];
		if (isSelected === 'TOTAL') this.selectedDes = [];

		if (this.selectedCheckedValue.length > 0) {
			this.searchProduct(false, '1');
		} else {
			// this.disabledButton = false;
			this.searchProduct(false, '-1');
		}
	}

	/* FUNCTION: SEARCH STRING
	 *@hierarchyType values are DEPT,CATGRP,CAT,SUBCAT,SEG,BRDOWNHGH,BRDHGH, ALL(to get the data of all hierarchies)
	 *@searchString will be DISPLAYALL, if user didn't entered any SearchString
	 */
	searchProduct(isSelected, selectedValue) {
		this.enableLoader = true;
		this.suggestions = [];
		//Set req body
		let reqBody = {
			searchType: isSelected,
			isMulti: true,
			dashboardId: this.selectorType,
			hierrachyType: 'ALL',
			selectedVal: this.getSearchString(selectedValue)
		};
		this.requestPayload = _.omit(reqBody.selectedVal, ['exactMatch', 'searchString']);

		this.productSearchService.getSearchList(reqBody).subscribe(response => {
			this.allDatas = [];
			let set: any;
			const loadbyCount = 1000;

			this.allData = response;
			for (const [key] of Object.entries(this.allData)) {
				if (!_.isUndefined(this.allData[key])) {
					//this.sortedResponseTotal[key] = _.compact(value);
					this.sortedResponseTemp[key] = [
						_.take(this.allData[key], loadbyCount),
						this.allData[key].length
					];
				}
			}
			this.sortedResponseTotal = _.omitBy(this.sortedResponseTotal, _.isEmpty);
			this.searchData = _.omitBy(this.sortedResponseTemp, _.isEmpty);
			Object.entries(this.searchData).map((element: any) => {
				this.ele = element;
				this.valueArray = [];
				this.ele[1][0].map((d: any) => {
					let val = { name: d, isChecked: false };
					if (this.sortedResponseMulti[this.ele[0]].some(e => e === d)) {
						val = { name: d, isChecked: true };
					}
					this.valueArray.push(val);
				});

				set = {
					key: this.ele[0],
					value: this.valueArray,
					selectedValue: this.sortedResponseMulti[this.ele[0]]
				};

				this.allDatas.push(set);
			});

			this.matchText = this.utilityService.isValid(this.keyToSearch)
				? "Matches Found  : '" + this.keyToSearch + "'"
				: '';
			//this.keyToSearch = '';
			this.enableLoader = false;
		});
	}

	/*Get unique valu from array as combination of key and value */
	getUniqueValues(data) {
		const returned_array = [];
		data.map(element => {
			if (!returned_array.find(e => e?.key === element?.key && e?.value === element.value))
				returned_array.push(element);
		});
		return returned_array;
	}

	getSearchString(isSelected) {
		//Get unique selected value
		const uniqueProductSelection = this.getUniqueValues(this.selectedCheckedValue);

		let keywordToSearch = this.utilityService.isValid(this.keyToSearch)
			? this.keyToSearch
			: 'DISPLAYALL'; // (if search string is empty then send DISPLAYALL)
		let exactMatchValue = 'false';
		let optedValues = {};
		let extOptedValues = this.reqObj;

		if (this.utilityService.isValid(this.keyToSearch) && this.suggesstionSearch) {
			exactMatchValue = 'true';
		}
		optedValues = {
			searchString: keywordToSearch,
			exactMatch: exactMatchValue
		};

		let opt = optedSelection;

		if (isSelected !== '-1' || uniqueProductSelection.length > 0) {
			/**Update selected product value to payload */
			opt.filter(item => {
				let keyName = this.dashedToCamelCase(item.key);
				this.reqObj[keyName] = [];
				uniqueProductSelection.forEach(product => {
					if (item.alias_name === product.key) {
						item.value.push(product.value);
						this.reqObj[keyName].push(product.value);
						extOptedValues = this.reqObj;
					}
				});
			});
		} else {
			this.reqObj = [];
			extOptedValues = this.reqObj;
		}

		Object.assign(optedValues, extOptedValues);
		return optedValues;
	}

	dashedToCamelCase(str) {
		if (str !== 'SUBCATEGORY') {
			return str.toLowerCase().replace(/_(.)/g, function (match, chr) {
				return chr.toUpperCase();
			});
		} else {
			return 'subCategory';
		}
	}
}
