import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SELECTOR_TYPES } from '../../../../models/report-hovertexts.constant';

@Component({
	selector: 'crf-ca-save-default',
	templateUrl: './save-default.component.html',
	styleUrl: './save-default.component.scss'
})
export class SaveDefaultComponent {
	@Input() public selectorType: number;
	@Input() public configId: number;
	@Input() disable: boolean;
	@Input() menu: boolean = true;
	@Input() disableMenuOptions: boolean;
	@Output() menuSelector = new EventEmitter<any>();

	public showMoreOptions: boolean = false;
	public menuOptions = [];
	public selectorTypes = SELECTOR_TYPES;

	openMenu() {
		this.showMoreOptions = !this.showMoreOptions;
		this.menuOptions = [
			{
				id: 1,
				displayName: this.selectorType === 7 ? 'Set All as My Default' : 'Set as My Default',
				icon: 'save'
			},
			{
				id: 2,
				displayName: this.selectorType === 7 ? 'Reset All to My Default' : 'Reset to My Default',
				icon: 'reset'
			}
		];
	}

	hideSaveSelection() {
		return (
			(this.configId === 12 ||
				this.configId === 13 ||
				this.configId === 2 ||
				this.configId === 4 ||
				this.configId === 5 ||
				this.configId === 6 ||
				this.configId === 7) &&
			this.selectorType === 1
		);
	}

	// Not in used for now remove it later
	disableMenuLogic(menu): boolean {
		return this.disable;
	}

	menuItemClicked(menuId, selectorId) {
		this.showMoreOptions = false;
		this.menuSelector.emit({
			menuId: menuId,
			selectorId: selectorId
		});
	}
}
