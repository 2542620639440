import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { REST_API_CONSTANTS } from 'src/app/constants/rest-api-constants';
import { NlsnDataStoreService } from 'src/app/core/service/store/nlsn-data-store.service';
import { NlsnGlobalsContextExtract } from 'src/app/core/service/auth/nlsn-globals.service';
import { ReportService } from 'src/app/service/report.service';
import { BASE_URL } from 'src/environments/env.config.constants';

@Injectable({
	providedIn: 'root'
})
export class TrendedWeeklyService extends ReportService {
	constructor(
		http: HttpClient,
		nlsnDataStoreService: NlsnDataStoreService,
		nlsnGlobalsContextExtract: NlsnGlobalsContextExtract
	) {
		super(http, nlsnDataStoreService, nlsnGlobalsContextExtract);
	}

	getReportData(payload: any) {
		const url =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			REST_API_CONSTANTS.REPORT.TRENDED_WEEKLY.REPORT_URL;

		return this.http.post<any>(url, payload, this.options).pipe(
			map(result => {
				return result;
			})
		);
	}

	getPeriodEndDate(period: string, payload: any) {
		const url =
			BASE_URL[this.nlsnGlobalsContextExtract.appEnvironment] +
			REST_API_CONSTANTS.REPORT.TRENDED_WEEKLY.PERIOD_END_DATE +
			'/' +
			period;

		return this.http.post<any>(url, payload, this.options).pipe(
			map(result => {
				return result;
			})
		);
	}
}
