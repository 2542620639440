<div class="user-create-component">
	<!-- HeaderSection Page Title -->
	<crf-ca-concierge-header></crf-ca-concierge-header>
	<div class="rectangular-box">
		<div class="tabs">
			<div class="tab" [class.selected]="isTabSelected(1)" (click)="switchTab(1)">
				User Creation
			</div>
			<div class="tab" [class.selected]="isTabSelected(2)" (click)="switchTab(2)">Group</div>
			<!-- Add more tabs as needed -->
		</div>
		<div class="tab-content">
			<div class="left-half" *ngIf="isTabSelected(1)">
				<div class="left-radio">
					<!-- <input
						type="radio"
						name="userOption"
						id="createUser"
						value="createUser"
						[(ngModel)]="userOption"
					/>
					&nbsp; -->
					<h4 for="createUser">Create User</h4>
					&nbsp; &nbsp;
					<!-- <input
						type="radio"
						name="userOption"
						id="bulkImport"
						value="bulkImport"
						[(ngModel)]="userOption"
					/>
					&nbsp;
					<label for="bulkImport">Bulk Import Users</label> -->
				</div>
				<div *ngIf="userOption === 'createUser'" [formGroup]="userForm" class="userForm">
					<br /><br />
					<label for="firstName">{{ CREATE_USERS.FIRST_NAME }}</label>
					<input type="text" formControlName="firstName" placeholder="First Name" />
					<br />
					<div
						class="error_message"
						*ngIf="userForm.get('firstName').invalid && userForm.get('firstName').touched"
					>
						{{ CREATE_USERS.FIRST_NAME_ERROR }}
					</div>
					<br />
					<label for="lastName">{{ CREATE_USERS.LAST_NAME }}</label>
					<input type="text" formControlName="lastName" placeholder="Last Name" />
					<br />
					<div
						class="error_message"
						*ngIf="userForm.get('lastName').invalid && userForm.get('lastName').touched"
					>
						{{ CREATE_USERS.LAST_NAME_ERROR }}
					</div>
					<br />
					<label for="email">{{ CREATE_USERS.EMAIL }}</label>
					<input type="email" formControlName="email" placeholder="Email" />
					<br />
					<div
						class="error_message"
						*ngIf="userForm.get('email').invalid && userForm.get('email').touched"
					>
						{{ CREATE_USERS.EMAIL_ERROR }}
					</div>
				</div>
				<div *ngIf="userOption === 'bulkImport'">
					<input type="file" accept=".csv" placeholder="{{ CREATE_USERS.ADD_USER_LIST }}" />
					<br />
					<button (click)="addBulkUsers()">Add</button>
				</div>
			</div>
			<div *ngIf="isTabSelected(1)" class="separator"></div>
			<div class="right-half" *ngIf="isTabSelected(1)">
				<div class="organization-section">
					<h4>{{ CREATE_USERS.ORGANIZATION }}</h4>
					<div class="radio-group">
						<input
							type="radio"
							name="organization"
							id="manufacturer"
							[(ngModel)]="organizationType"
							value="manufacturer"
						/>
						<label for="{{ CREATE_USERS.MANUFACTURER_RADIO }}">{{
							CREATE_USERS.MANUFACTURER_RADIO
						}}</label>
						&nbsp; &nbsp;
						<niq-menu
							*ngIf="
								organizationType === 'manufacturer' ||
								organizationType === 'Demo User' ||
								organizationType === 'Connect Partner'
							"
							[(opened)]="showManufacturerSearch"
							[multi]="false"
							[verticalOffset]="manufacturerVOffset"
						>
							<niq-search-bar
								dropdown-trigger
								#niqSearchBar
								variant="expandable"
								size="large"
								placeholder="Search Organization"
								[disabled]="false"
								[error]="false"
								inputValue="{{ manufacturerSearchInput }}"
								errorMessage="Error"
								(inputValueChange)="manufacturerSearch($event)"
							></niq-search-bar>
							<div
								class="search-content"
								*ngIf="manufacturerSearchResults && manufacturerSearchResults.length > 0"
							>
								<div class="search-body">
									<div *ngIf="manufacturerSearchResults.length > 0">
										Showing {{ manufacturerSearchResults.length }} results
									</div>
									<div *ngFor="let item of manufacturerSearchResults">
										<niq-item (click)="selectedManufacturerChange(item.orgName)">{{
											item.orgName
										}}</niq-item>
									</div>
								</div>
							</div>
						</niq-menu>
						<div class="error_message" *ngIf="showManufacturerError">
							{{ CREATE_USERS.ORGANIZATION_ERROR }}
						</div>
					</div>
					<div class="radio-group">
						<input
							type="radio"
							name="organization"
							id="{{ CREATE_USERS.WALMART_RADIO }}"
							[(ngModel)]="organizationType"
							value="{{ CREATE_USERS.WALMART_RADIO }}"
						/>
						<label for="{{ CREATE_USERS.WALMART_RADIO }}">{{ CREATE_USERS.WALMART_RADIO }}</label>
					</div>
					<div class="radio-group">
						<input
							type="radio"
							name="organization"
							id="{{ CREATE_USERS.NIELSEN_RADIO }}"
							[(ngModel)]="organizationType"
							value="{{ CREATE_USERS.NIELSEN_RADIO }}"
						/>
						<label for="{{ CREATE_USERS.NIELSEN_RADIO }}">{{ CREATE_USERS.NIELSEN_RADIO }}</label>
					</div>
					<div class="radio-group">
						<input
							type="radio"
							name="organization"
							id="{{ CREATE_USERS.CONNECT_PARTNER_RADIO }}"
							[(ngModel)]="organizationType"
							value="{{ CREATE_USERS.CONNECT_PARTNER_RADIO }}"
						/>
						<label for="{{ CREATE_USERS.CONNECT_PARTNER_RADIO }}">{{
							CREATE_USERS.CONNECT_PARTNER_RADIO
						}}</label>
					</div>
					<div class="radio-group">
						<input
							type="radio"
							name="organization"
							id="{{ CREATE_USERS.DEMOUSER_RADIO }}"
							[(ngModel)]="organizationType"
							value="{{ CREATE_USERS.DEMOUSER_RADIO }}"
						/>
						<label for="{{ CREATE_USERS.DEMOUSER_RADIO }}">{{ CREATE_USERS.DEMOUSER_RADIO }}</label>
					</div>
				</div>
				<div
					class="client-section"
					*ngIf="organizationType === 'manufacturer' || organizationType === 'Demo User'"
				>
					<h4>{{ CREATE_USERS.CLIENT }}</h4>
					<label for="{{ CREATE_USERS.CLIENT }}">{{ CREATE_USERS.CLIENT }}</label>
					&nbsp; &nbsp;
					<niq-menu
						[selected]="-1"
						[opened]="false"
						[disabled]="false"
						(selectedChange)="selectedClientItemsChange($event)"
						[noOverlay]="false"
						[ignoreSelect]="false"
						[multi]="false"
						[menuOpenDelay]="0"
						[menuCloseDelay]="0"
						[verticalOffset]="0"
						[horizontalOffset]="0"
						[activateOnHover]="false"
					>
						<niq-search-bar
							dropdown-trigger
							#niqSearchBar
							variant="expandable"
							size="large"
							[disabled]="false"
							[error]="false"
							placeholder="Search Client"
							errorMessage="Error Message"
							(inputValueChange)="clientSearchTrigger($event)"
							inputValue="{{ clientSearchInput }}"
						></niq-search-bar>
						<div
							class="search-content"
							*ngIf="clientSearchResults && clientSearchResults.length > 0 && showClientSearch"
						>
							<div class="search-body">
								<div *ngIf="clientSearchResults.length > 0">
									Showing {{ clientSearchResults.length }} results
								</div>
								<div *ngFor="let item of clientSearchResults">
									<niq-item (click)="selectedClientChange(item)">{{ item }}</niq-item>
								</div>
							</div>
						</div>
					</niq-menu>
					<div class="error_message" *ngIf="showClientError">{{ CREATE_USERS.CLIENT_ERROR }}</div>
				</div>
				<niq-button variant="primary" colorTheme="light" (click)="nextStep()">Next</niq-button>
			</div>
			<!-- Content for the Group tab -->
			<div class="left-half responsive-boxes" *ngIf="isTabSelected(2)">
				<div class="box" id="box1">
					<h4>{{ CREATE_USERS.SELECT_GROUPS }}</h4>
					<niq-search-bar
						variant="expandable"
						size="large"
						[disabled]="false"
						[error]="false"
						placeholder="Search Group"
						(inputValueChange)="groupSearchTrigger($event)"
						style="margin-bottom: 10px; --search-input-width: 18vw"
					></niq-search-bar>
					<ul>
						<li
							*ngFor="let group of filteredGroups"
							(click)="selectGroup(group)"
							[class.selectedGroup]="group === selectedGroup"
							[style.background]="group === selectedGroup ? '#c2dbff' : ''"
							style="cursor: pointer"
						>
							{{ group.groupName }}
						</li>
					</ul>
				</div>
				<div class="buttons">
					<niq-button
						iconName="chevron-right"
						(click)="moveToSecondBox()"
						[state]="filteredGroups.includes(selectedGroup) ? 'default' : 'disabled'"
					></niq-button
					><br /><br />
					<niq-button
						(click)="moveToFirstBox()"
						[state]="selectedGroups.includes(selectedGroup) ? 'default' : 'disabled'"
						iconName="chevron-left

					"
					>
					</niq-button>
				</div>
				<div class="box" id="box2">
					<h4>Selected: {{ selectedGroups.length }}/{{ filteredGroups.length }}</h4>
					<ul>
						<li
							*ngFor="let group of selectedGroups"
							(click)="selectGroup(group)"
							[class.selectedGroup]="group === selectedGroup"
							[style.background]="group === selectedGroup ? '#c2dbff' : ''"
							style="cursor: pointer"
						>
							{{ group.groupName }}
						</li>
					</ul>
				</div>
				<div class="error_message" *ngIf="hasSelectedGroupIds === false">
					{{ CREATE_USERS.GROUP_SELECTION_ERROR }}
				</div>
			</div>

			<div *ngIf="isTabSelected(2)" class="separator"></div>
			<div class="right-half" *ngIf="isTabSelected(2)">
				<!-- Add content for the right half of the Group tab here -->
				<div>
					<niq-button (click)="dialogBox.open()">Create Group</niq-button>
					<niq-dialog #dialogBox [size]="'large'" [closeOnEsc]="true" [closeOnClickOutside]="true">
						<div dialogTitle style="width: 100%; display: flex; align-items: center">
							<div style="overflow: hidden; text-overflow: ellipsis; margin-right: 8px">
								Create Group
							</div>
						</div>
						<div dialogBody>
							<div class="dialoguebox-tabs">
								<button
									(click)="switchDialogueTab(1)"
									[class.dialoguebox-active]="isDialogueTabSelected(1)"
								>
									{{ CREATE_USERS.SELECT_CATEGORY }}
								</button>
								<button
									(click)="switchDialogueTab(2)"
									[class.dialoguebox-active]="isDialogueTabSelected(2)"
								>
									{{ CREATE_USERS.SELECT_APPS_ACCESS }}
								</button>
							</div>

							<div class="dialoguebox-tab-content" *ngIf="isDialogueTabSelected(1)">
								<!-- Content for Tab 1 -->
								<form [formGroup]="groupForm">
									<input
										type="text"
										id="groupName"
										formControlName="groupName"
										placeholder="Group Name"
										style="width: 45%; margin-left: 5px; margin-bottom: 5px; height: 40px"
									/>

									<span>
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span>

									<niq-button
										variant="primary"
										colorTheme="light"
										(click)="
											isCategoriesAdded ? removeSuggestedCategories() : addSuggestedCategories()
										"
									>
										{{
											isCategoriesAdded
												? organizationType === 'NielsenIQ' || organizationType === 'Walmart'
													? 'Remove All Categories'
													: 'Remove Suggested Categories'
												: organizationType === 'NielsenIQ' || organizationType === 'Walmart'
												? 'Add All Categories'
												: 'Add Suggested Categories'
										}}
									</niq-button>

									<div class="error_message" *ngIf="showGroupNameError">
										{{ CREATE_USERS.GROUP_NAME_ERROR }}
									</div>
								</form>
								<div class="error_message" *ngIf="showCategoryError">
									{{ CREATE_USERS.CATEGORY_SELECTION_ERROR }}
								</div>

								<div class="left-half responsive-boxes" *ngIf="isDialogueTabSelected(1)">
									<div class="box" id="box1">
										<niq-search-bar
											variant="expandable"
											size="large"
											[disabled]="false"
											[error]="false"
											placeholder="Search Category"
											(inputValueChange)="dimCategorySearchTrigger($event)"
											style="margin-bottom: 10px; --search-input-width: 18vw"
										></niq-search-bar>
										<ul>
											<li
												*ngFor="let category of dimCategory"
												(click)="selectDimCategory(category)"
												[class.selected]="category === selectedDimCategory"
												[style.background]="category === selectedDimCategory ? '#c2dbff' : ''"
												style="cursor: pointer"
											>
												{{ category.category }}
											</li>
										</ul>
									</div>

									<div class="buttons">
										<niq-button
											iconName="chevron-right"
											(click)="moveCatToSecondBox()"
											[state]="dimCategory.includes(selectedDimCategory) ? 'default' : 'disabled'"
										></niq-button
										><br /><br />
										<niq-button
											(click)="moveCatToFirstBox()"
											[state]="
												selectedDimCategories.includes(selectedDimCategory) ? 'default' : 'disabled'
											"
											iconName="chevron-left
	
						"
										>
										</niq-button>
									</div>

									<div class="box" id="box2">
										<h4>Selected: {{ selectedDimCategories.length }}/{{ dimCategory.length }}</h4>
										<ul>
											<li
												*ngFor="let category of selectedDimCategories"
												(click)="selectDimCategory(category)"
												[class.selected]="category === selectedDimCategory"
												[style.background]="category === selectedDimCategory ? '#c2dbff' : ''"
												style="cursor: pointer"
											>
												{{ category.category }}
											</li>
										</ul>
									</div>
								</div>
							</div>
							<br />

							<div
								dialogFooter
								style="width: 100%; display: flex; align-items: center; gap: 8px"
								*ngIf="isDialogueTabSelected(1)"
							>
								<niq-button
									(click)="dialogBox.close()"
									size="medium"
									variant="ghost"
									[iconName]="undefined"
								>
									Cancel
								</niq-button>
								<niq-button
									size="medium"
									variant="primary"
									[iconName]="undefined"
									(click)="dialogBoxNext()"
								>
									Next
								</niq-button>
							</div>

							<div class="dialoguebox-tab-content" *ngIf="isDialogueTabSelected(2)">
								<!-- Content for Tab 2 -->
								<div class="left-half responsive-boxes" *ngIf="isDialogueTabSelected(2)">
									<div class="box" id="box1">
										<h3>{{ CREATE_USERS.SELECT_APPS_TO_ACCESS }}</h3>

										<ul>
											<niq-checkbox-group>
												<niq-checkbox
													size="small"
													*ngFor="let app of groupApps"
													(click)="toggleAppSelection(app.id)"
													[class.selected]="isAppSelected(app.id)"
												>
													{{ app.applicationName }}
												</niq-checkbox>
											</niq-checkbox-group>

											<br />
										</ul>
									</div>

									<div class="box" id="box2">
										<h3>{{ CREATE_USERS.WALMART_OVT_DASHBOARDS }}</h3>
										<ul>
											<niq-checkbox-group>
												<niq-checkbox
													size="small"
													(click)="toggleSelectAllDashboards()"
													[checked]="areAllDashboardsSelected()"
												>
													All
												</niq-checkbox>

												<niq-checkbox
													size="small"
													*ngFor="let dashboard of dashboards"
													(click)="toggleDashboardSelection(dashboard.id)"
													[checked]="isDashboardSelected(dashboard.id)"
												>
													{{ dashboard.questioName }}
												</niq-checkbox>
											</niq-checkbox-group>

											<br />
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div
							dialogFooter
							style="width: 100%; display: flex; align-items: center; gap: 8px"
							*ngIf="isDialogueTabSelected(2)"
						>
							<niq-button
								size="medium"
								variant="primary"
								[iconName]="undefined"
								(click)="dialogBoxSave(); dialogBox.close()"
							>
								Save
							</niq-button>
						</div>
					</niq-dialog>
				</div>
				<div>
					<niq-button
						class="submit-button-container"
						size="large"
						variant="primary"
						[state]="selectedGroups.length === 0 ? 'disabled' : 'default'"
						(click)="submitForm()"
						>Submit</niq-button
					>
				</div>
			</div>
		</div>
	</div>
</div>
