import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { ConsumerDecisionTreeUtility } from 'src/app/utility/report/consumer-decision-tree.utility';
import { ConsumerDecisionTreeNodeComponent } from '../consumer-decision-tree-node/consumer-decision-tree-node.component';

@Component({
	selector: 'crf-ca-node',
	templateUrl: './node.component.html',
	styleUrl: './node.component.scss'
})
export class NodeComponent {
	@Input() childNode: any;
	@Input() level: number;
	@Input() category: string;

	@Input() parent: ConsumerDecisionTreeNodeComponent;

	constructor(private utility: ConsumerDecisionTreeUtility) {}

	displayExpandedUpcTableView() {
		//Forming empty node values
		let nodeValues: any = {};
		for (let i: number = 0; i < 4; i++) nodeValues['char' + i] = nodeValues['lvl' + i] = '';

		//Setting current state value
		nodeValues['char' + this.level] = this.category;
		nodeValues['lvl' + this.level] = this.childNode.treenode;

		//Recursion to get the parent values
		let parentNode = this.parent;

		while (parentNode?.parent) {
			let parentRef = parentNode?.parent;
			nodeValues['char' + parentRef.level] = parentRef?.category;
			nodeValues['lvl' + parentRef.level] = parentRef?.childNode.treenode;

			//Setting value for base condition
			parentNode = parentRef?.parent;
		}

		this.utility.expandTableView$.next(nodeValues);
	}
}
