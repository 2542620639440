import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { QUADRANTS } from 'src/app/page/performance-quadrant-report-page/model/quadrants.model';
import * as _ from 'lodash';
import { ToastService } from '@nielseniq/athena-core';
import { TOAST } from 'src/app/constants/toast.constant';
import { ReplaySubject, Subscription, takeUntil } from 'rxjs';
import { PerformanceQuadrantReportService } from '../service/performance-quadrant-report.service';

@Component({
	selector: 'crf-ca-main-performance-quadrant-report',
	templateUrl: './main-performance-quadrant-report.component.html',
	styleUrl: './main-performance-quadrant-report.component.scss'
})
export class MainPerformanceQuadrantReportComponent implements OnDestroy {
	@Output() tabChange = new EventEmitter<string>();

	//QUADRANT
	selectedTab: string = 'product-quadrants';
	quadrants: any[] = [
		{
			title: 'Accelerate',
			titleDescription: 'Losing Share in a Growing Market',
			columns: [],
			data: [],
			isDataFetched: false,
			sortColumn: 'valueOfSharePtCharge',
			sortOrder: ''
		},
		{
			title: 'Maintain',
			titleDescription: 'Gaining Share in a Growing Market',
			columns: [],
			data: [],
			isDataFetched: false,
			sortColumn: 'valueOfSharePtCharge',
			sortOrder: 'reverse'
		},
		{
			title: 'Evaluate',
			titleDescription: 'Losing Share in a Declining Market',
			columns: [],
			data: [],
			isDataFetched: false,
			sortColumn: 'valueOfSharePtCharge',
			sortOrder: ''
		},
		{
			title: 'Defend',
			titleDescription: 'Gaining Share in a Declining Market',
			columns: [],
			data: [],
			isDataFetched: false,
			sortColumn: 'valueOfSharePtCharge',
			sortOrder: 'reverse'
		}
	];
	expandedQuadrantIndex: number = -1;
	loadColumns: boolean;

	unsubscribeOnDestroy$: ReplaySubject<any> = new ReplaySubject<any>();

	constructor(
		private performanceReportQuadrantService: PerformanceQuadrantReportService,
		private toastService: ToastService
	) {}

	loadReport(by: string, period: string, factType: string, payload: any): void {
		//Setting loader
		this.quadrants.forEach(quadrant => {
			quadrant.isDataFetched = false;
			quadrant.data = [];
		});

		//API call
		this.performanceReportQuadrantService
			.getData(period, by, factType, payload)
			.pipe(takeUntil(this.unsubscribeOnDestroy$))
			.subscribe({
				next: res => {
					if (res.result) {
						//Clearing existing data
						this.quadrants.forEach(quadrant => {
							quadrant.data = [];
						});

						//Segregate quadrant data
						this.segregateQuadrantData(res.result);

						//Sort the data
						this.sortData();
					} else
						this.toast('warning', 'Performance Quadrant', 'Ng After View Init: Invalid Response');
				},
				error: err => {
					this.toast('error', 'Performance Quadrant', 'Ng After View Init: Invalid Response');
					this.quadrants.forEach(quadrant => (quadrant.isDataFetched = true));
				},
				complete: () => this.quadrants.forEach(quadrant => (quadrant.isDataFetched = true))
			});
	}

	toggleQuadrantExpansion(index: number): void {
		this.expandedQuadrantIndex == index
			? (this.expandedQuadrantIndex = -1)
			: (this.expandedQuadrantIndex = index);
	}

	//UTILITY
	sortData(): void {
		this.quadrants.forEach(quadrant => {
			let sortedData = [];

			//Sorting based on the sorting order
			if (quadrant.sortOrder == 'reverse')
				sortedData = _.sortBy(quadrant.data, quadrant.sortColumn).reverse();
			else sortedData = _.sortBy(quadrant.data, quadrant.sortColumn);

			//Updating UI content
			quadrant.data = sortedData;
		});
	}

	segregateQuadrantData(data: QUADRANTS[]): void {
		let endDate = null;

		if (!_.isEmpty(data)) {
			endDate = new Date(data[0].periodEndDate.trim());
			let options = { month: 'short' };
			let month = endDate.toLocaleDateString('en-US', options);
			let today = new Date();
			if (today.getFullYear() - endDate.getFullYear() >= 100)
				endDate.setFullYear(endDate.getFullYear() + 100);
			endDate = month + '-' + endDate.getDate() + '-' + endDate.getFullYear();

			for (let i = 0; i < data.length; i++) {
				if (Math.round(data[i].valueOfSharePtCharge) < 0)
					if (data[i].totalMktSalesChange < 0)
						//Losing Share in a Declining Market
						this.quadrants[2].data.push(data[i]);
					//Losing Share in a Growing Market
					else this.quadrants[0].data.push(data[i]);
				//Gaining Share in a Declining Market
				else if (data[i].totalMktSalesChange < 0) this.quadrants[3].data.push(data[i]);
				//Gaining Share in a Growing Market
				else this.quadrants[1].data.push(data[i]);
			}
		}
	}

	loadColumnDefinition(columnDefinition) {
		this.loadColumns = false;

		this.quadrants.forEach(quadrant => {
			let columnDefNew = structuredClone(columnDefinition);
			quadrant.columns = [];

			//Setting value of sort based on the quadrant
			columnDefNew.forEach(column => {
				if (column && column.field === quadrant.sortColumn)
					if (quadrant.sortOrder === 'reverse') column.sort = 'desc';
					else column.sort = 'asc';
			});

			quadrant.columns = columnDefNew;
		});

		setTimeout(() => (this.loadColumns = true), 10);
	}

	toast(type: any, title: string, message: string): void {
		this.toastService.InjectToast(type, message, '', TOAST.TIMEOUT, '', title, TOAST.SIZE);
	}

	ngOnDestroy(): void {
		this.unsubscribeOnDestroy$.next(true);
		this.unsubscribeOnDestroy$.complete();
	}
}
