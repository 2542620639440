import { Component, OnInit, HostListener, Output, EventEmitter, ViewChild } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { AssortmentApiService } from './service/assortment-api.service';
import { ProductSearchService } from 'src/app/service/product-search.service';
import { UtilityService } from '../services/utility.service';
import { AssortmentDashboardService } from '../services/assortment-dashboard.service';
import { ReportDimension } from 'src/app/models/report-dimension.model';
import { ASSORTMENT_DASHBOARD } from '../../constants/ui-constants';
import { pageHoverTexts, SELECTOR_TYPES } from 'src/app/models/report-hovertexts.constant';
import { Subject, forkJoin, map, takeUntil } from 'rxjs';
import { TextChangePipe } from 'src/app/pipes/text-change.pipe';
import { ToastService } from '@nielseniq/athena-core';
import { TOAST } from 'src/app/constants/toast.constant';
import { GridService } from 'src/app/shared/components/grid/grid-service/grid.service';
import { AssortmentWMvsRMInnerTableComponent } from './assortment-wmvs-rm-inner-table/assortment-wmvs-rm-inner-table.component';
import { TopbarUtilityService } from 'src/app/core/service/topbar/topbar-utility.service';
import { EXCEL } from 'src/app/constants/report.constant';
import { ReportHeaderUtility } from 'src/app/shared/service/report/report-header.utility';

export function getFactMap(factData: any): Map<String, any[]> {
	let factDataMap: Map<String, any[]> = new Map<string, any[]>();

	// Forming 3*n array from the column specifications
	factData.forEach(fact => {
		if (factDataMap.has(fact.gkey))
			if (fact.valuebase === 'Dollars') factDataMap.get(fact.gkey)[0] = fact;
			else if (fact.valuebase === 'Units') factDataMap.get(fact.gkey)[1] = fact;
			else factDataMap.get(fact.gkey)[2] = fact;
		else if (fact.valuebase === 'Dollars') factDataMap.set(fact.gkey, [fact, null, null]);
		else if (fact.valuebase === 'Units') factDataMap.set(fact.gkey, [null, fact, null]);
		else factDataMap.set(fact.gkey, [null, null, fact]);
	});

	return factDataMap;
}

@Component({
	selector: 'crf-ca-assortment-dashboard',
	templateUrl: './assortment-dashboard.component.html',
	styleUrls: ['./assortment-dashboard.component.scss']
})
export class AssortmentDashboardComponent implements OnInit {
	@ViewChild(AssortmentWMvsRMInnerTableComponent) innerTable: AssortmentWMvsRMInnerTableComponent;

	@Output() resetColDefs: EventEmitter<void> = new EventEmitter<void>();

	// Properties
	colHeadersObj: { [key: string]: string };
	colHeaders: string;

	slected_metrics: string[] = [];
	modifiedColDefs: any = this.slected_metrics;

	fourByFourCells = ASSORTMENT_DASHBOARD.FOUR_BY_FOUR_CELLS;

	factsCol = ASSORTMENT_DASHBOARD.FACT_LIST;

	defaultFacts: any;

	factList: any = [];

	isDashboardDataAvailable: boolean;
	dashboardData;
	tabs: { key: string; value: string }[];
	selectedBaseFact_obj: any;
	selectedBaseFact: any;
	baseFact: string;
	pinnedTopRowData: any;
	categoryList: any;
	totalUs = {
		key: 'Total US',
		value: {
			remainingMarket: 'Walmart Total US xAOC Rem',
			walmartMarket: 'Walmart Total US TA'
		}
	};
	showMulticheckDropdown: boolean = false;
	selectedProductToShow: { level: string; value: string } = {
		level: '',
		value: ''
	};
	showProductView: boolean = false;
	selectorTypes = SELECTOR_TYPES;

	showBaseFactSelectDropdown: boolean = false;
	showFactSelectorDropdown: boolean = false;

	showMarketfilterDropdown: boolean = false;

	showPeriodDropdown: boolean = false;

	qid: number = 9;
	periodvar: any;
	selectedMarket: string[];

	selectedByProdName: string;

	assortmentCtrl: ReportDimension = {
		dashboardName: 'Item Assortment Summary (Scan)',
		selectedMarket: [''],
		selectedProduct: [],
		period: '',
		multiMarketEnabled: true,
		baseFact: ''
	};

	baseFactSearchTerm: string = '';
	periodData: any = {};

	pageHoverTexts: any = pageHoverTexts;
	marketMenuItems: any = [];
	periodList: any = [];
	baseFactList: any = [];
	marketList: any;
	companyList: any;
	baseFactKey: any;
	factKey: any;
	tableLoader: boolean;
	suppressedProducts: any[];
	showCategory: any;
	upcCountsColumnDefs: any = [];
	upcCountsRowData: any[];
	upcPercentageColumnDefs: any = [];
	upcPercentageRowData: any[];
	mktTableColumnDefs: any[];
	mktTableRowData: any = [];
	screenWidth: any;

	pcWidth: any;
	ccWidth: any;
	columnWidthPercentage: string;

	headerName: string;
	sort: string;

	defaultFactColumns: any = [];

	public readonly unSubscribeAll: Subject<void> = new Subject<void>();
	pageTitle: any;
	selectedFactCount: number;

	// Constructor
	constructor(
		public requestService: AssortmentApiService,
		public productSearchService: ProductSearchService,
		public assortmentDashboardService: AssortmentDashboardService,
		public utilityService: UtilityService,
		public textChangePipe: TextChangePipe,
		public toastService: ToastService,
		public gridColumnService: GridService,
		private topBarUtility: TopbarUtilityService,
		private utility: ReportHeaderUtility
	) {}

	// Lifecycle Hook
	ngOnInit(): void {
		this.requestService.isInnerTable = false;
		this.pageTitle = this.topBarUtility.getPageTitleReport();
		this.selectedBaseFact = this.assortmentCtrl.baseFact;
		this.selectedBaseFact_obj = ASSORTMENT_DASHBOARD.BASE_FACT_LIST;

		let baseFactvar = ASSORTMENT_DASHBOARD.BASE_FACT_LIST.slice(2).map(item => {
			return { key: item.field, value: item.headerName };
		});

		baseFactvar.forEach(item => {
			this.baseFactList.push(item);
		});

		// this.getAllTabs();

		this.assortmentDashboardService.getMarketList().subscribe((marketList: any) => {
			this.marketList = marketList;
			this.marketMenuItems = Object.entries(marketList).map(([key, value]) => ({
				key,
				value,
				isSelected: false
			}));
		});

		for (let item of this.baseFactList) {
			if (item.value === this.selectedBaseFact) {
				this.baseFactKey = item.key;
				break;
			}
		}

		this.onInitialLoad();
		this.initializeSelectedMetricsAndHeaders();
		this.screenWidth = window.innerWidth;

		if (this.screenWidth > 1500) {
			this.ccWidth = '290%';
			this.pcWidth = '150%;';
		} else if (this.screenWidth > 1000) {
			this.ccWidth = '190%';
			this.pcWidth = '80%';
		} else {
			this.ccWidth = '140%';
			this.pcWidth = '100%';
		}
		if (this.screenWidth > 1500) {
			this.columnWidthPercentage = '145%'; // Large screen width
		} else if (this.screenWidth > 1000) {
			this.columnWidthPercentage = '105%'; // Medium screen width
		} else {
			this.columnWidthPercentage = '90%'; // Small screen width
		}
		this.upcCountsColumnDefs = [
			{
				headerName: 'Metric',
				cellClass: 'main',
				cellFilter: '',
				field: 'metric',
				width: `${this.ccWidth}`,
				height: 500
			},
			{
				headerName: 'Value',
				cellClass: 'special',
				cellFilter: '',
				field: 'value',
				width: '140%',
				height: 500
			}
		];

		this.upcPercentageColumnDefs = [
			{ headerName: 'Metric', field: 'metric', width: '150%', cellClass: 'main', cellFilter: '' },
			{
				headerName: 'Active UPCs',
				field: 'activeUPCs',
				width: `${this.pcWidth}`,
				cellClass: 'special',
				cellFilter: "zip:'perc'"
			},
			{
				headerName: 'New UPCs',
				field: 'newUPCs',
				width: `${this.pcWidth}`,
				cellClass: 'special',
				cellFilter: "zip:'perc'"
			}
		];
	}
	initializeSelectedMetricsAndHeaders(): void {
		this.slected_metrics = this.modifiedColDefs
			.filter(colDef => {
				const matchedItem = this.baseFactList.find(item => item.value === colDef);
				return matchedItem ? matchedItem.key : null;
			})
			.map(colDef => {
				const matchedItem = this.baseFactList.find(item => item.value === colDef);
				return matchedItem ? matchedItem.key : null;
			})
			.filter(key => key !== null);
		this.colHeaders = this.slected_metrics
			.map(key => {
				const matchedItem = this.baseFactList.find(item => item.key === key);
				return matchedItem ? matchedItem.value : '';
			})
			.filter(value => value !== '')
			.join(', ');
	}

	@HostListener('document:click', ['$event'])
	onDocumentClick(event: MouseEvent): void {
		const clickedElement = event.target as HTMLElement;
		if (!clickedElement.closest('.dropdown-ovt') && !clickedElement.closest('.select-dropdown')) {
			this.showMulticheckDropdown = false;
			this.showPeriodDropdown = false;
			this.showBaseFactSelectDropdown = false;
			this.showFactSelectorDropdown = false;
		}
	}
	initializeFactsSelection(): void {
		if (!this.defaultFacts) {
			console.warn('defaultFacts is null or undefined.');
			this.defaultFacts = 'Count of UPCs, WM $ Sales, RM $ Sales, TM $ Sales';
		}

		this.selectedFactCount = 0;
		this.factsCol.forEach(fact => {
			fact.isSelected = this.defaultFacts.includes(fact.headerName);
			if (fact.isSelected == true) this.selectedFactCount++;
		});

		this.getFactColumnDefinition();
	}

	onInitialLoad() {
		this.tableLoader = true;

		let requestArray = [
			this.utilityService.getDefaultSelectors(this.qid),
			this.assortmentDashboardService.getMarketList(),
			this.assortmentDashboardService.getPeriodList()
		];

		forkJoin(requestArray)
			.pipe(
				map(([defaultAll, allMarket, allPeriod]) => {
					return {
						defaultAll: defaultAll,
						allMarket: allMarket,
						allPeriod: allPeriod
					};
				})
			)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe({
				next: response => {
					if (response['allMarket']) {
						this.marketList = response['allMarket'];
						Object.entries(this.marketList).map(market => {
							let marketEntry = { key: market[0], value: market[1], isSelected: false };
							this.marketMenuItems.push(marketEntry);
						});
					} else {
						this.marketList = [];
					}
					if (response['allPeriod']) {
						this.periodData.periods = response['allPeriod'];
						Object.keys(this.periodData.periods).forEach(item => {
							if (this.periodData.periods[item].defaultPeriod) {
								this.periodData.default_key = item;
								this.periodData.currentPeriod = this.periodData.periods[item].currentPeriod;
								this.periodData.previousPeriod = this.periodData.periods[item].previousPeriod;
								this.periodData.twoYAPeriod = this.periodData.periods[item].twoYAPeriod;
								this.periodData.id = this.periodData.periods[item].id;
							}
							this.periodList.push({ key: item, value: item });
						});
					} else {
						this.periodData = {};
					}
					if (response['defaultAll']) {
						this.assortmentDashboardService.defaultSelectors = response['defaultAll'];
						this.defaultFacts = this.assortmentDashboardService.defaultSelectors.facts;
						this.onApplyDefault(response['defaultAll'], 7);
					} else {
						this.assortmentDashboardService.defaultSelectors = {};
					}
					this.getPeriodEndDate(this.periodData?.id, response?.['defaultAll']);
					this.initializeFactsSelection(); // Call the new method here
				},
				error: error => {
					console.log('Error occurred', error);
				}
			});
	}

	getPeriodEndDate(period, productSelections) {
		let data = {
			department: productSelections?.department,
			categoryGroup: productSelections?.categorygroup,
			category: productSelections?.category,
			subCategory: productSelections?.subcategory,
			segment: productSelections?.segment,
			brandOwnerHigh: productSelections?.brandownerhigh,
			brandOwnerLow: productSelections?.brandownerlow,
			brandHigh: productSelections?.brandhigh,
			totalBusiness: productSelections?.totalbusiness,
			majorBusiness: productSelections?.majorbusiness
		};
		this.assortmentDashboardService
			.getPeriodEndDate(this.qid, period, data)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(value => (this.assortmentCtrl.periodEndDate = value.periodEndDate));
	}

	getFactColumnDefinition() {
		let facts = [];

		let factMap: Map<String, any[]> = this.getFactMap(this.factsCol);
		let colDefinition: any[] = [];
		// colDefinition.push(factMap.get('value')[0][2]);

		factMap.forEach(fact => {
			let keyname = this.utility.getFactSelectorMapping(fact);

			facts.push(keyname);
		});

		facts.forEach(fact => {
			if (fact.isCheckedDollar) colDefinition.push(factMap.get(fact.value)[0]);
			else colDefinition.push(null);
			if (fact.isCheckedUnit) colDefinition.push(factMap.get(fact.value)[1]);
			else colDefinition.push(null);
			if (fact.isCheckedOther) colDefinition.push(factMap.get(fact.value)[2]);
			else colDefinition.push(null);
		});

		this.factList = facts;

		return colDefinition;
	}
	getFactMap(factData: any): Map<String, any[]> {
		let factDataMap: Map<string, any[]> = new Map<string, any[]>();

		//Forming 3*n array from the column specifications
		factData.forEach(fact => {
			if (factDataMap.has(fact.gkey))
				if (fact.valuebase === 'Dollars') factDataMap.get(fact.gkey)[0] = fact;
				else if (fact.valuebase === 'Units') factDataMap.get(fact.gkey)[1] = fact;
				else factDataMap.get(fact.gkey)[2] = fact;
			else if (fact.valuebase === 'Dollars') factDataMap.set(fact.gkey, [fact, null, null]);
			else if (fact.valuebase === 'Units') factDataMap.set(fact.gkey, [null, fact, null]);
			else factDataMap.set(fact.gkey, [null, null, fact]);
		});

		return factDataMap;
	}

	onApplyDefault(response, selector) {
		if (selector === 0 || selector === 7) {
			let value: any = this.assortmentDashboardService.getSelectedProducts(response);
			this.assortmentDashboardService
				.retrieveLevelData(value.selectedProduct.level, this.qid, value)
				.pipe(takeUntil(this.unSubscribeAll))
				.subscribe(val => {
					this.categoryList = val.result;
					let defaultIndex = this.assortmentDashboardService.findDefaultByLevel(
						val.result,
						value.selectedProduct.level,
						'key'
					);
					// this.assortmentCtrl.selectedLevelToShow = this.categoryList[defaultIndex].value;
					// this.requestService.payload.level = this.categoryList[defaultIndex].key;
					this.loadDashboard();
				});
			this.selectedProductToShow = value.selectedProduct;
			this.getAllTabs();
			this.assortmentCtrl.selectedProduct = [];
			if (response?.totalbusiness) {
				this.assortmentCtrl.selectedProduct.push({
					displayName: 'Total Business',
					displayValue: [response.totalbusiness]
				});
				this.requestService.payload.totalBusiness = response.totalbusiness;
			}
			if (response?.majorbusiness) {
				this.assortmentCtrl.selectedProduct.push({
					displayName: 'Major Business',
					displayValue: [response.majorbusiness]
				});
				this.requestService.payload.majorBusiness = response.majorbusiness;
			}
			if (response?.department) {
				this.assortmentCtrl.selectedProduct.push({
					displayName: 'Department',
					displayValue: [response.department]
				});
				this.requestService.payload.department = response.department;
			}
			if (response?.categorygroup) {
				this.assortmentCtrl.selectedProduct.push({
					displayName: 'Category Group',
					displayValue: [response.categorygroup]
				});
				this.requestService.payload.categoryGroup = response.categorygroup;
			}
			if (response?.category) {
				this.assortmentCtrl.selectedProduct.push({
					displayName: 'Category',
					displayValue: [response.category]
				});
				this.requestService.payload.category = response.category;
			}
			if (response?.subcategory) {
				this.assortmentCtrl.selectedProduct.push({
					displayName: 'Subcategory',
					displayValue: [response.subcategory]
				});
				this.requestService.payload.subCategory = response.subcategory;
			}
			if (response?.segment) {
				this.assortmentCtrl.selectedProduct.push({
					displayName: 'Segment',
					displayValue: [response.segment]
				});
				this.requestService.payload.segment = response.segment;
			}
			if (response?.brandownerhigh) {
				this.assortmentCtrl.selectedProduct.push({
					displayName: 'Brand Owner High',
					displayValue: [response.brandownerhigh]
				});
				this.requestService.payload.brandOwnerHigh = response.brandownerhigh;
			}
			if (response?.brandownerlow) {
				this.assortmentCtrl.selectedProduct.push({
					displayName: 'Brand Owner Low',
					displayValue: [response.brandownerlow]
				});
			}
			this.requestService.payload.brandOwnerLow = response.brandownerlow;
		}
		if (selector === 3 || selector === 7) {
			this.selectedBaseFact = this.assortmentDashboardService.defaultSelectors.baseFact;
			let selectedFact;
			if (!this.selectedBaseFact) {
				selectedFact = 'Count of UPCs';
			} else {
				selectedFact = this.selectedBaseFact;
			}
			this.utilityService.preselectDefault(this.baseFactList, selectedFact);
		}

		if (selector === 5 || selector === 7) {
			this.assortmentCtrl.period = response.periodIds ?? this.periodData.default_key;
			this.utilityService.preselectDefault(this.periodList, this.assortmentCtrl.period);
			this.periodvar = response.periodIds ?? this.periodData.default_key;
			this.requestService.payload.period = this.periodvar;

			if (this.periodData.periods[this.periodvar]) {
				let matchingPeriod = this.periodData.periods[this.periodvar];
				this.requestService.payload.cyPeriod = matchingPeriod.currentPeriod;
				this.requestService.payload.yaPeriod = matchingPeriod.previousPeriod;
				this.periodData.id = matchingPeriod.id;
				this.periodData.currentPeriod = matchingPeriod.currentPeriod;
			} else {
				console.error(`Period "${this.periodvar}" not found in periodData.periods.`);
			}
		}

		if (selector === 6 || selector === 7) {
			this.modifiedColDefs = this.defaultFacts;
			this.getFactColumnDefinition();
		}

		if (selector === 2 || selector === 7) {
			this.marketMenuItems = [];
			if (response.market === 'Total US' || response.market === null) {
				Object.entries(this.marketList).map(market => {
					let marketEntry = { key: market[0], value: market[1], isSelected: false };
					this.marketMenuItems.push(marketEntry);
				});
				this.assortmentCtrl.selectedMarket = ['Total US'];
				this.requestService.payload.rmMarket = [this.totalUs.value.remainingMarket];
				this.requestService.payload.wmMarket = [this.totalUs.value.walmartMarket];
			} else {
				Object.entries(this.marketList).map(market => {
					let marketEntry = {
						key: market[0],
						value: market[1],
						isSelected: this.utilityService
							.defaultSelectorSplit(response.market)
							.includes(market[0])
					};
					this.marketMenuItems.push(marketEntry);
				});
				this.assortmentCtrl.selectedMarket = this.utilityService.defaultSelectorSplit(
					response.market
				);
				this.assortmentCtrl.selectedMarket.forEach(market => {
					this.requestService.payload.wmMarket.push(this.marketList[market].walmartMarket);
					this.requestService.payload.rmMarket.push(this.marketList[market].remainingMarket);
				});
			}
			this.requestService.payload.market = this.assortmentCtrl?.selectedMarket;
		}

		// if (selector === 4 || selector === 7) {
		//     this.assortmentCtrl.volumeBasis = response.baseFactType ?? this.baseFactList[0].value;
		//     this.requestService.payload.baseFact = this.baseFactList.filter(
		//         fact => fact.value === this.assortmentCtrl.volumeBasis
		//     )[0].key;
		// }
	}

	getHeaderName(index: number): string {
		switch (index) {
			case 1:
				return 'New';
			case 2:
				return 'Existing';
			case 3:
				return 'Delisted';
			case 4:
				return 'Not Carried';
			case 5:
				return 'Total';
			default:
				return '';
		}
	}

	getReportData() {
		this.requestService
			.getAssortmentInfo(this.requestService.payload)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(res => {
				this.isDashboardDataAvailable = true;
				this.dashboardData = res.responseData;
				this.upcCountsRowData = [
					{
						metric: 'UPCs carried at Walmart',
						value: res.responseData.upcCounts.upcCarriedAtWalmart
					},
					{ metric: 'UPCs Carried at RM', value: res.responseData.upcCounts.upcCarriedAtRM },
					{ metric: 'Delisted UPCs', value: res.responseData.upcCounts.delistedUPCs }
				];

				// Define the dimensions of your matrix
				let numRows = 5; // Number of rows
				let numCols = 5; // Number of columns

				// Find the selected base fact object from selectedBaseFact_obj
				let selectedBaseFactObj = this.selectedBaseFact_obj.find(
					cell => cell.headerName === this.selectedBaseFact
				);

				let cellFilter = selectedBaseFactObj ? selectedBaseFactObj.cellFilter : '';
				let cellClass = selectedBaseFactObj ? selectedBaseFactObj.cellClass : '';

				// Define column definitions
				this.mktTableColumnDefs = [
					{
						headerName: '',
						field: 'rowHeader',
						width: `${this.columnWidthPercentage}`,
						cellFilter: '',
						cellClass: 'main'
					}
				];
				for (let i = 1; i <= numCols; i++) {
					this.mktTableColumnDefs.push({
						headerName: this.getHeaderName(i),
						field: i.toString(),
						width: `${this.columnWidthPercentage}%`,
						cellFilter: cellFilter,
						cellClass: cellClass,
						tooltipValueGetter: params => {
							return `${params.data.rowHeader} UPCs at Walmart & ${params.colDef.headerName} UPCs in remaining market`;
						}
					});
				}

				// Define row data
				this.mktTableRowData = [];
				let currentValue = 1;

				for (let i = 1; i <= numRows; i++) {
					let rowData = { rowHeader: this.getHeaderName(i) };
					for (let j = 1; j <= numCols; j++) {
						rowData[j.toString()] = res.responseData.tableValues[currentValue++][this.baseFactKey];
					}
					this.mktTableRowData.push(rowData);
				}

				this.upcPercentageRowData = [
					{
						metric: 'UPCs',
						activeUPCs: res.responseData.upcCounts.activeUPCPercentage,
						newUPCs: res.responseData.upcCounts.newUPCPercentage
					},
					{
						metric: '$',
						activeUPCs: res.responseData.upcCounts.activeUPCDollarPercentage,
						newUPCs: res.responseData.upcCounts.newUPCDollarPercentage
					}
				];
			});
	}

	getCellNumber(rowIndex: number, colIndex: number): number {
		let numRows = 5; // Number of rows
		let numCols = 5; // Number of columns
		let adjustedRowIndex = rowIndex + 1;
		let adjustedColIndex = colIndex + 1;
		// Calculate cell number
		let cellNumber = (adjustedRowIndex - 1) * numCols + adjustedColIndex;
		return cellNumber;
	}

	showInDetailData(cellData: any, columnIndex: string): void {
		let rowHeader = cellData.rowHeader;
		let colIndex = parseInt(columnIndex);
		let rowNumber = this.calculateRowNumber(rowHeader);
		let quadrant = this.calculateQuadrant(rowNumber, colIndex);
		this.requestService.payload.quadrant = quadrant.toString();

		this.showProductView = false;
		this.showMulticheckDropdown = false;
		this.showPeriodDropdown = false;
		this.showBaseFactSelectDropdown = false;

		if (cellData.rowHeader === 'Total' || colIndex == 5 || columnIndex == 'rowHeader') {
			cellData.cellClass = 'no-decor';
			cellData['rowHeaderClass'] = 'no-decor';
			cellData[colIndex.toString() + 'Class'] = 'no-decor';
			return;
		}

		let var2 = this.mktTableColumnDefs.find(col => col.headerName === rowHeader);
		this.requestService.payload.baseFact = this.selectedBaseFact;
		this.requestService.isInnerTable = !this.requestService.isInnerTable;
		// this.selectedBaseFact = this.assortmentCtrl.baseFact;

		this.pinnedTopRowData = [this.dashboardData.tableValues[quadrant]];

		this.initializeFactsSelection;
		this.getFactColumnDefinition();
	}

	calculateQuadrant(rowNumber: number, columnIndex: number): number {
		let newQuadrantBoundary = 1;
		let existingQuadrantBoundary = 2;
		let delistedQuadrantBoundary = 3;
		let notCarriedQuadrantBoundary = 4;
		let totalQuadrantBoundary = 5;

		// Calculate the quadrant based on row and column numbers
		if (rowNumber === newQuadrantBoundary) {
			return columnIndex;
		} else if (rowNumber === existingQuadrantBoundary) {
			return columnIndex + 5;
		} else if (rowNumber === delistedQuadrantBoundary) {
			return columnIndex + 10;
		} else if (rowNumber === notCarriedQuadrantBoundary) {
			return columnIndex + 15;
		} else if (rowNumber === totalQuadrantBoundary) {
			return columnIndex + 20;
		} else {
			return -1;
		}
	}

	calculateRowNumber(rowHeader: string): number {
		switch (rowHeader) {
			case 'New':
				return 1;
			case 'Existing':
				return 2;
			case 'Delisted':
				return 3;
			case 'Not Carried':
				return 4;
			case 'Total':
				return 5;
			default:
				return -1;
		}
	}

	getAllTabs() {
		this.productSearchService
			.getChildLevels(9, this.selectedProductToShow.level, {})
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(res => {
				this.tabs = res.result;
			});
	}

	showProduct() {
		this.showProductView = !this.showProductView;
		if (this.showProductView) {
			this.showBaseFactSelectDropdown = false;
			this.showMulticheckDropdown = false;
			this.showPeriodDropdown = false;
			this.showFactSelectorDropdown = false;
		}
	}

	updateProductSelection(selectedProduct) {
		let value: any;
		selectedProduct?.optedValues.forEach(level => {
			let key = level.payloadField;
			if (key !== 'brandFamily') {
				this.assortmentDashboardService.defaultSelectors[key] = level.value;
				this.requestService.payload[key] = level.value; // Update payload accordingly
			}
		});
		value = this.assortmentDashboardService.getSelectedProducts(
			this.assortmentDashboardService.defaultSelectors
		);
		this.assortmentDashboardService
			.retrieveLevelData(value.selectedProduct.level, this.qid, value)
			.pipe(takeUntil(this.unSubscribeAll))
			.subscribe(val => {
				this.categoryList = val.result;
				let defaultIndex = this.assortmentDashboardService.findDefaultByLevel(
					val.result,
					value.selectedProduct.level,
					'key'
				);
				// this.assortmentCtrl.selectedLevelToShow = this.categoryList[defaultIndex].value;
				// this.requestService.payload.level = this.categoryList[defaultIndex].key;
				this.utilityService.preselectDefault(
					this.categoryList,
					this.assortmentCtrl.selectedLevelToShow
				);
				this.loadDashboard();
			});
		this.selectedProductToShow = {
			level: selectedProduct?.selectedProduct?.key,
			value: selectedProduct?.selectedProduct?.value
		};
		this.getAllTabs();

		this.assortmentCtrl.selectedProduct = [];
		selectedProduct?.optedValues
			.filter(item => item.value !== '' && item.value !== null)
			.map(item => {
				this.assortmentCtrl.selectedProduct.push({
					displayName: this.textChangePipe.transform(item.alias_name),
					displayValue: [item.value]
				});
			});
	}

	onMoreOptionClicked(data) {
		if (data.menuId === 1) {
			let selectorType = this.selectorTypes[data.selectorId].type;
			let selectorValues = this.saveSelectionMetaData;
			let payload = this.getSaveDefaultPayload(data.menuId, data.selectorId, selectorValues);
			let queryParam = this.qid + '?type=' + selectorType;
			this.assortmentDashboardService.defaultSelectors[selectorType] = selectorValues[selectorType];
			this.utilityService
				.saveItemAsDefault(payload, queryParam)
				.pipe(takeUntil(this.unSubscribeAll))
				.subscribe({
					next: res => {
						this.toastService.InjectToast(
							'success',
							'Selections saved successfully',
							'',
							TOAST.TIMEOUT,
							'',
							'Success',
							TOAST.SIZE
						);
					},
					error: err => console.log(err)
				});
		} else if (data.menuId === 2) {
			if (data.selectorId === 7 || data.selectorId === 3) {
				this.requestService.payload.brandOwnerHigh = '';
				this.requestService.payload.brandOwnerLow = '';
				this.requestService.payload.category = '';
				this.requestService.payload.categoryGroup = '';
				this.requestService.payload.department = '';
				this.requestService.payload.majorBusiness = '';
				this.requestService.payload.segment = '';
				this.requestService.payload.subCategory = '';
				this.requestService.payload.totalBusiness = '';
			}

			if (data.selectorId === 7 || data.selectorId === 6) {
				this.colHeaders = this.defaultFacts;
				if (this.innerTable) {
					this.innerTable.resetColumns();
				}
			}
			this.defaultFacts = this.colHeaders;
			this.selectedFactCount = this.innerTable.defaultColumns.length;
			this.requestService.isInnerTable = false;
			this.onApplyDefault(this.assortmentDashboardService.defaultSelectors, data.selectorId);
			this.loadDashboard();
		}
	}

	saveSelectionMetaData() {
		return {
			market: this.assortmentCtrl.selectedMarket.join(';'),
			periodIds: this.assortmentCtrl.period,
			baseFacts: this.assortmentCtrl.baseFact
		};
	}

	getSaveDefaultPayload(type, selectorId, params) {
		let paramsObj = {};
		if (type === 1) {
			if (selectorId === 0 || selectorId === 7) {
				paramsObj = {
					baseFact: this.selectedBaseFact,
					periodIds: this.requestService.payload.period,
					brand: '',
					brandfamily: '',
					brandhigh: '',
					brandlow: '',
					brandownerhigh: this.requestService.payload.brandOwnerHigh,
					brandownerlow: this.requestService.payload.brandOwnerLow,
					category: this.requestService.payload.category,
					categorygroup: this.requestService.payload.categoryGroup,
					department: this.requestService.payload.department,
					majorbusiness: this.requestService.payload.majorBusiness,
					segment: this.requestService.payload.segment,
					subcategory: this.requestService.payload.subCategory,
					totalbusiness: this.requestService.payload.totalBusiness
				};
			} else if (selectorId === 5) {
				paramsObj = {
					periodIds: this.requestService.payload.period
				};
			} else if (selectorId === 3) {
				paramsObj = {
					baseFact: this.assortmentCtrl.baseFact
				};
			} else if (selectorId === 6 || selectorId === 7) {
				paramsObj = {
					facts: this.colHeaders
				};
				this.defaultFacts = this.colHeaders;
				this.initializeFactsSelection();
				this.getFactColumnDefinition();
			} else if (selectorId === 2) {
				this.assortmentCtrl.selectedMarket.forEach((market, index) => {
					paramsObj[`market${index + 1}`] = market;
				});
			}
			for (let i in this.selectorTypes) {
				if ((selectorId == Number(i) || selectorId === 7 || selectorId === 5) && i !== '0') {
					if (params[this.selectorTypes[i].type] !== undefined) {
						paramsObj[this.selectorTypes[i].type] = params[this.selectorTypes[i].type];
					}
				}
			}
			return paramsObj;
		}
	}

	baseFactSelected(data: any) {
		this.showBaseFactSelectDropdown = !this.showBaseFactSelectDropdown;
		this.assortmentCtrl.baseFact = data.value;
		this.selectedBaseFact = data.value;
		this.baseFactKey = data.key;

		// this.loadDashboard();
		// Close other dropdowns
		this.showProductView = false;
		this.showMulticheckDropdown = false;
		this.showPeriodDropdown = false;

		// Find the selected base fact object from selectedBaseFact_obj
		let selectedBaseFactObj = this.selectedBaseFact_obj.find(
			cell => cell.headerName === this.selectedBaseFact
		);

		let cellFilter = selectedBaseFactObj ? selectedBaseFactObj.cellFilter : '';
		let cellClass = selectedBaseFactObj ? selectedBaseFactObj.cellClass : '';

		// Define column definitions
		this.mktTableColumnDefs = [
			{ headerName: '', field: 'rowHeader', width: '145%', cellFilter: '', cellClass: 'main' }
		];

		for (let i = 1; i <= 5; i++) {
			this.mktTableColumnDefs.push({
				headerName: this.getHeaderName(i),
				field: i.toString(),
				width: '145%',
				cellFilter: cellFilter,
				cellClass: cellClass,
				tooltipValueGetter: params => {
					return `${params.data.rowHeader} UPCs at Walmart & ${params.colDef.headerName} UPCs in remaining market`;
				}
			});
		}
		this.mktTableRowData = [];
		let currentValue = 1;

		for (let i = 1; i <= 5; i++) {
			let rowData = { rowHeader: this.getHeaderName(i) };
			for (let j = 1; j <= 5; j++) {
				rowData[j.toString()] = this.dashboardData.tableValues[currentValue++][this.baseFactKey];
			}
			this.mktTableRowData.push(rowData);
		}
	}

	onFactSelect(data: any) {
		this.selectedFactCount = 0;
		data.forEach(fact => {
			if (fact.isCheckedDistribution == true) this.selectedFactCount++;
			if (fact.isCheckedEQ == true) this.selectedFactCount++;
			if (fact.isCheckedOther == true) this.selectedFactCount++;
			if (fact.isCheckedUnit == true) this.selectedFactCount++;
			if (fact.isCheckedDollar == true) this.selectedFactCount++;
		});
		this.showFactSelectorDropdown = false;
		this.requestService.factUpdated(data);
		this.loadDashboard();
		// Close other dropdowns
	}

	showBaseFactMenu() {
		this.showBaseFactSelectDropdown = !this.showBaseFactSelectDropdown;
		if (this.showBaseFactSelectDropdown) {
			this.showProductView = false;
			this.showMulticheckDropdown = false;
			this.showPeriodDropdown = false;
			this.showFactSelectorDropdown = false;
		}
	}

	showFactMenu() {
		this.showFactSelectorDropdown = !this.showFactSelectorDropdown;
		if (this.showFactSelectorDropdown) {
			this.showProductView = false;
			this.showMulticheckDropdown = false;
			this.showPeriodDropdown = false;
			this.showBaseFactSelectDropdown = false;
		}
	}

	showMarketMenu() {
		this.showMulticheckDropdown = !this.showMulticheckDropdown;
		if (this.showMulticheckDropdown) {
			this.showProductView = false;
			this.showBaseFactSelectDropdown = false;
			this.showPeriodDropdown = false;
			this.showFactSelectorDropdown = false;
		}
	}

	showPeriodMenu() {
		this.showPeriodDropdown = !this.showPeriodDropdown;
		if (this.showPeriodDropdown) {
			this.showProductView = false;
			this.showBaseFactSelectDropdown = false;
			this.showMulticheckDropdown = false;
			this.showFactSelectorDropdown = false;
		}
	}

	loadDashboard() {
		// Set initial loading states
		this.isDashboardDataAvailable = false;
		let matchedFact = this.baseFactList.find(item => item.value === this.selectedBaseFact);
		if (matchedFact) {
			this.baseFactKey = matchedFact.key;
		}
		if (!this.selectedBaseFact) {
			this.selectedBaseFact = 'Count of UPCs';
			this.baseFactKey = 'upcCount';
		}
		// Reset necessary flags and loaders
		this.dashboardData = null;

		// Call functions to fetch dashboard data
		this.getReportData();
		this.getAllTabs();
		this.tableLoader = false;
	}

	addselectedMarket(selectedMarket: any) {
		this.assortmentCtrl.selectedMarket = [];
		this.requestService.payload.wmMarket = [];
		this.requestService.payload.rmMarket = [];
		selectedMarket.forEach(item => {
			this.assortmentCtrl.selectedMarket.push(item.key);
		});
		this.requestService.payload.market = [...this.assortmentCtrl.selectedMarket];
		this.assortmentCtrl.selectedMarket.forEach(market => {
			this.requestService.payload.wmMarket.push(this.marketList[market].walmartMarket);
			this.requestService.payload.rmMarket.push(this.marketList[market].remainingMarket);
		});
		this.loadDashboard();
		this.showMulticheckDropdown = false;
	}

	levelSelected(data: any) {
		// this.assortmentCtrl.selectedLevelToShow = data.value;
		// this.requestService.payload.level = data.key;
		this.tableLoader = true;

		this.loadDashboard();
	}

	periodSelected(data: any) {
		this.tableLoader = true;

		this.assortmentCtrl.period = data.value;
		this.showPeriodDropdown = false;
		this.periodvar = data.key.toString();
		this.requestService.payload.period = this.periodvar;
		this.requestService.payload.cyPeriod = this.periodData.periods[data.key].currentPeriod;
		this.requestService.payload.yaPeriod = this.periodData.periods[data.key].previousPeriod;
		this.periodData.id = this.periodData.periods[data.key].id;

		this.loadDashboard();
	}

	reportDownload() {
		let periodString = this.periodData?.id ? this.periodData.id.toString() : '';

		let reportData = {
			applicationType: 'JBP',
			brandFamily: '',
			brandOwnerHigh: this.requestService.payload.brandOwnerHigh || null,
			brandOwnerLow: this.requestService.payload.brandOwnerLow || null,
			brandHigh: '',
			brandLow: '',
			bylevel: this.assortmentCtrl.selectedLevelToShow,
			category: this.requestService.payload.category,
			categoryGroup: this.requestService.payload.categoryGroup || null,
			cyPeriod: this.requestService.payload.cyPeriod,
			dataModelType: 'ASSORTMENT',
			department: this.requestService.payload.department,
			factDpName: this.selectedBaseFact,
			factType: this.baseFactKey,
			level: this.selectedProductToShow.level,
			majorBusiness: this.requestService.payload.majorBusiness || null,
			market: this.assortmentCtrl.selectedMarket,
			// parentScope: '',
			period: periodString,
			periodEndDate: this.assortmentCtrl.periodEndDate,
			quadrant: '0',
			rmMarket: this.requestService.payload.rmMarket,
			scope: this.selectedProductToShow.level,
			selectedValue: this.selectedProductToShow.value,
			segment: this.requestService.payload.segment || '',
			slected_metrics: this.slected_metrics,
			subCategory: this.requestService.payload.subCategory || null,
			totalBusiness: this.requestService.payload.totalBusiness || null,
			wmMarket: this.requestService.payload.wmMarket,
			yaPeriod: this.requestService.payload.yaPeriod
		};
		this.utilityService.exportFile(reportData, EXCEL);
	}

	updateSortAction() {
		this.gridColumnService.sortColumnSubject$.subscribe(res => {
			this.headerName = res.headerName;
			this.sort = res.sortOrder;
		});
	}

	onColDefsChanged(data: { fields: string[]; headers: string; modifiedColDefs: any[] }) {
		this.slected_metrics = data.fields;
		this.modifiedColDefs = data.modifiedColDefs;
		this.colHeaders = this.slected_metrics
			.map(key => {
				const matchedItem = this.baseFactList.find(item => item.key === key);
				return matchedItem ? matchedItem.value : '';
			})
			.filter(value => value !== '') // Ensure no empty values are included
			.join(', ');
	}
}
