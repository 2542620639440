<div class="card p-3">
	<crf-ca-save-default
		[selectorType]="6"
		[configId]="configId"
		(menuSelector)="getMenuResponse($event)"
		[disableMenuOptions]="disableMenuOptions"
		[menu]="menu"
	></crf-ca-save-default>
	<niq-search-bar
		variant="expandable"
		size="medium"
		[onWhite]="true"
		placeholder="Search facts"
		class="searchbar"
		(inputValueChange)="search($event)"
		(clear)="clear($event)"
	>
	</niq-search-bar>
	<div class="header-name d-flex justify-content-between p-3">
		<p class="mb-0">Fact name</p>
		<p class="mb-0">Selected</p>
	</div>
	<niq-accordion-group variant="single" size="medium" [expandedItem]="accordionExpandedItem">
		<niq-accordion
			size="medium"
			[isExpanded]="false"
			expandIcon="chevron-up"
			collapseIcon="chevron-down"
			[border]="'none'"
			class="accordion mb-2"
			*ngFor="let factHeader of factHeaders; let index = index"
			(toggle)="toggle(index)"
		>
			<div accordion-actions class="d-flex justify-content-between">
				<p>{{ factHeader.title }}</p>
				<p style="position: absolute; right: 50px; margin: 0">
					{{ getCheckedCount(factHeader.value) }} of {{ factData.length }}
				</p>
			</div>
			<div accordion-content class="accordion-content-container p-3">
				<niq-radio-group size="small">
					<niq-radio-button
						size="small"
						(click)="factValueChange(fact, factHeader.value)"
						[checked]="factValue.key === fact.key && headerValue === factHeader.value"
						*ngFor="let fact of factData"
					>
						<span class="menu-text">{{ factHeader.value === 'DOLLAR' ? '$ ' : 'Unit ' }}</span>
						<span
							class="menu-text"
							[innerHTML]="searchText ? fact.highlightedValue : fact.key"
						></span>
					</niq-radio-button>
				</niq-radio-group>
			</div>
		</niq-accordion>
	</niq-accordion-group>
	<!--Footer-->
	<div class="button-container py-3 d-flex justify-content-between align-items-center">
		<niq-button variant="ghost" size="small" (click)="clearSelection()"> Clear all </niq-button>
		<div>
			<niq-button variant="ghost" size="medium" class="mr-2" (click)="cancel()">
				Cancel
			</niq-button>
			<niq-button
				variant="primary"
				size="medium"
				[state]="disabledButton ? 'disabled' : 'default'"
				(click)="apply()"
			>
				Apply
			</niq-button>
		</div>
	</div>
</div>
