import { Injectable } from '@angular/core';
import _ from 'lodash';

@Injectable({
	providedIn: 'root'
})
export class ShareReportUtilityService {
	constructor() {}
	/**
	 *
	 * @returns linked parent child data required for ag-grid tree
	 */
	public linkParentChild(data) {
		const productData = data;
		const parentProductLevel = _.map(
			productData.filter(x => x.hierarchy === 'parent'),
			'productLevel'
		);
		productData.forEach(element => {
			if (element.hierarchy === 'parent') {
				element.productLevelHierarchy = [parentProductLevel];
			}
			if (element.hierarchy === 'child') {
				element.productLevelHierarchy = [parentProductLevel, element.productLevel];
			}
		});
		return productData;
	}
	mergeParentChild(data) {
		let parent = data.filter(p => p.hierarchy === 'parent');
		let child = data.filter(c => c.hierarchy === 'child');
		parent[0]['children'] = child;
		return parent;
	}
}
