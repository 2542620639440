import { Component, ViewChild } from '@angular/core';
import { DialogComponent } from '@nielseniq/athena-core';
import { LandingPageService } from '../service/landing-page.service';
import { AzureUtilityService } from '../concierge/services/azure-utility.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';

import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'crf-ca-landing-page',
	templateUrl: './landing-page.component.html',
	styleUrl: './landing-page.component.scss'
})
export class NlsnAdminLandingComponent {
	@ViewChild('vidBannerModal') vidBannerModal: DialogComponent;
	private destroySubjects: Subject<void> = new Subject();
	bannerResults: any;
	vidLink: any;
	vidBannerLink: any;
	docResults: any;
	docData: any;
	isShowBanner = false;
	uiThemeUrl: string;
	currentBanner = 0;
	currentDocument = 0;
	showDoc: boolean;

	constructor(
		private landingPageService: LandingPageService,
		private AzureUtilityService: AzureUtilityService,
		public sanitizer: DomSanitizer
	) {}

	ngOnInit() {
		this.fetchBannerAndDocument();
	}

	onBannerClick(data) {
		this.docData = data;
		if (data.materialType === '' && data.videoLink) {
			this.AzureUtilityService.getSASTokensWithFileName(data.videoLink)
				.pipe(takeUntil(this.destroySubjects))
				.subscribe(url => {
					this.vidBannerLink = url;
					this.vidBannerModal.open();
				});
		}
	}

	onCloseDocumentSection() {
		this.docResults.forEach(ele => {
			this.landingPageService.viewedDocument(ele).subscribe();
		});
		this.showDoc = false;
	}
	updateBannerTheme() {
		this.bannerResults.forEach(banner => {
			switch (banner.backgroundTheme) {
				case 'Theme1':
					this.uiThemeUrl = 'assets/img/themes/banner_theme1.jpg';
					banner.uiThemeUrl = 'assets/img/themes/banner_theme1.jpg';
					break;
				case 'Theme2':
					this.uiThemeUrl = 'assets/img/themes/banner_theme2.jpg';
					banner.uiThemeUrl = 'assets/img/themes/banner_theme2.jpg';
					break;
				default:
					this.uiThemeUrl = 'assets/img/themes/banner_theme1.jpg';
					banner.uiThemeUrl = 'assets/img/themes/banner_theme1.jpg';
					break;
			}
		});
		this.isShowBanner = true;
	}

	fetchBannerAndDocument() {
		this.landingPageService
			.getBanner()
			.pipe(takeUntil(this.destroySubjects))
			.subscribe(res => {
				this.bannerResults = res.responseData.bannerData;
				this.docResults = res.responseData.docData.map(ele => {
					return {
						...ele,
						enhancementSummaryFileUrl: ''
					};
				});

				if (this.bannerResults.length > 0) {
					this.updateBannerTheme();
				} else {
					this.isShowBanner = false;
				}
				// Document Code
				if (!_.isEmpty(this.docResults)) {
					let firstDocument = this.docResults[0];
					this.AzureUtilityService.getSASTokensWithFileName(firstDocument.fileName)
						.pipe(takeUntil(this.destroySubjects))
						.subscribe(url => {
							firstDocument.enhancementSummaryFileUrl = url;
							this.showDoc = true;
						});
				}
			});
	}
	/**
	 *
	 * @param state prev & next
	 */
	changeBanner(state): void {
		if (state === 'prev' && this.currentBanner! < 0) {
			this.currentBanner--;
		} else {
			if (this.currentBanner < this.bannerResults.length - 1) {
				this.currentBanner++;
			} else {
				this.currentBanner = 0;
			}
		}
	}
	/**
	 *
	 * @param state prev & next
	 */
	onChangeDocument(state): void {
		if (state === 'prev') {
			this.currentDocument--;
		} else {
			if (this.currentDocument < this.docResults.length - 1) {
				if (this.docResults[this.currentDocument + 1].enhancementSummaryFileUrl === '') {
					this.AzureUtilityService.getSASTokensWithFileName(
						this.docResults[this.currentDocument + 1].fileName
					)
						.pipe(takeUntil(this.destroySubjects))
						.subscribe(url => {
							this.docResults[this.currentDocument + 1].enhancementSummaryFileUrl = url;
							this.currentDocument++;
						});
				} else {
					this.currentDocument++;
				}
			} else {
				this.currentDocument = 0;
			}
		}
	}
	/**
	 * Update read to backend
	 */
	onBannerCloseButton() {
		this.isShowBanner = false;
		this.bannerResults.forEach(ele => {
			this.landingPageService.viewedDocument(ele).pipe(takeUntil(this.destroySubjects)).subscribe();
		});
	}
	ngOnDestroy(): void {
		this.destroySubjects.next();
		this.destroySubjects.complete();
	}
}
